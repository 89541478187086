import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
const ToolbarReport2 = ({ editDrawer }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <GridToolbarContainer
      sx={{
        "& .MuiButton-text": {
          fontSize: "12px",
          // color: colors.buttonColor[100],
        },
        "& .MuiButton-text:hover": {
          color: colors.buttonColor[100] + " !important",
          backgroundColor: "transparent!important",
        },
      }}
    >
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarExport
        csvOptions={{
          fileName: "data_export.csv",
          delimiter: ",",
          utf8WithBom: true,
        }}
      />
      <Button
        sx={{
          padding: "4px 5px",
          // color: "red",
        }}
        startIcon={<EditIcon />}
        onClick={editDrawer}
        variant='text'
      >
        Edit Parameters{" "}
      </Button>
    </GridToolbarContainer>
  );
};

export default ToolbarReport2;
