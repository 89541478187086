import React from "react";
import { useContext, useCallback, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { ReactComponent as ExpandedLogo } from "../../assets/ExpandedLogo.svg";
import {
  Box,
  Button,
  Typography,
  IconButton,
  useTheme,
  TextField,
  Link,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  initialValuesLogin,
  loginFormSchema,
  registrationFormSchema,
  initialValuesRegistration,
} from "./formConfiguration"; // import the objects from the new file
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import auth from "../../firebase/firebase";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import urls from "../../data/cloudFunctionUrls";

const LogIn = ({ toggleLoggedIn }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showLoginForm, setShowLoginForm] = useState(true);
  const changeShowLoginForm = () => {
    setShowLoginForm(!showLoginForm);
  };

  const colorMode = useContext(ColorModeContext);

  const handleSignUpSubmit = (event) => {
    console.log(event);
    const { emailReg, passwordReg, companyName, operationDate } = event;
    const date = new Date(operationDate);
    const month = date.getMonth();
    const year = date.getFullYear();

    fetch(urls.adminAPI + "/createCompany", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        companyName: companyName,
        companyEmail: emailReg,
        password: passwordReg,
        startYear: year,
        startMonth: month,
        companyDescription: "",
        founder1Email: "",
        founder2Email: "",
        founder3Email: "",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (
          data.message ===
          "The email address is already in use by another account."
        ) {
          alert("The email address is already in use by another account.");
        }
        if (data.message === "Company and user created successfully!") {
          handleLoginSubmit({
            email: emailReg,
            password: passwordReg,
          });
        }
      })
      .catch((error) => {
        alert("Something went wrong. Please try again later.");
      });
  };

  const handleLoginSubmit = (event) => {
    const { email, password } = event;
    signInWithEmailAndPassword(auth, email, password)
      .then((response) => {
        console.log(response);
        // sessionStorage.setItem(
        //     "Auth Token",
        //     response._tokenResponse.refreshToken
        // );
      })
      .catch((error) => {
        var errorCode = error.code;
        if (
          errorCode === "auth/wrong-password" ||
          errorCode === "auth/user-not-found"
        ) {
          alert("Wrong email or password");
        } else {
          alert("Something went wrong. Please try again later.");
        }
        var errorMessage = error.message;

        console.log(errorCode, errorMessage);
      });
  };

  return (
    <Box>
      <Box
        color={colors.sideBorder[100]}
        overflow='hidden'
        // borderBottom='1px solid'
        // borderBottomColor={colors.sideBorder[100]}
        display='flex'
        height='56px'
        padding='16px 8px'
        backgroundColor={colors.backgroundColor[100]}
        justifyContent='space-between'
      >
        {" "}
        <Box sx={{ mt: "3px" }} display='flex'>
          <ExpandedLogo></ExpandedLogo>
          {/* <Typography
            fontFamily='Montserrat'
            fontWeight='bold'
            variant='h3'
            color={colors.grey[100]}
            fontSize='18px'
            paddingLeft='10px'
          >
            FP
          </Typography>
          <Typography
            fontFamily='Montserrat'
            color={colors.grey[100]}
            variant='h3'
            fontSize='18px'
          >
            Tool
          </Typography> */}
        </Box>
        {/* <CollapsedLogo></CollapsedLogo> */}
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
      </Box>
      {showLoginForm ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
            width: "100vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant='h3'
              color={colors.grey[100]}
              fontWeight='bold'
              sx={{ m: "0 0 0px 0", fontFamily: "Inter" }}
            >
              Sign in to FPTool
            </Typography>{" "}
            <Box mt={1}>
              <Typography variant='h5' color={colors.grey[400]}>
                New to FPTool?{" "}
                <Link
                  onClick={changeShowLoginForm}
                  sx={{
                    color: colors.buttonColor[100],
                    textDecoration: "none",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Sign Up
                </Link>
              </Typography>{" "}
            </Box>
            <Formik
              onSubmit={handleLoginSubmit}
              initialValues={initialValuesLogin}
              validationSchema={loginFormSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  style={{ height: "70%", marginTop: "24px" }}
                  onSubmit={handleSubmit}
                >
                  <Box
                    sx={{
                      "& 	.MuiFilledInput-root:after": {
                        borderBottomColor: "#2595FF",
                      },
                      "& 	.Mui-focused": {
                        borderBottomColor: "#2595FF",
                        color: `${colors.grey[100]} !important`,
                      },
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "24px",
                      border: "1px solid",
                      borderColor: colors.sideBorder[100],
                      padding: "24px",
                      width: "480px",
                      borderRadius: "12px",
                    }}
                  >
                    <TextField
                      fullWidth
                      variant='filled'
                      type='text'
                      label='Email'
                      size='small'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name='email'
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      fullWidth
                      variant='filled'
                      type='password'
                      label='password'
                      size='small'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name='password'
                      error={!!touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      InputLabelProps={{ shrink: true }}
                    />
                    <Box mt='0px'>
                      Having trouble logging in?{" "}
                      <a
                        href='mailto:a.zerkoulis@acein.aueb.gr'
                        style={{
                          color: colors.buttonColor[100],
                          textDecoration: "none",
                        }}
                      >
                        Contact Us
                      </a>
                    </Box>
                    {/* <FormControlLabel
                    value='end'
                    control={<Checkbox />}
                    label='Remember me'
                    labelPlacement='end'
                  /> */}
                    <Button
                      // startIcon={<AddIcon />}
                      // size={isNonMobile ? "large" : "medium"}
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='buttonColor'
                      size='large'
                      sx={{
                        backgroundColor: colors.buttonColor[100],
                        color: "#FFFFFF",
                        marginTop: "32px",
                        // fontFamily: "Arial",
                        // fontSize: "16px",
                        // fontWeight: "bold",
                      }}
                    >
                      Log In
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "85vh",
            width: "100vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant='h3'
              color={colors.grey[100]}
              fontWeight='bold'
              sx={{ m: "0 0 0px 0", fontFamily: "Inter" }}
            >
              Get started with FPTool
            </Typography>{" "}
            <Box mt={1}>
              <Typography variant='h5' color={colors.grey[400]}>
                Already have an account?{" "}
                <Link
                  onClick={changeShowLoginForm}
                  sx={{
                    color: colors.buttonColor[100],
                    textDecoration: "none",
                    fontWeight: "600",

                    hover: {
                      cursor: "pointer",
                    },
                  }}
                >
                  Sign In
                </Link>
              </Typography>{" "}
            </Box>
            <Formik
              onSubmit={handleSignUpSubmit}
              initialValues={initialValuesRegistration}
              validationSchema={registrationFormSchema}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  style={{ height: "70%", marginTop: "24px" }}
                  onSubmit={handleSubmit}
                >
                  <Box
                    sx={{
                      "& 	.MuiFilledInput-root:after": {
                        borderBottomColor: "#2595FF",
                      },
                      "& 	.Mui-focused": {
                        borderBottomColor: "#2595FF",
                        color: `${colors.grey[100]} !important`,
                      },
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "24px",
                      border: "1px solid",
                      borderColor: colors.sideBorder[100],
                      padding: "24px",
                      width: "480px",
                      borderRadius: "12px",
                    }}
                  >
                    <TextField
                      fullWidth
                      variant='filled'
                      type='text'
                      label='Registration Email'
                      size='small'
                      onBlur={handleBlur}
                      InputLabelProps={{ shrink: true }}
                      onChange={handleChange}
                      value={values.emailReg}
                      name='emailReg'
                      error={!!touched.emailReg && !!errors.emailReg}
                      helperText={touched.emailReg && errors.emailReg}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        columnGap: "24px",
                      }}
                    >
                      <TextField
                        fullWidth
                        variant='filled'
                        type='password'
                        label='Password'
                        size='small'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.passwordReg}
                        name='passwordReg'
                        error={!!touched.passwordReg && !!errors.passwordReg}
                        helperText={touched.passwordReg && errors.passwordReg}
                      />
                      <TextField
                        fullWidth
                        variant='filled'
                        type='password'
                        label='Confirm Password'
                        size='small'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.confirmPassword}
                        name='confirmPassword'
                        error={
                          !!touched.confirmPassword && !!errors.confirmPassword
                        }
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        columnGap: "24px",
                      }}
                    >
                      <TextField
                        fullWidth
                        variant='filled'
                        type='text'
                        label='Company Name'
                        size='small'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.companyName}
                        name='companyName'
                        error={!!touched.companyName && !!errors.companyName}
                        helperText={touched.companyName && errors.companyName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        fullWidth
                        variant='filled'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type='date'
                        label='First day of operation'
                        size='small'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.operationDate}
                        name='operationDate'
                        error={
                          !!touched.operationDate && !!errors.operationDate
                        }
                        helperText={
                          touched.operationDate && errors.operationDate
                        }
                      />
                    </Box>
                    Your new account will be on free trial for 14 days.
                    {/* <FormControlLabel
                    value='end'
                    control={<Checkbox />}
                    label='Remember me'
                    labelPlacement='end'
                  /> */}
                    <Button
                      // startIcon={<AddIcon />}
                      // size={isNonMobile ? "large" : "medium"}
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='buttonColor'
                      size='large'
                      sx={{
                        backgroundColor: colors.buttonColor[100],
                        color: "#FFFFFF",
                        marginTop: "32px",
                        // fontFamily: "Arial",
                        // fontSize: "16px",
                        // fontWeight: "bold",
                      }}
                    >
                      Create Account
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
            <Box mt='16px'>
              Having trouble setting up your account?{" "}
              <a
                href='mailto:a.zerkoulis@acein.aueb.gr'
                style={{
                  color: colors.buttonColor[100],
                  textDecoration: "none",
                }}
              >
                Contact Us
              </a>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LogIn;
