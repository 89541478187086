import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { ReactComponent as Engine } from "../../assets/techny-white-gear.svg";

const Apology = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box ml=' 15px ' mt='20px' height='90%' mr='15px' width='90%'>
      <Typography
        variant='h2'
        color={colors.grey[100]}
        fontWeight='bold'
        sx={{ m: "0 0 5px 0", fontFamily: "Inter", fontSize: "26px" }}
      >
        Unfortunately, the tool is not yet optimized for mobile devices.
      </Typography>
      <Typography variant='h5' color={colors.greenAccent[400]}>
        Please use a desktop or laptop computer to view this site, we are
        terribly sorry for the inconvenience.
      </Typography>
      <Box width='100%' display='flex' justifyContent='center' mt='50px'>
        <Engine />
      </Box>
    </Box>
  );
};

export default Apology;
