import * as yup from "yup";
export const loginFormSchema = yup.object().shape({
  email: yup.string().email().required("Please enter your email"),
  password: yup
    .string("Input must be a String")
    .required("Please input a password"),
});

export const initialValuesLogin = {
  email: "",
  password: "",
};

export const registrationFormSchema = yup.object().shape({
  emailReg: yup
    .string()
    .email("Invalid email format")
    .required("Please enter your email"),
  passwordReg: yup
    .string("Input must be a String")
    .required("Please input a password")
    .min(6, "Password must be at least 8 characters"),
  confirmPassword: yup
    .string("Input must be a String")
    .required("Please confirm your password")
    .oneOf([yup.ref("passwordReg")], "Passwords must match"),

  companyName: yup
    .string("Input must be a String")
    .required("Please input your company name"),
  operationDate: yup
    .date("Invalid date format")
    .required("Please select an operation date"),
});

export const initialValuesRegistration = {
  emailReg: "",
  passwordReg: "",
  confirmPassword: "",
  companyName: "",
  operationDate: "",
};
