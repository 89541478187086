import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";

const LineChartRisk = ({ title, data, targetVariable }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const LineTheme = {
        axis: {
            domain: {
                line: {
                    stroke: colors.grey[100],
                },
            },
            legend: {
                text: {
                    fill: colors.grey[100],
                    fontWeight: "bold",
                },
            },
            ticks: {
                line: {
                    stroke: colors.grey[100],
                    strokeWidth: 1,
                },
                text: {
                    fill: colors.grey[100],
                    fontSize: 9.5,
                },
            },
        },
        legends: {
            text: {
                fill: colors.grey[100],
            },
        },
        tooltip: {
            container: {
                color: colors.primary[500],
            },
        },
    };

    return (
        <ResponsiveLine
            data={data}
            theme={LineTheme}
            margin={{ top: 50, right: 45, bottom: 60, left: 60 }}
            colors="#CCCCFF"
            xScale={{ type: "point" }}
            yScale={{
                type: "linear",
                stacked: false,
                min: "auto",
                max: "auto",
            }}
            yFormat=" >-.2f"
            curve="catmullRom"
            axisTop={null}
            axisRight={null}
            enableArea={true}
            areaOpacity={0.14}
            enableGridX={false}
            enableGridY={true}
            enablePoints={true}
            tooltip={({ point }) => (
                <div
                    style={{
                        padding: "9px 12px",
                        color: colors.grey[100],
                        border: "1.5px solid",
                        borderColor: colors.sideBorder[100],
                        backgroundColor: colors.backgroundColor[100],
                        borderRadius: "4px",
                    }}>
                    Year: <strong>{point.data.x}</strong>
                    <br />
                    Value: <strong>{point.data.y}</strong>
                </div>
            )}
            axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -45,
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
            }}
        />
    );
};

export default LineChartRisk;
