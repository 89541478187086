import { tokens } from "../../theme";
import React from "react";
import { useState, useEffect } from "react";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";

import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  ButtonGroup,
} from "@mui/material";
import Header from "../../components/Header";
import {
  activeButtonStyling,
  buttonGroupStyling2,
} from "../../globalStyling/tablesStyling";
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file
import LineChartEvaluation from "../../components/LineChartEvaluation";
import urls from "../../data/cloudFunctionUrls";
import auth from "../../firebase/firebase";
import LinearProgress from "@mui/material/LinearProgress";

const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

const destructuredData = (data) => {
  let tempData = {
    yearMinus1: data["-1"].toFixed(),
    year0: data["0"].toFixed(),
    year1: data["1"].toFixed(),
    year2: data["2"].toFixed(),
    year3: data["3"].toFixed(),
    year4: data["4"].toFixed(),
  };
  return tempData;
};

const InvestmentAnalysis = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [cashFlow, setCashFlow] = useState("Net Cash Flow");

  //   Possible values for the cash flow state are: netCashFlow,
  // Accumulated Cash Flow, Net Present Value(NCF)
  const changeCashFlow = (cashflow) => {
    setCashFlow(cashflow);
  };
  const companyFoundingYear = companyFoundingYearGetter();
  const buttonGroupStyles = buttonGroupStyling2(colors);
  const activeButton = activeButtonStyling(colors);

  const cashflowValues = {
    yearMinus1: -17721,
    year0: -13096,
    year1: -3873,
    year2: 3873,
    year3: 18145,
    year4: 4437,
  };

  const investmentMetrics = {
    irr5Years: 0,
    irrinfinity: 0,
    npv: 0,
    npmInfinity: 0,
    recoveryPeriod: 0,
    presentValueOfInvestment: 0,
    weightedAverageCost: 0,
  };
  const [investmentData, setInvestmentData] = useState(investmentMetrics);
  // const {
  //     irr5Years,
  //     irrinfinity,
  //     npv,
  //     npmInfinity,
  //     recoveryPeriod,
  //     presentValueOfInvestment,
  //     weightedAverageCost,
  // } = investmentData;

  const [lineChartData, setLineChartData] = useState({});
  const [insufficientData, setInsufficientData] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setInsufficientData(false);
    // This is the function that will update the values of the break even point
    auth.currentUser.getIdToken().then((token) => {
      fetch(urls.reportAPI + "/getCompanyInvestmentEvaluationAggregations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 404) {
            setInsufficientData(true);
          }
        })
        .then((data) => {
          let tempInvestmentData = {
            irr5Years: toPercent(data.irr5Years, 2),
            irrinfinity: toPercent(data.irrInfinite, 2),
            npv: data.netPresentValue5Years.toFixed(),
            npmInfinity: data.netPresentValueInfinite.toFixed(),
            recoveryPeriod: data.paybackPeriod.toFixed(2),
            presentValueOfInvestment:
              data.presentValueOfTotalInvestment.toFixed(2),
            weightedAverageCost: toPercent(
              data.weightedAverageCostPercentage,
              2
            ),
          };

          const tempLineChartData = {
            "Net Cash Flow": destructuredData(data.netCashFlowsPerYear),
            "Accumulated Cash Flow": destructuredData(
              data.cumulativeCashFlowsPerYear
            ),
            "Net Present Value(NCF)": destructuredData(
              data.currentNetCashFlowPerYear
            ),
          };

          setInvestmentData(tempInvestmentData);
          setLineChartData(tempLineChartData);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, []);

  return (
    <Box m='20px'>
      <Header
        title='Investment Evaluation'
        subtitle='An overview of your investment'
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          mb: "16px",
        }}
      >
        {" "}
        <Box>
          <Typography
            variant='h4'
            color={colors.grey[100]}
            fontWeight='bold'
            sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}
          >
            Key Metrics
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "16px",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexWrap: "wrap",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  flex: "1 0 600px;",
                  height: "200px",
                  display: "flex",
                  borderRadius: "8px",
                  gap: "15%",
                  border: "1.5px solid",
                  borderColor: colors.sideBorder[100],
                  padding: "12px",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Box>
                  {" "}
                  <Decoration1 />
                  <Typography
                    variant='h4'
                    color={colors.grey[400]}
                    fontWeight='medium'
                    sx={{
                      m: "0px 0px 0px 16px",
                      fontFamily: "Inter",
                      fontSize: width < 900 ? "0.8rem" : "1rem",
                      display: "inline",
                    }}
                  >
                    IRR(5 Years)
                  </Typography>
                  <Typography
                    variant='h5'
                    color={colors.grey[100]}
                    sx={{
                      m: "0px 0px 0px 33px",
                      fontFamily: "Inter",
                      fontWeight: "bold",
                      fontSize: width < 900 ? "1.1rem" : "1.5rem",
                    }}
                  >
                    {investmentData.irr5Years || "-"}
                  </Typography>
                </Box>
                <Box>
                  {" "}
                  <Decoration1 />
                  <Typography
                    variant='h4'
                    color={colors.grey[400]}
                    fontWeight='medium'
                    sx={{
                      m: "0px 0px 0px 16px",
                      fontFamily: "Inter",
                      fontSize: width < 900 ? "0.8rem" : "1rem",
                      display: "inline",
                    }}
                  >
                    IRR(∞)
                  </Typography>
                  <Typography
                    variant='h5'
                    color={colors.grey[100]}
                    sx={{
                      m: "0px 0px 0px 33px",
                      fontFamily: "Inter",
                      fontWeight: "bold",
                      fontSize: width < 900 ? "1.1rem" : "1.5rem",
                    }}
                  >
                    {investmentData.irrinfinity || "-"}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  flex: "1 0 600px;",
                  height: "200px",
                  display: "flex",
                  borderRadius: "8px",
                  gap: "15%",
                  border: "1.5px solid",
                  borderColor: colors.sideBorder[100],
                  padding: "12px",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Box>
                  {" "}
                  <Decoration1 />
                  <Typography
                    variant='h4'
                    color={colors.grey[400]}
                    fontWeight='medium'
                    sx={{
                      m: "0px 0px 0px 16px",
                      fontFamily: "Inter",
                      fontSize: width < 900 ? "0.8rem" : "1rem",
                      display: "inline",
                    }}
                  >
                    NPV(5 Years)
                  </Typography>
                  <Typography
                    variant='h5'
                    color={colors.grey[100]}
                    sx={{
                      m: "0px 0px 0px 33px",
                      fontFamily: "Inter",
                      fontWeight: "bold",
                      fontSize: width < 900 ? "1.1rem" : "1.5rem",
                    }}
                  >
                    {investmentData.npv || "-"}
                  </Typography>
                </Box>
                <Box>
                  {" "}
                  <Decoration1 />
                  <Typography
                    variant='h4'
                    color={colors.grey[400]}
                    fontWeight='medium'
                    sx={{
                      m: "0px 0px 0px 16px",
                      fontFamily: "Inter",
                      fontSize: width < 900 ? "0.8rem" : "1rem",
                      display: "inline",
                    }}
                  >
                    NPV(∞)
                  </Typography>
                  <Typography
                    variant='h5'
                    color={colors.grey[100]}
                    sx={{
                      m: "0px 0px 0px 33px",
                      fontFamily: "Inter",
                      fontWeight: "bold",
                      fontSize: width < 900 ? "1.1rem" : "1.5rem",
                    }}
                  >
                    {investmentData.npmInfinity || "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexWrap: "wrap",
                gap: "16px",
              }}
            >
              <Box
                sx={{
                  flex: "1 0 350px;",
                  height: "200px",
                  display: "flex",
                  borderRadius: "8px",
                  gap: "10%",
                  border: "1.5px solid",
                  borderColor: colors.sideBorder[100],
                  padding: "12px",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Box>
                  {" "}
                  <Decoration1 />
                  <Typography
                    variant='h4'
                    color={colors.grey[400]}
                    fontWeight='medium'
                    sx={{
                      m: "0px 0px 0px 16px",
                      fontFamily: "Inter",
                      fontSize: width < 900 ? "0.8rem" : "1rem",
                      display: "inline",
                    }}
                  >
                    Weighted Average Cost
                  </Typography>
                  <Typography
                    variant='h5'
                    color={colors.grey[100]}
                    sx={{
                      m: "0px 0px 0px 33px",
                      fontFamily: "Inter",
                      fontWeight: "bold",
                      fontSize: width < 900 ? "1.1rem" : "1.5rem",
                    }}
                  >
                    {investmentData.weightedAverageCost || "-"}
                  </Typography>
                </Box>
                <Box>
                  {" "}
                  <Decoration1 />
                  <Typography
                    variant='h4'
                    color={colors.grey[400]}
                    fontWeight='medium'
                    sx={{
                      m: "0px 0px 0px 16px",
                      fontFamily: "Inter",
                      display: "inline",
                      fontSize: width < 900 ? "0.8rem" : "1rem",
                    }}
                  >
                    Present Value of Investment
                  </Typography>
                  <Typography
                    variant='h5'
                    color={colors.grey[100]}
                    sx={{
                      m: "0px 0px 0px 33px",
                      fontFamily: "Inter",
                      fontWeight: "bold",
                      fontSize: width < 900 ? "1.1rem" : "1.5rem",
                    }}
                  >
                    {investmentData.presentValueOfInvestment || "-"}
                  </Typography>
                </Box>
                <Box>
                  {" "}
                  <Decoration1 />
                  <Typography
                    variant='h4'
                    color={colors.grey[400]}
                    fontWeight='medium'
                    sx={{
                      m: "0px 0px 0px 16px",
                      fontSize: width < 900 ? "0.8rem" : "1rem",
                      fontFamily: "Inter",
                      display: "inline",
                    }}
                  >
                    Recovery Period
                  </Typography>
                  <Typography
                    variant='h5'
                    color={colors.grey[100]}
                    sx={{
                      m: "0px 0px 0px 33px",
                      fontFamily: "Inter",
                      fontWeight: "bold",
                      fontSize: width < 900 ? "1.1rem" : "1.5rem",
                    }}
                  >
                    {investmentData.recoveryPeriod || "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={buttonGroupStyles} height='70vh' position='relative'>
          {" "}
          <Typography
            variant='h4'
            color={colors.grey[100]}
            fontWeight='bold'
            sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}
          >
            Cash Flows
          </Typography>
          <ButtonGroup variant='outlined' aria-label='outlined button group'>
            <Button
              onClick={() => changeCashFlow("Net Cash Flow")}
              style={cashFlow === "Net Cash Flow" ? activeButton : {}}
            >
              Net Cash Flow
            </Button>
            <Button
              onClick={() => changeCashFlow("Accumulated Cash Flow")}
              style={cashFlow === "Accumulated Cash Flow" ? activeButton : {}}
            >
              Accumulated Cash Flow
            </Button>
            <Button
              style={cashFlow === "Net Present Value(NCF)" ? activeButton : {}}
              onClick={() => changeCashFlow("Net Present Value(NCF)")}
            >
              Net present value(NCF)
            </Button>
          </ButtonGroup>
          <Box
            sx={{
              mt: "16px",
              height: "80%",
              width: "100%",
              border: "1.5px solid ",
              borderColor: colors.sideBorder[100],
              position: "absolute",
              borderRadius: "4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Box
              sx={{
                mt: "16px",
                height: "95%",
                width: "98%",
                zIndex: 1,
                position: "absolute",
              }}
            >
              <LineChartEvaluation
                insufficientData={insufficientData}
                companyFoundingYear={companyFoundingYear}
                cashFlowValues={lineChartData[cashFlow]}
                type={cashFlow}
              >
                {" "}
              </LineChartEvaluation>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default InvestmentAnalysis;
