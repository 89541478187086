import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
// import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendCustomPasswordResetEmail,
} from "firebase/auth";
import auth from "../../firebase/firebase";
import urls from "../../data/cloudFunctionUrls.json";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      m='20px'
      sx={{
        height: "70%",
        width: "80%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {" "}
      <Header
        title='Welcome to FPtool'
        subtitle='To get your reports and financial projections please input your company details'
      />
      {/* <IconButton
        // onClick={toggleParametersDrawer}
        aria-label='delete'
        sx={{
          backgroundColor: colors.buttonBackground[100],
          color: colors.buttonTextColor[100],
          zIndex: 1,
          border: "1px solid",
          borderColor: colors.buttonColor[100],
        }}
      >
        <ArrowForwardIcon />
      </IconButton> */}
    </Box>
  );
};

export default Dashboard;
