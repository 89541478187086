import {
    Box,
    Button,
    Drawer,
    IconButton,
    TextField,
    Typography,
    Tab,
    ButtonGroup,
} from "@mui/material";

import ToolbarReport from "../../components/ToolbarReport";
import LoadingSkeleton from "../../components/LoadingSkeleton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { DataGrid, NoRowsOverlay } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { useState, useEffect } from "react";
import NoRowsOverlayhello from "../../components/NoRowsOverlay";
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file
import { tableStylingReports } from "../../globalStyling/tablesStyling";
import Header from "../../components/Header";
import auth from "../../firebase/firebase";
import urls from "../../data/cloudFunctionUrls";
import rows from "./initialRows.json";
// title -> backend object dict
const titleDict = {
    "Total Revenue": "workCycleTotalPerYear",
    "Minus: Cost of goods sold": "salesPerYear",
    "Gross Profit": "grossProfitPerYear",
    "Minus: Salaries and wages": "personnelCostsPerYear",
    "Minus: Operating expenses": "operationalCostsPerYear",
    "Minus: Transportation expenses": "transportationCostsPerYear",
    "Minus: Promotional expenses": "advertismentCostsPerYear",
    "Minus: Other expenses": "otherCostsPerYear",
    "Minus: Other operating income": "otherIncomePerYear",
    "Profit before taxes, interest and depreciation":
        "resultsBeforeInterestTaxedDepreciationPerYear",
    "Minus: Depreciation": "depreciationsPerYear",
    "Profit before taxes and interest": "resultBeforeInterestAndTaxPerYear",
    "Minus: Loan interest": "loanInterestPerYear",
    "Minus: Equity in Earnings": "operatingRightsPerYear",
    "Profit before taxes": "resultBeforeTaxPerYear",
    "Minus: Taxes": "incomeTaxPerYear",
    "Profit after taxes": "resultAfterTaxPerYear",
    Dividends: "dividendsPerYear",
    "Retained earnings": "reservesPerYear",
};

const objectToRow = (obj, id, category, title) => {
    // if obj is empty, return empty row
    if (Object.keys(obj).length === 0) {
        return {
            id: id,
            category: category || "",
            year0: 0,
            year1: 0,
            year2: 0,
            year3: 0,
            year4: 0,
            title: title,
        };
    }

    const row = {
        id: id,
        category: category || "",
        year0: obj?.["0"].toFixed() || null,
        year1: obj?.["1"].toFixed() || null,
        year2: obj?.["2"].toFixed() || null,
        year3: obj?.["3"].toFixed() || null,
        year4: obj?.["4"].toFixed() || null,
        title: title,
    };
    return row;
};

const OperatingAccounts = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const companyFoundingYear = companyFoundingYearGetter();

    const ratiosGroupingModel = [
        {
            groupId: "Years",
            children: [
                { field: "year0" },
                { field: "year1" },
                { field: "year2" },
                { field: "year3" },
                { field: "year4" },
            ],
        },
    ];

    const columns = [
        {
            field: "category",
            headerName: "",
            width: 300,
            sortable: false,
        },
        ,
        {
            field: "year0",
            headerName: String(companyFoundingYear),
            flex: 0.7,
            minWidth: 155,
            sortable: false,
        },
        {
            field: "year1",
            headerName: String(companyFoundingYear + 1),
            flex: 0.7,
            minWidth: 155,
            sortable: false,
        },
        {
            field: "year2",
            headerName: String(companyFoundingYear + 2),
            flex: 0.7,
            minWidth: 155,
            sortable: false,
        },
        {
            field: "year3",
            headerName: String(companyFoundingYear + 3),
            flex: 0.7,
            minWidth: 155,
            sortable: false,
        },
        {
            field: "year4",
            headerName: String(companyFoundingYear + 4),
            flex: 0.7,
            minWidth: 155,
            sortable: false,
        },
        // add more columns here
    ];

    function getRowClassName(params) {
        if (params.row.title) {
            return theme.palette.mode === "dark"
                ? "title-row-dark"
                : "title-row";
        }
    }

    const tableStyles = tableStylingReports(colors);

    const [ratioData, setRatioData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [insufficientData, setInsufficientData] = useState(false);

    useEffect(() => {
        setInsufficientData(false);
        // This is the function that will update the values of the break even point
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.reportAPI + "/getCompanyOperatingAccountAggregations", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else if (response.status === 404) {
                        setInsufficientData(true);
                        setLoading(false);
                    }
                })
                .then((data) => {
                    setLoading(false);
                    let rowData = Object.keys(titleDict).map((key, index) => {
                        if (
                            key === "Gross Profit" ||
                            key ===
                                "Profit before taxes, interest and depreciation" ||
                            key === "Profit before taxes and interest" ||
                            key === "Profit before taxes" ||
                            key === "Profit after taxes" ||
                            key === "Retained earnings"
                        ) {
                            return objectToRow(
                                data[titleDict[key]],
                                index,
                                key,
                                true
                            );
                        } else {
                            return objectToRow(
                                data[titleDict[key]],
                                index,
                                key,
                                false
                            );
                        }
                    });
                    setRatioData(rowData);
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }, []);

    const CustomNoRowsOverlay = () => {
        return insufficientData ? (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    position: "absolute",
                    zIndex: 10,
                }}>
                <Typography sx={{ mb: "16px", maxWidth: "500px" }}>
                    Before generating the Operating Account Report, you need to
                    provide Company Data Parameters, Funding, Cost and Products
                    Company Data
                </Typography>
            </Box>
        ) : (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    position: "absolute",
                    zIndex: 10,
                }}>
                <Typography sx={{ mb: "16px", maxWidth: "500px" }}>
                    No rows
                </Typography>
            </Box>
        );
    };

    return (
        <Box m="20px">
            <Header
                title="Operating Accounts"
                subtitle="Record and track core business operations"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box ml="15px" height="930px" sx={tableStyles}>
                    <DataGrid
                        rows={ratioData}
                        loading={loading}
                        density="compact"
                        columns={columns}
                        experimentalFeatures={{ columnGrouping: true }}
                        columnGroupingModel={ratiosGroupingModel}
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                            LoadingOverlay: LoadingSkeleton,
                            // noRowsOverlay: () => <div>No Rows hello</div>,
                            Toolbar: () => <ToolbarReport />,
                            ToolbarReport,
                        }}
                        getRowClassName={getRowClassName}
                    />
                </Box>
            </Box>
        </Box>
    );
};
export default OperatingAccounts;
