import * as yup from "yup";
// establishment Costs form
export const establishmentCostSchema = yup.object().shape({
  cost: yup
    .number()
    .typeError("Cost must be a valid number")
    .positive("Cost must be a positive number")
    .required("Field is required"),
  category: yup.string("Input must be a String").required("Field is required"),
  depreciationYears: yup
    .number()
    .typeError("Cost must be a valid number")
    .positive("Cost must be a positive number")
    .required("Field is required"),
});

export const initialValuesEstablishment = {
  cost: "",
  category: "",
  depreciationYears: "",
};

export const operationCostSchema = yup.object().shape({
  cost0: yup
    .number()
    .typeError("Cost must be a valid number")
    .positive("Cost must be a positive number")
    .required("Field is required"),
  cost1: yup
    .number()
    .typeError("Cost must be a valid number")
    .positive("Cost must be a positive number")
    .required("Field is required"),
  cost2: yup
    .number()
    .typeError("Cost must be a valid number")
    .positive("Cost must be a positive number")
    .required("Field is required"),
  cost3: yup
    .number()
    .typeError("Cost must be a valid number")
    .positive("Cost must be a positive number")
    .required("Field is required"),
  cost4: yup
    .number()
    .typeError("Cost must be a valid number")
    .positive("Cost must be a positive number")
    .required("Field is required"),
  category: yup.string("Input must be a String").required("Field is required"),
  description: yup
    .string("Input must be a String")
    .required("Field is required"),
});

export const initialValuesOperational = {
  cost0: "",
  cost1: "",
  cost2: "",
  cost3: "",
  cost4: "",
  category: "",
  year: "",
  description: "",
  depreciationYears: "",
};

export const finvestmentsSchema = yup.object().shape({
  investment: yup
    .number()
    .typeError("Cost must be a valid number")
    .positive("Cost must be a positive number")
    .required("Field is required"),
  // category: yup.string("Input must be a String").required("Field is required"),
  description: yup
    .string("Input must be a String")
    .required("Field is required"),
  depreciationYears: yup
    .number()
    .typeError("Depreciation years must be a valid number"),
  year: yup.number().required("Year is required"),
});

export const initialFinvestments = {
  investment: "",
  category: "",
  year: "",
  description: "",
  depreciationYears: "",
};

export const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const example2 = yup.object().shape({
  name: yup.string().required("Please enter your name."),
  age: yup
    .number()
    .integer("Age must be an integer.")
    .min(18, "You must be at least 18 years old."),
  email: yup
    .string()
    .email("Please enter a valid email address.")
    .required("Please enter your email."),
});

export const example1 = yup.object().shape({
  cost: yup
    .number()
    .typeError("Cost must be a valid number")
    .positive()
    .required("Field is required"),
  category: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  adress: yup.string().required("required"),
  adress2: yup.string().required("required"),
});

export const userSchema = yup.object().shape({
  firstName: yup.number().integer().min(0).max(150).required(),
  lastName: yup.string().required("required"),
  email: yup.string().email("invalid email").required("required"),
  contact: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  adress: yup.string().required("required"),
  adress2: yup.string().required("required"),
});

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  contact: "",
  address1: "",
  address2: "",
};
