import { useState, useEffect } from "react";
import {
  Box,
  Drawer,
  IconButton,
  Typography,
  useTheme,
  Backdrop,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import "./global.css";
import { tokens } from "../../theme";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";

import { ReactComponent as Logo } from "../../assets/CollapsedLogo.svg";
import { ReactComponent as ExpandedLogo } from "../../assets/ExpandedLogo.svg";
import { ReactComponent as CompanyData } from "../../assets/database.svg";
import { ReactComponent as BarChart } from "../../assets/bar-chart.svg";
import { ReactComponent as ReportsIcon } from "../../assets/file-text.svg";
import { ReactComponent as HomeIcon } from "../../assets/home.svg";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { string } from "yup";

const Sidebar = ({ isCollapsed, setIsCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // If screen fals below 800px, the sidebar will be collapsed
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (width < 1000) {
      setIsCollapsed(true);
    }
  }, [width]);
  const styling = {
    "&.Mui-selected": {
      fontSize: "18px!important",
      fontWeight: "500!important",
      fontWeight: "medium",
      backgroundColor: colors.buttonBackground[100],
      color: colors.buttonTextColor[100],
      borderRight: "4px solid #3F51B5",
      borderRightColor: colors.buttonTextColor[100],
      fontFamily: "Roboto",
      pl: 4,
    },
  };

  const paperWidth = isCollapsed ? "0px" : "240px";
  const positionPaper =
    width < 1000 ? "absolute!important" : "relative!important";
  const nestedItems = [
    { text: "Costs", path: "/costs" },
    { text: "Products and Services", path: "/productsandservices" },
    { text: "Revenue", path: "/revenue" },
    { text: "Funding", path: "/funding" },
    { text: "Parameters", path: "/companyparameters" },
  ];
  const nestedItems2 = [
    { text: "Break Even Point", path: "/breakevenpoint" },
    { text: "Financial Ratios", path: "/financialratios" },
    { text: "Cash Flows", path: "/cashflows" },
    { text: "Operating Accounts", path: "/operatingaccounts" },
    { text: "Balance Sheets", path: "/balancesheets" },
  ];
  const nestedItems3 = [
    { text: "Investment Analysis", path: "/investmentanalysis" },
    { text: "Sensitivity Analysis", path: "/sensitivityanalysis" },
    { text: "Risk Analysis", path: "/riskanalysis" },
  ];

  const [selected, setSelected] = useState(
    localStorage.getItem("selected") || "Dashboard"
  );
  const handleClick = (item) => {
    setSelected(item.text);
    if (width < 1000) {
      setIsCollapsed(true);
    }
  };
  const handleClickDashboard = (name) => {
    setSelected(name);
  };
  const [openNested, setOpenNested] = useState(false);
  const handleClickNested = () => {
    setOpenNested(!openNested);
  };
  const [openNested2, setOpenNested2] = useState(false);
  const handleClickNested2 = () => {
    setOpenNested2(!openNested2);
  };

  const [openNested3, setOpenNested3] = useState(false);
  const handleClickNested3 = () => {
    setOpenNested3(!openNested3);
  };
  useEffect(() => {
    localStorage.setItem("selected", selected);
  }, [selected]);

  return (
    <Box
      // This part customizes the css of the premade sidebar library
      sx={{
        "&::-webkit-scrollbar": {
          width: 0,
        },
        "& .MuiDrawer-paper": {
          borderRadius: "0px 8px 8px 0px",
          width: paperWidth,
          backgroundColor: colors.backgroundColor[100],
          color: colors.grey[100],
          // transition: "width 0.2s",
          transition: "width 0.5s",
          overflowX: "hidden",
          boxShadow: "none",
          position: positionPaper,
        },
      }}
    >
      <Drawer
        variant='persistent'
        anchor='left'
        open={true}
        sx={{ height: "100%" }}
      >
        <Box>
          <Box
            sx={{
              height: "56.36px",
              width: "240px!important",
              display: "flex",
              alignItems: "center",
              padding: "30px 16px",
              position: "sticky",
            }}
          >
            {" "}
            <ExpandedLogo></ExpandedLogo>
            {/* <Typography
              fontFamily='Montserrat'
              fontWeight='bold'
              variant='h3'
              color={colors.grey[100]}
              fontSize='18px'
              paddingLeft='16px'
            >
              FP
            </Typography>
            <Typography
              fontFamily='Montserrat'
              color={colors.grey[100]}
              variant='h3'
              fontSize='18px'
            >
              Tool
            </Typography> */}
          </Box>
          <Box mt={"12px"}>
            {/* <ListItem
              button
              key={"Dashboard"}
              component={Link}
              to={"/"}
              selected={selected === "Dashboard"}
              onClick={() => handleClickDashboard("Dashboard")} // This is the function that changes the selected item")}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: colors.buttonBackground[100],
                  color: colors.buttonTextColor[100],
                  borderRight: "4px solid #3F51B5",
                  borderRightColor: colors.buttonTextColor[100],
                  fontFamily: "Roboto!important",
                },
                width: "240px!important",

                "&.Mui-selected:hover": {
                  backgroundColor: colors.buttonBackground[100],
                  color: colors.buttonTextColor[100],
                },
              }}
            >
              <HomeIcon sx={{ mr: "8px" }}></HomeIcon>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    fontFamily='Inter'
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Dashboard
                  </Typography>
                }
                sx={{
                  pl: 2,
                }}
              />
            </ListItem> */}
            <ListItem
              button
              onClick={handleClickNested}
              sx={{
                width: "240px!important",
                marginTop: "8px",
                ...(openNested && {
                  color: colors.buttonTextColor[100],
                }),
              }}
            >
              <CompanyData sx={{ mr: "8px" }} />
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    fontFamily='Inter'
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {"Company Data"}
                  </Typography>
                }
                sx={{
                  pl: 2,
                }}
              />
              {nestedItems && (openNested ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
            {nestedItems && (
              <Collapse
                in={openNested}
                timeout={{ enter: 500, exit: 300 }}
                unmountOnExit
              >
                <List
                  sx={{
                    "&.MuiList-padding": {
                      padding: "0px!important",
                    },
                    width: "240px!important",
                  }}
                >
                  {nestedItems.map((item) => (
                    <ListItem
                      button
                      key={item.text}
                      component={Link}
                      to={item.path}
                      selected={selected === item.text}
                      onClick={() => handleClick(item)}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: colors.buttonBackground[100],
                          color: colors.buttonTextColor[100],
                          borderRight: "4px solid #3F51B5",
                          borderRightColor: colors.buttonTextColor[100],
                          fontFamily: "Roboto!important",
                        },
                        paddingLeft: "50px!important",

                        "&.Mui-selected:hover": {
                          backgroundColor: colors.buttonBackground[100],
                          color: colors.buttonTextColor[100],
                        },
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            fontFamily='Inter'
                            style={{
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            {item.text}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
            <ListItem
              button
              onClick={handleClickNested2}
              sx={{
                width: "240px!important",
                marginTop: "8px",
                ...(openNested2 && {
                  color: colors.buttonTextColor[100],
                }),
              }}
            >
              <ReportsIcon sx={{ mr: "8px" }} />
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    fontFamily='Inter'
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    {"Reports"}
                  </Typography>
                }
                sx={{
                  pl: 2,
                }}
              />
              {nestedItems2 && (openNested2 ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
            {nestedItems2 && (
              <Collapse
                in={openNested2}
                timeout={{ enter: 500, exit: 300 }}
                unmountOnExit
              >
                <List
                  sx={{
                    "&.MuiList-padding": {
                      padding: "0px!important",
                    },
                    width: "240px!important",
                  }}
                >
                  {nestedItems2.map((item) => (
                    <ListItem
                      button
                      key={item.text}
                      component={Link}
                      to={item.path}
                      selected={selected === item.text}
                      onClick={() => handleClick(item)}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: colors.buttonBackground[100],
                          color: colors.buttonTextColor[100],
                          borderRight: "4px solid #3F51B5",
                          borderRightColor: colors.buttonTextColor[100],
                          fontFamily: "Roboto!important",
                        },
                        paddingLeft: "50px!important",

                        "&.Mui-selected:hover": {
                          backgroundColor: colors.buttonBackground[100],
                          color: colors.buttonTextColor[100],
                        },
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            fontFamily='Inter'
                            style={{
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            {item.text}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
            <ListItem
              button
              onClick={handleClickNested3}
              sx={{
                width: "240px!important",
                marginTop: "8px",
                ...(openNested3 && {
                  color: colors.buttonTextColor[100],
                }),
              }}
            >
              <BarChart sx={{ mr: "8px" }} />
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    fontFamily='Inter'
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Analyses
                  </Typography>
                }
                sx={{
                  pl: 2,
                }}
              />
              {nestedItems3 && (openNested3 ? <ExpandLess /> : <ExpandMore />)}
            </ListItem>
            {nestedItems3 && (
              <Collapse
                in={openNested3}
                timeout={{ enter: 500, exit: 300 }}
                unmountOnExit
              >
                <List
                  sx={{
                    "&.MuiList-padding": {
                      padding: "0px!important",
                    },
                    width: "240px!important",
                  }}
                >
                  {nestedItems3.map((item) => (
                    <ListItem
                      button
                      key={item.text}
                      component={Link}
                      to={item.path}
                      selected={selected === item.text}
                      onClick={() => handleClick(item)}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: colors.buttonBackground[100],
                          color: colors.buttonTextColor[100],
                          borderRight: "4px solid #3F51B5",
                          borderRightColor: colors.buttonTextColor[100],
                          fontFamily: "Roboto!important",
                        },
                        paddingLeft: "50px!important",

                        "&.Mui-selected:hover": {
                          backgroundColor: colors.buttonBackground[100],
                          color: colors.buttonTextColor[100],
                        },
                      }}
                    >
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            fontFamily='Inter'
                            style={{
                              fontSize: "12px",
                              fontWeight: "500",
                            }}
                          >
                            {item.text}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}

            <Box
              sx={{
                marginTop: "24px",
                marginLeft: "16px",
                marginRight: "16px",
                width: "85%",
                height: "1px",
                backgroundColor: colors.sideBorder[100],
              }}
            ></Box>
            {/* <ListItem
                            button
                            key={"FAQ"}
                            component={Link}
                            to={"/"}
                            selected={selected === "FAQ"}
                            onClick={() => handleClickDashboard("FAQ")} // This is the function that changes the selected item")}
                            sx={{
                                "&.Mui-selected": {
                                    backgroundColor:
                                        colors.buttonBackground[100],
                                    color: colors.buttonTextColor[100],
                                    borderRight: "4px solid #3F51B5",
                                    borderRightColor:
                                        colors.buttonTextColor[100],
                                    fontFamily: "Roboto!important",
                                },
                                width: "240px!important",
                                marginTop: "12px",

                                "&.Mui-selected:hover": {
                                    backgroundColor:
                                        colors.buttonBackground[100],
                                    color: colors.buttonTextColor[100],
                                },
                            }}>
                            <HelpOutlineOutlinedIcon
                                sx={{ mr: "4px" }}></HelpOutlineOutlinedIcon>
                            <ListItemText
                                disableTypography
                                primary={
                                    <Typography
                                        fontFamily="Inter"
                                        style={{
                                            fontSize: "12px",
                                            fontWeight: "600",
                                        }}>
                                        FAQ
                                    </Typography>
                                }
                                sx={{
                                    pl: 2,
                                }}
                            />
                        </ListItem> */}
          </Box>
        </Box>
      </Drawer>
      {!isCollapsed && width <= 1000 && (
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer - 1,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
          open={!isCollapsed}
          onClick={() => setIsCollapsed(true)}
        />
      )}
    </Box>
  );
};

export default Sidebar;
