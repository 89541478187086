import { useState } from "react";
import * as React from "react";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import AddIcon from "@mui/icons-material/Add";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
    Box,
    Button,
    Drawer,
    IconButton,
    TextField,
    Typography,
    CircularProgress,
} from "@mui/material";
import {
    
    useMediaQuery,
    useTheme,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { Formik } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// form validation
import { initialLoanDetails, loanDetailsSchema } from "./formValues"; // import the objects from the new file

import { tokens } from "../../theme";
// Mock data for the tables
import companyFoundingYearGetter from "../../data/companyFoundingYear";

const companyFoundingYear = companyFoundingYearGetter();

const LoanDetailsDrawer = ({
    loanDetailsDrawerOpen,
    toggleLoanDetailsDrawer,
    saveNewLoanDetails,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1000px)");

    return (
        <Drawer
            variant="temporary"
            anchor={"right"}
            open={loanDetailsDrawerOpen}
            onClose={() => toggleLoanDetailsDrawer()}
            PaperProps={{
                sx: {
                    width: {
                        sm: 320,
                        md: 320,
                        lg: 360,
                        xl: 500,
                    },
                    borderRadius: "8px ",
                    border: "1.5px solid",
                    borderColor: colors.sideBorder[100],
                    backgroundColor: colors.backgroundColor[100],
                    backgroundImage: "none",
                    height: "98vh",
                    margin: "8px 8px 8px 8px ",
                },
            }}>
            {/* {list(anchor)} */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid",
                    borderBottomColor: colors.sideBorder[100],
                    alignItems: "center",
                    height: "100px",
                    padding: "16px 16px 8px 16px",
                }}>
                {/* New Add Title */}
                <Box>
                    <Typography
                        variant="h2"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{
                            m: "0 0 0px ",
                            fontFamily: "Inter",
                            fontSize: "20px",
                        }}>
                        <Decoration1></Decoration1>
                        <Box sx={{ display: "inline", ml: "12px" }}>
                            New Loan
                        </Box>
                    </Typography>
                    <Typography ml="30px" variant="h5" color={colors.grey[400]}>
                        Company Funding
                    </Typography>
                </Box>
                <Box sx={{ height: "58px" }}>
                    <IconButton onClick={() => toggleLoanDetailsDrawer()}>
                        <HighlightOffIcon />
                    </IconButton>
                </Box>
            </Box>

            {/* Formik - We use it to create the form inside the*/}
            <Formik
                onSubmit={(values, { setSubmitting }) =>
                    saveNewLoanDetails(values, setSubmitting)
                }
                initialValues={initialLoanDetails}
                validationSchema={loanDetailsSchema}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form style={{ height: "90vh" }} onSubmit={handleSubmit}>
                        <Box
                            height="80%"
                            display="flex"
                            flexDirection="column"
                            columnGap="20px"
                            rowGap="20px"
                            overflow="auto"
                            padding="24px 16px"
                            sx={{
                                "& 	.MuiFilledInput-root:after": {
                                    borderBottomColor: "#2595FF",
                                },
                                "& 	.Mui-focused": {
                                    borderBottomColor: "#2595FF",
                                    color: `${colors.grey[100]} !important`,
                                },
                            }}>
                            <Box fontSize={isNonMobile ? "14px" : "13px"}>
                                A loan is an arrangement under which a lender
                                allows another party the use of funds in
                                exchange for an interest payment and the return
                                of the funds at the end of the lending
                                arrangement. Loans provide liquidity to
                                businesses and individuals, and as such are a
                                necessary part of the financial system.
                            </Box>
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Description"
                                size="small"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.description}
                                name="description"
                                error={
                                    !!touched.description &&
                                    !!errors.description
                                }
                                helperText={
                                    touched.description && errors.description
                                }
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Loan Amount"
                                size="small"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.loanamount}
                                name="loanamount"
                                error={
                                    !!touched.loanamount && !!errors.loanamount
                                }
                                helperText={
                                    touched.loanamount && errors.loanamount
                                }
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Annual Interest (%)"
                                size="small"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.annualinterest}
                                name="annualinterest"
                                error={
                                    !!touched.annualinterest &&
                                    !!errors.annualinterest
                                }
                                helperText={
                                    touched.annualinterest &&
                                    errors.annualinterest
                                }
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Payment Years"
                                size="small"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.paymentyears}
                                name="paymentyears"
                                error={
                                    !!touched.paymentyears &&
                                    !!errors.paymentyears
                                }
                                helperText={
                                    touched.paymentyears && errors.paymentyears
                                }
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="date"
                                label="Date"
                                size="small"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.date}
                                name="date"
                                error={!!touched.date && !!errors.date}
                                helperText={touched.date && errors.date}
                                InputProps={{
                                    inputProps: {
                                        min: companyFoundingYear - 1 + "-01-01",
                                        max: companyFoundingYear + 4 + "-12-31",
                                    },
                                }}
                                defaultValue={new Date(companyFoundingYear)}
                            />

                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Payments in Year"
                                size="small"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.paymentsinyear}
                                name="paymentsinyear"
                                error={
                                    !!touched.paymentsinyear &&
                                    !!errors.paymentsinyear
                                }
                                helperText={
                                    touched.paymentsinyear &&
                                    errors.paymentsinyear
                                }
                            />
                        </Box>
                        <Box
                            sx={{
                                borderTop: "1px solid",
                                borderTopColor: colors.sideBorder[100],
                                pt: "18px",
                                pr: "16px",
                            }}
                            display="flex"
                            gap="8px"
                            disabled={isSubmitting}
                            justifyContent="end"
                            mt={isNonMobile ? "50px" : "15px"}>
                            <Button
                                onClick={() => toggleLoanDetailsDrawer()}
                                variant="outlined"
                                size={isNonMobile ? "large" : "medium"}
                                pr="80px"
                                disabled={isSubmitting}
                                sx={{
                                    color: colors.grey[100],
                                    fontWeight: "medium",
                                    borderColor: colors.sideBorder[100],
                                }}>
                                Cancel
                            </Button>
                            <Button
                                startIcon={isSubmitting ? null : <AddIcon />}
                                size={isNonMobile ? "large" : "medium"}
                                type="submit"
                                variant="contained"
                                color="buttonColor"
                                disabled={isSubmitting}
                                sx={{
                                    backgroundColor: "#2595FF",
                                    color: "#FFFFFF",
                                }}>
                                {isSubmitting ? (
                                    <CircularProgress
                                        size={24}
                                        style={{ color: colors.grey[100] }}
                                    />
                                ) : (
                                    "New Entry"
                                )}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Drawer>
    );
};

export default LoanDetailsDrawer;
