import * as yup from "yup";
// establishment Costs form

export const merchSchema = yup.object().shape({
    cost0: yup
        .number()
        .typeError("Cost must be a valid number")
        .positive("Cost must be a positive number")
        .required("Field is required"),
    cost1: yup
        .number()
        .typeError("Cost must be a valid number")
        .positive("Cost must be a positive number")
        .required("Field is required"),
    cost2: yup
        .number()
        .typeError("Cost must be a valid number")
        .positive("Cost must be a positive number")
        .required("Field is required"),
    cost3: yup
        .number()
        .typeError("Cost must be a valid number")
        .positive("Cost must be a positive number")
        .required("Field is required"),
    cost4: yup
        .number()
        .typeError("Cost must be a valid number")
        .positive("Cost must be a positive number")
        .required("Field is required"),
    name: yup.string("Input must be a String").required("Field is required"),
});

export const initialMerch = {
    cost0: "",
    cost1: "",
    cost2: "",
    cost3: "",
    cost4: "",
    name: "",
};

export const initialProductSchema = {
    name: yup.string("Input must be a String").required("Field is required"),
    type: yup.string("Input must be a String").required("Field is required"),
};

export const initialProducts = {
    name: "",
    type: "",
};

export const materialsSchema = yup.object().shape({
    unitcost: yup
        .number()
        .typeError("Must be a valid number")
        .positive("Must be a positive number")
        .required("Field is required"),
    unitmeasure: yup
        .string("Input must be a String")
        .required("Field is required"),
    name: yup.string("Input must be a String").required("Field is required"),
});

export const initialMaterials = {
    name: "",
    unitmeasure: "",
    unitcost: "",
};
