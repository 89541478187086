import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockLineData as data } from "../data/mockData";

const LineChart = (props) => {
  const { breakEvenValues, insufficientData } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isDashboard = false;
  const {
    fixedCost,
    FixedMinusRevenues,
    salesQuantity,
    salesValue,
    totalCostatZeroSales,
    totalCostatSales,
    totalCostQuantity,
    bepValue,
    bepQuantity,
  } = breakEvenValues;

  const LineData = [
    {
      id: "Sales",
      color: tokens("dark").greenAccent[500],
      data: [
        {
          x: 0,
          y: 0,
        },
        {
          x: Math.max(salesQuantity, bepQuantity),
          y: Math.max(salesValue, bepValue),
        },
      ],
    },
    {
      id: "Total Cost-other Revenues",
      color: "#FAA541",
      data: [
        {
          x: 0,
          y: totalCostatZeroSales,
        },
        {
          x: Math.max(totalCostQuantity, bepQuantity),
          y: Math.max(totalCostatSales, bepValue),
        },
      ],
    },
    {
      id: "Break Even Point",
      color: "#9421EB",
      data: [
        {
          x: bepQuantity,
          y: bepValue,
        },
      ],
    },
  ];

  return insufficientData ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        // backgroundColor: colors.grey[900],
      }}
    >
      <p>
        Before generating the Break Even Point Line Charts, you need to provide
        Cost and Revenue Company Data .
      </p>
    </div>
  ) : Object.keys(breakEvenValues).length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        // backgroundColor: colors.grey[900],
      }}
    >
      <p>Loading Chart </p>
    </div>
  ) : (
    <ResponsiveLine
      data={LineData}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },

          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
          },
        },
      }}
      // Here you put the prices for the fixed cost and fixed cost-other revenues
      markers={[
        // {
        //   axis: "y",
        //   value: 0,
        //   lineStyle: { stroke: "#b0413e", strokeWidth: 1 },
        //   legend: "y marker at 0",
        //   legendPosition: "bottom-left",
        // },
        {
          axis: "y",
          value: fixedCost,

          lineStyle: {
            stroke: "#2595FF",
            strokeWidth: 1.5,
            strokeDasharray: "5,5",
          },
          legend: "Fixed Cost",
          legendPosition: "bottom-left",
          textStyle: {
            fill: colors.grey[100],
          },
        },

        {
          axis: "y",
          value: bepValue,
          lineStyle: {
            stroke: "#9421EB",
            strokeWidth: 1.5,
            strokeDasharray: "5,5",
          },
          textStyle: {
            fill: colors.grey[100],
          },
          legend: "BEP",
          legendPosition: "bottom-left",
        },

        {
          axis: "x",
          value: bepQuantity,
          lineStyle: {
            stroke: "#9421EB",
            strokeWidth: 1.5,
            strokeDasharray: "5,5",
          },
          textStyle: {
            fill: colors.grey[100],
          },
          legend: "BEP",
          legendPosition: "bottom-left",
        },
        {
          axis: "y",
          value: FixedMinusRevenues,
          lineStyle: {
            stroke: "#2F3DBE",
            strokeWidth: 3,
            strokeDasharray: "20,20",
          },
          legend: "Fixed Cost-Other Revenues",
          textStyle: {
            fill: colors.grey[100],
          },
          legendPosition: "top-right",
        },
      ]}
      colors={{ datum: "color" }} // added
      margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
      tooltip={({ point }) => (
        <div
          style={{
            padding: "9px 12px",
            color: colors.grey[100],
            border: "1.5px solid",
            borderColor: colors.sideBorder[100],
            backgroundColor: colors.backgroundColor[100],
            borderRadius: "4px",
          }}
        >
          Quantity: <strong> {point.data.x}</strong>
          <br />
          Revenue <strong> {point.data.y}</strong>
        </div>
      )}
      xScale={{
        type: "linear",
        min: 0,
        max: "auto",
      }}
      yScale={{
        type: "linear",
        min: 0,
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=' >-.2f'
      curve='catmullRom'
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: -1,
        tickPadding: 5,
        tickRotation: 50,
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickValues: 5, // added
        tickSize: 3,
        tickPadding: 5,
        tickRotation: -54,
        legendOffset: -50,
        legendPosition: "middle",
      }}
      enableArea={true}
      areaOpacity={0.09}
      enableGridX={false}
      enableGridY={false}
      pointSize={8}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "top-left",
          direction: "row",
          justify: false,
          translateX: 20,
          translateY: -45,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 170,
          itemHeight: 40,
          itemOpacity: 1,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
        },
      ]}
    />
  );
};

export default LineChart;
