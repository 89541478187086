import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider, AuthContext } from "./firebase/Authentication.js";

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
}

const Application = () => {
    return (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <AuthProvider>
            <Application />
        </AuthProvider>
    </React.StrictMode>
);
