import { useState } from "react";
import * as React from "react";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import AddIcon from "@mui/icons-material/Add";

import {
    Box,
    Button,
    Drawer,
    IconButton,
    TextField,
    Typography,
    CircularProgress,
} from "@mui/material";
import {
    useMediaQuery,
    useTheme,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { Formik } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// form validation
import { revSchema, initialRevValues } from "./formValues"; // import the objects from the new file
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file

import { tokens } from "../../theme";
// Mock data for the tables

const companyFoundingYear = companyFoundingYearGetter();

const MerchGlobalRevDrawer = ({
    merchGlobalRevDrawer,
    toggleMerchGlobalRevDrawer,
    saveMerchGlobalRevenues,
    merchandise,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1000px)");
    return (
        <Drawer
            variant="temporary"
            anchor={"right"}
            open={merchGlobalRevDrawer}
            onClose={() => toggleMerchGlobalRevDrawer()}
            PaperProps={{
                sx: {
                    width: {
                        sm: 320,
                        md: 320,
                        lg: 360,
                        xl: 500,
                    },
                    borderRadius: "8px",
                    border: "1.5px solid",
                    borderColor: colors.sideBorder[100],
                    backgroundColor: colors.backgroundColor[100],
                    backgroundImage: "none",
                    height: "98vh",
                    margin: "8px 8px 8px 8px ",
                },
            }}>
            {/* {list(anchor)} */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid",
                    borderBottomColor: colors.sideBorder[100],
                    alignItems: "center",
                    height: "100px",
                    padding: "16px 16px 8px 16px",
                }}>
                {/* New Add Title */}
                <Box>
                    <Typography
                        variant="h2"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{
                            m: "0 0 0px ",
                            fontFamily: "Inter",
                            fontSize: "20px",
                        }}>
                        <Decoration1></Decoration1>
                        <Box sx={{ display: "inline", ml: "12px" }}>
                            New Global Merchandise
                        </Box>
                    </Typography>
                    <Typography ml="30px" variant="h5" color={colors.grey[400]}>
                        Revenue
                    </Typography>
                </Box>
                <Box sx={{ height: "58px" }}>
                    <IconButton onClick={() => toggleMerchGlobalRevDrawer()}>
                        <HighlightOffIcon />
                    </IconButton>
                </Box>
            </Box>
            <Formik
                onSubmit={(values, { setSubmitting }) =>
                    saveMerchGlobalRevenues(values, setSubmitting)
                }
                initialValues={initialRevValues}
                validationSchema={revSchema}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form style={{ height: "90vh" }} onSubmit={handleSubmit}>
                        <Box
                            height="80%"
                            display="flex"
                            flexDirection="column"
                            columnGap="20px"
                            rowGap="20px"
                            overflow="auto"
                            padding="24px 16px"
                            sx={{
                                "& 	.MuiFilledInput-root:after": {
                                    borderBottomColor: "#2595FF",
                                },
                                "& 	.Mui-focused": {
                                    borderBottomColor: "#2595FF",
                                    color: `${colors.grey[100]} !important`,
                                },
                            }}>
                            <TextField
                                fullWidth
                                select
                                variant="filled"
                                type="text"
                                label="Description"
                                size="small"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.description}
                                name="description"
                                error={
                                    !!touched.description &&
                                    !!errors.description
                                }
                                helperText={
                                    touched.description && errors.description
                                }>
                                {merchandise.map((merch) => (
                                    <MenuItem value={merch.dbid}>
                                        {merch.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {[...Array(5)].map((_, index) => (
                                <Box key={index} display="flex" gap={2}>
                                    <TextField
                                        fullWidth
                                        id={`sellingprice${index}`}
                                        variant="filled"
                                        type="text"
                                        label={`Selling Price in ${
                                            companyFoundingYear + index
                                        }`}
                                        size="small"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values[`sellingprice${index}`]}
                                        name={`sellingprice${index}`}
                                        error={
                                            !!touched[`sellingprice${index}`] &&
                                            !!errors[`sellingprice${index}`]
                                        }
                                        helperText={
                                            touched[`sellingprice${index}`] &&
                                            errors[`sellingprice${index}`]
                                        }
                                    />
                                    <TextField
                                        id={`quantity${index}`}
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={`Quantity Sold in ${
                                            companyFoundingYear + index
                                        }`}
                                        size="small"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values[`quantity${index}`]}
                                        name={`quantity${index}`}
                                        error={
                                            !!touched[`quantity${index}`] &&
                                            !!errors[`quantity${index}`]
                                        }
                                        helperText={
                                            touched[`quantity${index}`] &&
                                            errors[`quantity${index}`]
                                        }
                                    />
                                </Box>
                            ))}
                        </Box>
                        <Box
                            sx={{
                                borderTop: "1px solid",
                                borderTopColor: colors.sideBorder[100],
                                pt: "18px",
                                pr: "16px",
                            }}
                            display="flex"
                            gap="8px"
                            disabled={isSubmitting}
                            justifyContent="end"
                            mt={isNonMobile ? "50px" : "15px"}>
                            <Button
                                onClick={() => toggleMerchGlobalRevDrawer()}
                                variant="outlined"
                                size={isNonMobile ? "large" : "medium"}
                                pr="80px"
                                disabled={isSubmitting}
                                sx={{
                                    color: colors.grey[100],
                                    fontWeight: "medium",
                                    borderColor: colors.sideBorder[100],
                                }}>
                                Cancel
                            </Button>
                            <Button
                                startIcon={isSubmitting ? null : <AddIcon />}
                                size={isNonMobile ? "large" : "medium"}
                                type="submit"
                                variant="contained"
                                color="buttonColor"
                                disabled={isSubmitting}
                                sx={{
                                    backgroundColor: "#2595FF",
                                    color: "#FFFFFF",
                                }}>
                                {isSubmitting ? (
                                    <CircularProgress
                                        size={24}
                                        style={{ color: colors.grey[100] }}
                                    />
                                ) : (
                                    "New Entry"
                                )}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Drawer>
    );
};

export default MerchGlobalRevDrawer;
