export const mockEstablishmentData = [
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 2,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 3,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
    {
        id: 1,
        cost: 1500,
        depreciation: 5,
        age: 35,
        category: "Equipment",
        participationToTotal: 20 + "%",
    },
];

export const mockInvestmentData = [
    {
        id: 1,
        description: "Equipment",
        amount: 1500,
        depreciation: 5,
        description: "Equipment",
        participationToTotal: 20 + "%",
        year: 2021,
    },
    {
        id: 1,
        description: "Equipment",
        amount: 1500,
        depreciation: 5,
        description: "Equipment",
        participationToTotal: 20 + "%",
        year: 2021,
    },
    {
        id: 1,
        description: "Equipment",
        amount: 1500,
        depreciation: 5,
        description: "Equipment",
        participationToTotal: 20 + "%",
        year: 2021,
    },
    {
        id: 1,
        description: "Equipment",
        amount: 1500,
        depreciation: 5,
        description: "Equipment",
        participationToTotal: 20 + "%",
        year: 2021,
    },
    {
        id: 1,
        description: "Equipment",
        amount: 1500,
        depreciation: 5,
        description: "Equipment",
        participationToTotal: 20 + "%",
        year: 2021,
    },
    {
        id: 1,
        description: "Equipment",
        amount: 1500,
        depreciation: 5,
        description: "Equipment",
        participationToTotal: 20 + "%",
        year: 2021,
    },
    {
        id: 1,
        description: "Equipment",
        amount: 1500,
        depreciation: 5,
        description: "Equipment",
        participationToTotal: 20 + "%",
        year: 2021,
    },
    {
        id: 1,
        description: "Equipment",
        amount: 1500,
        depreciation: 5,
        description: "Equipment",
        participationToTotal: 20 + "%",
        year: 2021,
    },
    {
        id: 1,
        description: "Equipment",
        amount: 1500,
        depreciation: 5,
        description: "Equipment",
        participationToTotal: 20 + "%",
        year: 2021,
    },
    {
        id: 1,
        description: "Equipment",
        amount: 1500,
        depreciation: 5,
        description: "Equipment",
        participationToTotal: 20 + "%",
        year: 2021,
    },
];

export const mockWorkingData = [
    {
        id: 1,
        category: "Human Resources",
        description: "Work Force Salary",
        year1: 20321,
        year2: 2022312,
        year3: 2023123,
        year4: 203123,
        year5: 20123,
    },
];
// Funding data
export const mockFundingStructureData = [
    {
        id: 1,
        participation: 1,
        category: "Business Angels",
    },
    {
        id: 2,
        participation: 1,
        category: "Loan Capital",
        amount: 10000,
    },
    {
        id: 3,
        participation: 1,
        category: "Loan Capital",
        amount: 10000,
    },
];

export const mockLoanDetailsData = [
    {
        id: 1,
        amount: 10000,
        interest: 4.5,
        years: 3,
        date: "2022-01-01",
        paymentsnumber: 12,
    },
    {
        id: 2,
        amount: 5000,
        interest: 3.2,
        years: 2,
        date: "2021-12-15",
        paymentsnumber: 26,
    },
    {
        id: 3,
        amount: 15000,
        interest: 5.0,
        years: 4,
        date: "2022-03-07",
        paymentsnumber: 12,
    },
    {
        id: 4,
        amount: 8000,
        interest: 4.0,
        years: 5,
        date: "2021-11-30",
        paymentsnumber: 12,
    },
    {
        id: 5,
        amount: 12000,
        interest: 3.8,
        years: 3,
        date: "2022-02-28",
        paymentsnumber: 24,
    },
];

export const mockPieData = [
    {
        id: "Loan Capital",
        label: "Initial Loan Capital",
        value: 42,
        color: "hsl(273, 70%, 50%)",
    },
    {
        id: "Business Angels",
        label: "Business Angels",
        value: 90,
        color: "hsl(200, 70%, 50%)",
    },
    {
        id: "Equity Capitals",
        label: "Equity Capitals",
        value: 417,
        color: "hsl(258, 70%, 50%)",
    },
    {
        id: "Initial Funding",
        label: "Initial Funding",
        value: 171,
        color: "hsl(285, 70%, 50%)",
    },
];

export const mockMerchandiseData = [
    {
        id: 1,
        name: " A",
        year0: 100,
        year1: 200,
        year2: 300,
        year3: 400,
        year4: 500,
    },
    {
        id: 2,
        name: " B",
        year0: 50,
        year1: 100,
        year2: 150,
        year3: 200,
        year4: 250,
    },
    {
        id: 3,
        name: " C",
        year0: 500,
        year1: 600,
        year2: 700,
        year3: 800,
        year4: 900,
    },
    {
        id: 4,
        name: " D",
        year0: 75,
        year1: 125,
        year2: 175,
        year3: 225,
        year4: 275,
    },
    {
        id: 5,
        name: " E",
        year0: 1000,
        year1: 1100,
        year2: 1200,
        year3: 1300,
        year4: 1400,
    },
];
export const mockBarData = [
    {
        product: "Product 1",
        price: 83,
        priceColor: "#9c9eff",
    },
    {
        product: "Product 2",

        price: 27,
        priceColor: "#92b094",
    },
    {
        product: "Product 3",
        price: 18,
        priceColor: "#a8c18b",
    },
    {
        product: "Product 4",
        price: 161,
        priceColor: "#bcd086",
    },
    {
        product: "Product 5",

        price: 181,
        priceColor: "#c7d983",
    },
    {
        product: "Product 6",

        price: 181,
        priceColor: "#d9e680",
    },
    {
        product: "Product 7",

        price: 181,
        priceColor: "#e2ed7f",
    },
];

export const mockMaterialsData = [];
export const mockProductsData = [];

export const otherRevData = [];
export const productsRevData = [];
export const merchRevData = [];

export const productsGlobalRevData = [];
export const merchGlobalRevData = [];
