import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockLineData as data } from "../data/mockData";

const LineChartSensitivity = ({ targetVariable, data, inputVariables }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // Colors
    const lineColors = ["#9421EB", "#FAA541", "#2595FF", "#2F3DBE"];

    function transformData(inputData) {
        const keys = ["-0.3", "-0.2", "-0.1", "0", "0.1", "0.2", "0.3"];

        return inputData.map((data, index) => ({
            id: inputVariables[index] + index.toString(),
            color: lineColors[index],
            data: keys.map((key) => ({
                x: `${parseFloat(key) * 100}%`,
                y: data[key].toFixed(2),
            })),
        }));
    }

    return (
        <ResponsiveLine
            data={transformData(data)}
            margin={{ top: 50, right: 130, bottom: 60, left: 60 }}
            theme={{
                axis: {
                    domain: {
                        line: {
                            stroke: colors.grey[100],
                        },
                    },

                    legend: {
                        text: {
                            fill: colors.grey[100],
                            fontWeight: "bold",
                        },
                    },
                    ticks: {
                        line: {
                            stroke: colors.grey[100],
                            strokeWidth: 1,
                        },
                        text: {
                            fill: colors.grey[100],
                        },
                    },
                },
                legends: {
                    text: {
                        fill: colors.grey[100],
                    },
                },
                tooltip: {
                    container: {
                        color: colors.primary[500],
                    },
                },
            }}
            tooltip={({ point }) => (
                <div
                    style={{
                        padding: "9px 12px",
                        color: colors.grey[100],
                        border: "1.5px solid",
                        borderColor: colors.sideBorder[100],
                        backgroundColor: colors.backgroundColor[100],
                        borderRadius: "4px",
                    }}>
                    {point.id}: <strong> {point.data.x}</strong>
                    <br />
                    {targetVariable}:<strong> {point.data.y}</strong>
                </div>
            )}
            xScale={{ type: "point" }}
            yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
                reverse: false,
            }}
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            enableGridX={false}
            axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: targetVariable,
                legendOffset: 36,
                legendPosition: "middle",
            }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            colors={{ datum: "color" }} // added
            useMesh={true}
            legends={[
                {
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemBackground: "rgba(0, 0, 0, .03)",
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
        />
    );
};

export default LineChartSensitivity;
