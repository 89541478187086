import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockLineData as data } from "../data/mockData";

const LineChartEvaluation = ({
  cashFlowValues,
  companyFoundingYear,
  type,
  insufficientData,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isDashboard = false;
  let colorLine;
  if (type === "Net Cash Flow") {
    colorLine = "#BAA6D7";
  } else if (type === "Net Present Value(NCF)") {
    colorLine = "#FAA541";
  } else {
    colorLine = "#6B9CB6";
  }

  let LineData;

  if (
    !cashFlowValues ||
    Object.keys(cashFlowValues).length === 0 ||
    insufficientData
  ) {
    LineData = [];
  } else {
    LineData = [
      {
        id: type,
        color: colorLine,
        data: [
          {
            x: String(companyFoundingYear - 1),
            y: cashFlowValues.yearMinus1,
          },

          {
            x: String(companyFoundingYear),
            y: cashFlowValues.year0,
          },
          {
            x: String(companyFoundingYear + 1),
            y: cashFlowValues.year1,
          },
          {
            x: String(companyFoundingYear + 2),
            y: cashFlowValues.year2,
          },
          {
            x: String(companyFoundingYear + 3),
            y: cashFlowValues.year3,
          },
          {
            x: String(companyFoundingYear + 4),
            y: cashFlowValues.year4,
          },
        ],
      },
    ];
  }

  return insufficientData ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        // backgroundColor: colors.grey[900],
      }}
    >
      <p>
        {" "}
        Before generating the Cash Flows Line Charts, you need to provide
        Funding, Cost, Products and Parameters Company Data.
      </p>
    </div>
  ) : !cashFlowValues || Object.keys(cashFlowValues).length === 0 ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        // backgroundColor: colors.grey[900],
      }}
    >
      <p>Loading Chart</p>
    </div>
  ) : (
    <ResponsiveLine
      data={LineData}
      keys={["y"]}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },

          legend: {
            text: {
              fill: colors.grey[100],
              fontWeight: "bold",
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
          },
        },
      }}
      // Here you put the prices for the fixed cost and fixed cost-other revenues

      colors={{ datum: "color" }} // added
      margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        stacked: false,
        min: "auto",
        max: "auto",
      }}
      yFormat=' >-.2f'
      curve='catmullRom'
      axisTop={null}
      axisRight={null}
      tooltip={({ point }) => (
        <div
          style={{
            padding: "9px 12px",
            color: colors.grey[100],
            border: "1.5px solid",
            borderColor: colors.sideBorder[100],
            backgroundColor: colors.backgroundColor[100],
            borderRadius: "4px",
          }}
        >
          Year: <strong>{point.data.x}</strong>
          <br />
          Value: <strong>{point.data.y}</strong>
        </div>
      )}
      axisBottom={{
        orient: "bottom",
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Year", // added
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickValues: 5, // added
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -54,
        legendOffset: -50,
        legendPosition: "middle",
      }}
      enableArea={true}
      areaOpacity={0.06}
      enableGridX={false}
      enableGridY={false}
      pointSize={8}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "top-left",
          direction: "row",
          justify: false,
          translateX: 30,
          translateY: -35,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 170,
          itemHeight: 40,
          itemOpacity: 1,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          // effects: [
          //   {
          //     on: "hover",
          //     style: {
          //       itemBackground: "rgba(0, 0, 0, .03)",
          //       itemOpacity: 1,
          //     },
          //   },
          // ],
        },
      ]}
    />
  );
};

export default LineChartEvaluation;
