import * as yup from "yup";

export const workingCapitalSchema = yup.object().shape({
    inventoryDays: yup
        .number()
        .typeError("Days of Inventory on Hand must be a valid number")
        .positive("Days of Inventory on Hand must be a positive number")
        .required("Field is required"),
    salesDays: yup
        .number()
        .typeError("Days of Sales in Inventory must be a valid number")
        .positive("Days of Sales in Inventory must be a positive number")
        .required("Field is required"),
    finishedGoodsDays: yup
        .number()
        .typeError("Days of Finished Goods Inventory must be a valid number")
        .positive("Days of Finished Goods Inventory must be a positive number")
        .required("Field is required"),
    expensesDays: yup
        .number()
        .typeError("Days of Operating Expenses Coverage must be a valid number")
        .positive(
            "Days of Operating Expenses Coverage must be a positive number"
        )
        .required("Field is required"),
    accountsReceivableDays: yup
        .number()
        .typeError("Days of Accounts Receivable must be a valid number")
        .positive("Days of Accounts Receivable must be a positive number")
        .required("Field is required"),
    accountsPayableDays: yup
        .number()
        .typeError("Days of Accounts Payable must be a valid number")
        .positive("Days of Accounts Payable must be a positive number")
        .required("Field is required"),
});

export const initialWorkingCapital = {
    inventoryDays: "",
    salesDays: "",
    finishedGoodsDays: "",
    expensesDays: "",
    accountsReceivableDays: "",
    accountsPayableDays: "",
};
