import { useState, useEffect } from "react";
import * as React from "react";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import { Add, Delete } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import urls from "../../data/cloudFunctionUrls";
import auth from "../../firebase/firebase";

import {
    Box,
    Button,
    Drawer,
    IconButton,
    TextField,
    Typography,
    CircularProgress,
} from "@mui/material";
import {
    useMediaQuery,
    useTheme,
    MenuItem,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import { Formik } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// form validation
import {
    sensitivitySchema_1,
    sensitivitySchema_2,
    initialSensitivityValues,
} from "./formValues"; // import the objects from the new file

import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file

import { tokens } from "../../theme";
// Mock data for the tables

const SensitivityDrawer = ({
    openSensitivity,
    toggleSensitivity,
    saveSensitivity,
    setBeenConfigured,
    setVariables,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1000px)");

    // Adding more input fields
    const [accordions, setAccordions] = useState([1]);

    const addAccordion = () => {
        if (accordions.length < 2) {
            const newAccordion = accordions.length + 1;
            setAccordions([...accordions, newAccordion]);
        }
    };

    const deleteAccordion = (index) => {
        const updatedAccordions = accordions.filter((_, i) => i !== index);
        setAccordions(updatedAccordions);
    };

    // const products = ["Product 1", "Product 2", "Product 3"];
    const [products, setProducts] = useState(["-"]);
    const OperationCosts = [
        "Personnel Costs",
        "Operational Costs",
        "Transportation Costs",
        "Advertisment Costs",
        "Other Costs",
    ];
    const companyFoundingYear = companyFoundingYearGetter();

    useEffect(() => {
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/getproductsandmerch", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    }
                })
                .then((data) => {
                    let prodMerchArray = [];

                    data.products.forEach((product) => {
                        prodMerchArray.push(product.name);
                    });

                    data.merchandise.forEach((merch) => {
                        prodMerchArray.push(merch.name);
                    });

                    setProducts(prodMerchArray);
                })
                .catch((err) => {
                    // console.log(err);
                    // setFetchingProductsData(false);
                });
        });
    }, []);

    const [sensitivitySchema, setSensitivitySchema] =
        useState(sensitivitySchema_1);

    useEffect(() => {
        if (accordions.length === 2) {
            setSensitivitySchema(sensitivitySchema_2);
        } else {
            setSensitivitySchema(sensitivitySchema_1);
        }
    }, [accordions]);

    return (
        <Drawer
            variant="temporary"
            anchor={"right"}
            open={openSensitivity}
            onClose={() => toggleSensitivity()}
            PaperProps={{
                sx: {
                    width: {
                        sm: "97.5%",
                        md: 580,
                        lg: 660,
                        xl: 660,
                    },
                    borderRadius: "8px",
                    border: "1.5px solid",
                    borderColor: colors.sideBorder[100],
                    backgroundColor: colors.backgroundColor[100],
                    backgroundImage: "none",
                    height: "98vh",
                    margin: "8px 8px 8px 8px ",
                },
            }}>
            {/* {list(anchor)} */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid",
                    borderBottomColor: colors.sideBorder[100],
                    alignItems: "center",
                    height: "100px",
                    padding: "16px 16px 8px 16px",
                }}>
                {/* New Add Title */}
                <Box>
                    <Typography
                        variant="h2"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{
                            m: "0 0 0px ",
                            fontFamily: "Inter",
                            fontSize: "20px",
                        }}>
                        <Decoration1></Decoration1>
                        <Box sx={{ display: "inline", ml: "12px" }}>
                            Sensitivity Analysis Configuration
                        </Box>
                    </Typography>
                    <Typography ml="30px" variant="h5" color={colors.grey[400]}>
                        Setup your analysis
                    </Typography>
                </Box>
                <Box sx={{ height: "58px" }}>
                    <IconButton onClick={() => toggleSensitivity()}>
                        <HighlightOffIcon />
                    </IconButton>
                </Box>
            </Box>
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    console.log("values: ", values);
                    saveSensitivity(values, setSubmitting);
                    setBeenConfigured(true);

                    let inputVariables = [];
                    for (let i = 0; i < accordions.length; i++) {
                        // IF WE NEED MORE DETAILS
                        // inputVariables.push(
                        //     values[`category_${i}`] +
                        //         (values[`firstparam_${i}`]
                        //             ? "\n" + `${values[`firstparam_${i}`]}`
                        //             : "") +
                        //         (values[`secondparam_${i}`]
                        //             ? "\n" + `${values[`secondparam_${i}`]}`
                        //             : "")
                        // );

                        inputVariables.push(values[`category_${i}`]);
                    }

                    let outputVariable =
                        values.outputcategory +
                        (values.outputparam ? `_${values.outputparam}` : "");

                    setVariables(inputVariables, outputVariable);

                    toggleSensitivity();
                }}
                initialValues={initialSensitivityValues}
                validationSchema={sensitivitySchema}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form
                        style={{ height: "90vh", overflowY: "hidden" }}
                        onSubmit={handleSubmit}>
                        <Box
                            height="80%"
                            display="flex"
                            flexDirection="column"
                            columnGap="20px"
                            rowGap="20px"
                            overflow="auto"
                            padding="24px 16px"
                            sx={{
                                "& 	.MuiFilledInput-root:after": {
                                    borderBottomColor: "#2595FF",
                                },
                                "& 	.Mui-focused": {
                                    borderBottomColor: "#2595FF",
                                    color: `${colors.grey[100]} !important`,
                                },
                            }}>
                            <Box>
                                <Typography
                                    variant="h5"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{
                                        m: "0 0 0px ",
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                    }}>
                                    Input Variables
                                </Typography>
                                <Box
                                    color={colors.grey[300]}
                                    maxWidth={"100%"}
                                    fontSize={"13px"}
                                    mb={"12px"}
                                    mt={"8px"}>
                                    These variables will be varied
                                    systematically to observe how they affect
                                    the outputs variables. You can select up to
                                    2 input variables.
                                </Box>
                            </Box>

                            {accordions.map((accordion, index) => (
                                <Accordion
                                    key={accordion}
                                    sx={{
                                        borderWidth: "1px",
                                        borderStyle: "solid",
                                        // marginBottom: "16px",
                                        borderRadius: "8px",

                                        boxShadow: "none", // Remove box shadow

                                        borderColor: colors.sideBorder[100],
                                        backgroundColor:
                                            colors.backgroundColor[100],
                                        "&:before": {
                                            display: "none",
                                        },
                                        "&.Mui-expanded": {
                                            margin: "0px",
                                        },
                                    }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}>
                                        <Typography>
                                            Input Variable {accordion}
                                        </Typography>
                                        {index > 0 && (
                                            <IconButton
                                                variant="outlined"
                                                color="error"
                                                size="small"
                                                onClick={() =>
                                                    deleteAccordion(index)
                                                }
                                                sx={{
                                                    padding: "0px",
                                                    pl: "8px",
                                                    color: colors.grey[300], // Set the normal color
                                                    transition: "color 0.3s", // Add a transition for smooth hover effect
                                                    "&:hover": {
                                                        color: "#ff0000", // Set the color on hover
                                                    },
                                                }}>
                                                <Delete />
                                            </IconButton>
                                        )}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Divider
                                            sx={{
                                                ml: "0px",
                                                mr: "0px",
                                                mb: "16px",
                                            }}
                                            variant="middle"
                                        />
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                rowGap: "16px",
                                            }}>
                                            <TextField
                                                fullWidth
                                                select
                                                variant="filled"
                                                type="text"
                                                label="Category"
                                                size="small"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={
                                                    values[`category_${index}`]
                                                } // Use dynamic index for value
                                                name={`category_${index}`} // Use dynamic index for name
                                                error={
                                                    touched[
                                                        `category_${index}`
                                                    ] &&
                                                    errors[`category_${index}`]
                                                } // Use dynamic index for error and touched
                                                helperText={
                                                    touched[
                                                        `category_${index}`
                                                    ] &&
                                                    errors[`category_${index}`]
                                                } // Use dynamic index for helper text
                                            >
                                                <MenuItem value="Product Price">
                                                    Product Price
                                                </MenuItem>
                                                <MenuItem value="Product Sales Quantity">
                                                    Product Sales Quantity
                                                </MenuItem>
                                                <MenuItem value="Production Cost">
                                                    Production Cost
                                                </MenuItem>
                                                <MenuItem value="Operation Costs">
                                                    Operation Costs
                                                </MenuItem>
                                            </TextField>

                                            {values[`category_${index}`] ===
                                                "Product Price" && (
                                                <>
                                                    <TextField
                                                        fullWidth
                                                        select
                                                        variant="filled"
                                                        type="text"
                                                        label="Company`s Product"
                                                        size="small"
                                                        name={`firstparam_${index}`}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={
                                                            values[
                                                                `firstparam_${index}`
                                                            ]
                                                        }
                                                        error={
                                                            touched[
                                                                `firstparam_${index}`
                                                            ] &&
                                                            errors[
                                                                `firstparam_${index}`
                                                            ]
                                                        }
                                                        helperText={
                                                            touched[
                                                                `firstparam_${index}`
                                                            ] &&
                                                            errors[
                                                                `firstparam_${index}`
                                                            ]
                                                        }>
                                                        {products.map(
                                                            (product) => (
                                                                <MenuItem
                                                                    key={
                                                                        product
                                                                    }
                                                                    value={
                                                                        product
                                                                    }>
                                                                    {product}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </TextField>
                                                    <TextField
                                                        fullWidth
                                                        select
                                                        variant="filled"
                                                        type="text"
                                                        label="Year"
                                                        size="small"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={
                                                            values[
                                                                `secondparam_${index}`
                                                            ]
                                                        }
                                                        name={`secondparam_${index}`} // Use dynamic index for name
                                                        error={
                                                            !!touched[
                                                                `secondparam_${index}`
                                                            ] &&
                                                            !!errors[
                                                                `secondparam_${index}`
                                                            ]
                                                        }
                                                        helperText={
                                                            touched[
                                                                `secondparam_${index}`
                                                            ] &&
                                                            errors[
                                                                `secondparam_${index}`
                                                            ]
                                                        }>
                                                        {Array(5)
                                                            .fill()
                                                            .map((_, index) => (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={String(
                                                                        index
                                                                    )}>
                                                                    {companyFoundingYear +
                                                                        index}
                                                                </MenuItem>
                                                            ))}
                                                    </TextField>
                                                    Select the product you want
                                                    to vary the price of and in
                                                    which year.
                                                </>
                                            )}
                                            {values[`category_${index}`] ===
                                                "Product Sales Quantity" && (
                                                <>
                                                    <TextField
                                                        fullWidth
                                                        select
                                                        variant="filled"
                                                        type="text"
                                                        label="Products "
                                                        size="small"
                                                        name={`firstparam_${index}`}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={
                                                            values[
                                                                `firstparam_${index}`
                                                            ]
                                                        }
                                                        error={
                                                            touched[
                                                                `firstparam_${index}`
                                                            ] &&
                                                            errors[
                                                                `firstparam_${index}`
                                                            ]
                                                        }
                                                        helperText={
                                                            touched[
                                                                `firstparam_${index}`
                                                            ] &&
                                                            errors[
                                                                `firstparam_${index}`
                                                            ]
                                                        }>
                                                        {products.map(
                                                            (product) => (
                                                                <MenuItem
                                                                    key={
                                                                        product
                                                                    }
                                                                    value={
                                                                        product
                                                                    }>
                                                                    {product}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </TextField>
                                                    <TextField
                                                        fullWidth
                                                        select
                                                        variant="filled"
                                                        type="text"
                                                        label="Year"
                                                        size="small"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={
                                                            values[
                                                                `secondparam_${index}`
                                                            ]
                                                        }
                                                        name={`secondparam_${index}`} // Use dynamic index for name
                                                        error={
                                                            !!touched[
                                                                `secondparam_${index}`
                                                            ] &&
                                                            !!errors[
                                                                `secondparam_${index}`
                                                            ]
                                                        }
                                                        helperText={
                                                            touched[
                                                                `secondparam_${index}`
                                                            ] &&
                                                            errors[
                                                                `secondparam_${index}`
                                                            ]
                                                        }>
                                                        {Array(5)
                                                            .fill()
                                                            .map((_, index) => (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={String(
                                                                        index
                                                                    )}>
                                                                    {companyFoundingYear +
                                                                        index}
                                                                </MenuItem>
                                                            ))}
                                                    </TextField>
                                                    Select the product you want
                                                    to vary the sales quantity
                                                    of and in which year
                                                </>
                                            )}

                                            {values[`category_${index}`] ===
                                                "Production Cost" && (
                                                <>
                                                    <TextField
                                                        fullWidth
                                                        select
                                                        variant="filled"
                                                        type="text"
                                                        label="Products "
                                                        size="small"
                                                        name={`firstparam_${index}`}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={
                                                            values[
                                                                `firstparam_${index}`
                                                            ]
                                                        }
                                                        error={
                                                            touched[
                                                                `firstparam_${index}`
                                                            ] &&
                                                            errors[
                                                                `firstparam_${index}`
                                                            ]
                                                        }
                                                        helperText={
                                                            touched[
                                                                `firstparam_${index}`
                                                            ] &&
                                                            errors[
                                                                `firstparam_${index}`
                                                            ]
                                                        }>
                                                        {products.map(
                                                            (product) => (
                                                                <MenuItem
                                                                    key={
                                                                        product
                                                                    }
                                                                    value={
                                                                        product
                                                                    }>
                                                                    {product}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </TextField>
                                                    Select the product you want
                                                    to vary the production cost
                                                    of and in which year.
                                                </>
                                            )}
                                            {values[`category_${index}`] ===
                                                "Operation Costs" && (
                                                <>
                                                    <TextField
                                                        fullWidth
                                                        select
                                                        variant="filled"
                                                        type="text"
                                                        label="Operation Cost Category"
                                                        size="small"
                                                        name={`firstparam_${index}`}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={
                                                            values[
                                                                `firstparam_${index}`
                                                            ]
                                                        }
                                                        error={
                                                            touched[
                                                                `firstparam_${index}`
                                                            ] &&
                                                            errors[
                                                                `firstparam_${index}`
                                                            ]
                                                        }
                                                        helperText={
                                                            touched[
                                                                `firstparam_${index}`
                                                            ] &&
                                                            errors[
                                                                `firstparam_${index}`
                                                            ]
                                                        }>
                                                        {OperationCosts.map(
                                                            (operationcost) => (
                                                                <MenuItem
                                                                    key={
                                                                        operationcost
                                                                    }
                                                                    value={
                                                                        operationcost
                                                                    }>
                                                                    {
                                                                        operationcost
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </TextField>
                                                    Select the type of operation
                                                    cost you want to vary the
                                                    cost of and in which year.
                                                </>
                                            )}
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                            <Button
                                variant="outlined"
                                startIcon={<Add />}
                                onClick={addAccordion}
                                disabled={accordions.length >= 2}
                                sx={{
                                    backgroundColor:
                                        colors.buttonBackground[100],
                                    color: colors.buttonTextColor[100],
                                    zIndex: 1,
                                    mb: "16px",

                                    borderColor: colors.buttonColor[100],
                                    "&:hover": {
                                        backgroundColor:
                                            colors.buttonBackground[100],
                                        borderColor: colors.buttonColor[100],
                                    },
                                }}>
                                Add Input Variable
                            </Button>
                            <Divider
                                sx={{
                                    ml: "0px",
                                    mr: "0px",
                                }}
                                variant="middle"
                            />
                            <Box>
                                <Typography
                                    variant="h5"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{
                                        m: "0 0 0px ",
                                        fontFamily: "Inter",
                                        fontSize: "14px",
                                    }}>
                                    Output Variables
                                </Typography>
                                <Box
                                    color={colors.grey[300]}
                                    maxWidth={"100%"}
                                    fontSize={"13px"}
                                    mb={"12px"}
                                    mt={"8px"}>
                                    These variables are the ones you want to
                                    observe how they are affected by the
                                    fluctuation of the input variables. Here you
                                    can select only one.
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "16px",
                                    }}>
                                    <TextField
                                        fullWidth
                                        select
                                        variant="filled"
                                        type="text"
                                        label="Output Variable"
                                        size="small"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.outputcategory}
                                        name="outputcategory"
                                        error={
                                            touched.outputcategory &&
                                            errors.outputcategory
                                        }
                                        helperText={
                                            touched.outputcategory &&
                                            errors.outputcategory
                                        }>
                                        <MenuItem value="Net Cash Flow">
                                            Net Cash Flow
                                        </MenuItem>
                                        <MenuItem value="Accumulative Cash Flow">
                                            Accumulative Cash Flow
                                        </MenuItem>
                                        <MenuItem value="IRR(5 Years)">
                                            IRR(5 Years)
                                        </MenuItem>
                                        <MenuItem value="IRR(inf)">
                                            IRR(∞)
                                        </MenuItem>
                                        <MenuItem value="NPV(5 Years)">
                                            NPV(5 Years)
                                        </MenuItem>
                                        <MenuItem value="NPV(inf)">
                                            NPV(∞)
                                        </MenuItem>
                                        {/* <MenuItem value="Weighted Average Cost">
                                            Weighted Average Cost
                                        </MenuItem> */}
                                        <MenuItem value="Present Value of Total Investment">
                                            Present Value of Total Investment
                                        </MenuItem>
                                        <MenuItem value="Recovery Period">
                                            Recovery Period
                                        </MenuItem>
                                    </TextField>
                                    {(values.outputcategory ===
                                        "Net Cash Flow" ||
                                        values.outputcategory ===
                                            "Accumulative Cash Flow") && (
                                        <>
                                            <TextField
                                                fullWidth
                                                select
                                                variant="filled"
                                                type="text"
                                                label="Year"
                                                size="small"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.outputparam}
                                                name="outputparam"
                                                error={
                                                    !!touched.outputparam &&
                                                    !!errors.outputparam
                                                }
                                                helperText={
                                                    touched.outputparam &&
                                                    errors.outputparam
                                                }>
                                                {Array(5)
                                                    .fill()
                                                    .map((_, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={String(
                                                                index
                                                            )}>
                                                            {companyFoundingYear +
                                                                index}
                                                        </MenuItem>
                                                    ))}
                                            </TextField>
                                            Select the product you want to vary
                                            the sales quantity of and in which
                                            year
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                borderTop: "1px solid",
                                borderTopColor: colors.sideBorder[100],
                                pt: "18px",

                                pr: "16px",
                            }}
                            display="flex"
                            gap="8px"
                            disabled={isSubmitting}
                            justifyContent="end"
                            mt={isNonMobile ? "50px" : "15px"}>
                            <Button
                                onClick={() => toggleSensitivity()}
                                variant="outlined"
                                size={isNonMobile ? "large" : "medium"}
                                pr="80px"
                                disabled={isSubmitting}
                                sx={{
                                    color: colors.grey[100],
                                    fontWeight: "medium",
                                    borderColor: colors.sideBorder[100],
                                }}>
                                Cancel
                            </Button>
                            <Button
                                endIcon={isSubmitting ? null : null}
                                size={isNonMobile ? "large" : "medium"}
                                type="submit"
                                variant="outlined"
                                color="buttonColor"
                                disabled={isSubmitting}
                                sx={{
                                    backgroundColor: "#2595FF",
                                    color: "#FFFFFF",
                                }}>
                                {isSubmitting ? (
                                    <CircularProgress
                                        size={24}
                                        style={{ color: colors.grey[100] }}
                                    />
                                ) : (
                                    "Submit"
                                )}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Drawer>
    );
};

export default SensitivityDrawer;
