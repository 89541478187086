import { useState, useEffect } from "react";
import * as React from "react";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import AddIcon from "@mui/icons-material/Add";
import {
    tableStyling,
    tabStylingSettings,
} from "../../globalStyling/tablesStyling";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
    Box,
    Button,
    Drawer,
    IconButton,
    TextField,
    Typography,
    CircularProgress,
    Tab,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// form validation
import * as yup from "yup";
import urls from "../../data/cloudFunctionUrls";
import auth from "../../firebase/firebase";

import { workingCapitalSchema, initialWorkingCapital } from "./formValues"; // import the objects from the new file
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file

import { tokens } from "../../theme";
// Mock data for the tables

function getYearFromDate(timestamp) {
    if (!timestamp) return;
    const date = new Date(timestamp._seconds * 1000);
    const year = date.getFullYear();
    return year;
}

function getMonthFromDate(timestamp) {
    if (!timestamp) return;
    const date = new Date(timestamp._seconds * 1000);
    const month = date.getMonth() + 1; // Adding 1 because getMonth() returns values from 0 to 11
    return month;
}

const SettingsDrawer = ({ DrawerOpen, toggleDrawer, saveNewWCParameters }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1000px)");
    const [value, setValue] = useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //  States for team info
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState("-");
    const [remainingLicenceDays, setRemainingLicenceDays] = useState("-");
    const [companyFoundingDate, setCompanyFoundingDate] = useState(null);
    const [companyDescription, setCompanyDescription] = useState("-");
    const [teamMember1, setTeamMember1] = useState("-");
    const [teamMember2, setTeamMember2] = useState("-");
    const [teamMember3, setTeamMember3] = useState("-");
    const [refresh, setRefresh] = useState(false);

    const [updateValues, setUpdateValues] = useState({
        name: name,
        companyFoundingDate: companyFoundingDate,
        companyDescription: companyDescription,
        teamMember1: teamMember1,
        teamMember2: teamMember2,
        teamMember3: teamMember3,
    });

    useEffect(() => {
        setUpdateValues({
            company_name: name,
            company_start_year: getYearFromDate(companyFoundingDate),
            company_start_month: getMonthFromDate(companyFoundingDate),
            company_description: companyDescription,
            founder1_email: teamMember1,
            founder2_email: teamMember2,
            founder3_email: teamMember3,
        });
    }, [
        name,
        companyFoundingDate,
        companyDescription,
        teamMember1,
        teamMember2,
        teamMember3,
    ]);

    useEffect(() => {
        if (!DrawerOpen) return;

        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/getCompany", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else if (response.status === 404) {
                        alert("No company found");
                    }
                })
                .then(async (data) => {
                    setName(data.company_name);
                    setCompanyFoundingDate(data.company_founding_date);
                    setCompanyDescription(data.company_description);
                    setTeamMember1(data.founder1_email);
                    setTeamMember2(data.founder2_email);
                    setTeamMember3(data.founder3_email);

                    const companyFoundingYear = companyFoundingYearGetter();

                    if (
                        getYearFromDate(data.company_founding_date) !=
                        companyFoundingYear
                    ) {
                        const newCompanyFoundingYear = new Date(
                            data.company_founding_date._seconds * 1000
                        ).getFullYear();

                        // save companyFoundingYear to local storage
                        await localStorage.setItem(
                            "companyFoundingYear",
                            newCompanyFoundingYear
                        );
                        // window.location.replace(window.location.href);
                    }

                    let totalMilliseconds =
                        data.company_license_deadline._seconds * 1000 +
                        data.company_license_deadline._nanoseconds / 1000000;

                    const license_deadline = new Date(totalMilliseconds);
                    const today = new Date();
                    const diffTime = Math.abs(license_deadline - today);
                    const diffDays = Math.ceil(
                        diffTime / (1000 * 60 * 60 * 24)
                    );

                    setRemainingLicenceDays(diffDays);

                    // setLoading(false);

                    // setData(parameterData);
                })
                .catch((error) => {
                    console.log(error);
                    alert("Error fetching data");
                });
        });
    }, [DrawerOpen, refresh]);

    function camelToSnakeCase(str) {
        return str.replace(/([A-Z])/g, "_$1").toLowerCase();
    }

    // function that takes object with keys in camel case and returns object with keys in snake case
    function convertObjectKeysToSnakeCase(obj) {
        let newObj = {};
        for (let key in obj) {
            newObj[camelToSnakeCase(key)] = obj[key];
        }
        return newObj;
    }

    const handleEditButtonClick = () => {
        setIsEditing(!isEditing);
    };

    const handleSaveButtonClick = () => {
        setIsEditing(false);
        // Perform save/update action with the edited information
        console.log(updateValues);

        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/updatecompany", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({
                    updateData: convertObjectKeysToSnakeCase(updateValues),
                }),
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else if (response.status === 404) {
                        alert("No company found");
                    }
                })
                .then((data) => {
                    console.log("data", data);
                    setRefresh(!refresh);

                    // setLoading(false);

                    // setData(parameterData);
                })
                .catch((error) => {
                    console.log(error);
                    alert("Error fetching data");
                });
        });
    };

    //   Styling for the tabs
    const tabStyles = tabStylingSettings(colors);
    //  Styling for the edit button
    const editButtonActive = {
        // padding: "8px 16px",
        borderRadius: 6,
        margin: "16px 0px 8px 16px",

        // borderRadius: 6,
        backgroundColor: colors.buttonBackground[100],
        color: colors.buttonTextColor[100],
        borderColor: colors.buttonColor[100],
        borderColorHover: "none",
        "&:hover": {
            backgroundColor: colors.buttonBackground[100],
            borderColor: colors.buttonColor[100],
            color: colors.buttonTextColor[100],
        },
    };
    const editButtonInactive = {
        margin: "16px 0px 8px 16px",
        borderRadius: 6,
        backgroundColor: "none",
        color: colors.grey[400],
        borderColor: colors.sideBorder[100],
    };

    const renderEditableInputs = () => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    columnGap: "20px",

                    rowGap: "20px",

                    overflow: "auto",

                    padding: "16px 0px",

                    "& 	.MuiFilledInput-root:after": {
                        borderBottomColor: "#2595FF",
                    },
                    "& 	.Mui-focused": {
                        borderBottomColor: "#2595FF",
                        color: `${colors.grey[100]} !important`,
                    },
                }}>
                <TextField
                    label="Name"
                    value={updateValues.company_name}
                    onChange={(e) =>
                        setUpdateValues({
                            ...updateValues,
                            company_name: e.target.value,
                        })
                    }
                    size="small"
                    variant="filled"
                />
                <TextField
                    label="Start Year"
                    type="number"
                    min="1900"
                    value={updateValues.company_start_year}
                    onChange={(e) =>
                        setUpdateValues({
                            ...updateValues,
                            company_start_year: e.target.value,
                        })
                    }
                    size="small"
                    variant="filled"
                />
                <TextField
                    label="Start Month (number)"
                    type="number"
                    min="1"
                    max="12"
                    value={updateValues.company_start_month}
                    onChange={(e) => {
                        setUpdateValues({
                            ...updateValues,
                            company_start_month: e.target.value,
                        });
                    }}
                    size="small"
                    variant="filled"
                />
                <TextField
                    placeholder="Company Description"
                    size="small"
                    variant="filled"
                    multiline
                    value={updateValues.company_description}
                    onChange={(e) => {
                        setUpdateValues({
                            ...updateValues,
                            company_description: e.target.value,
                        });
                    }}
                    rows={2}
                    maxRows={4}
                />
                <TextField
                    label="Member 1 Email"
                    type="email"
                    value={updateValues.founder1_email}
                    onChange={(e) => {
                        setUpdateValues({
                            ...updateValues,
                            founder1_email: e.target.value,
                        });
                    }}
                    size="small"
                    variant="filled"
                />
                <TextField
                    label="Member 2 Email"
                    value={updateValues.founder2_email}
                    onChange={(e) => {
                        setUpdateValues({
                            ...updateValues,
                            founder2_email: e.target.value,
                        });
                    }}
                    size="small"
                    variant="filled"
                />
                <TextField
                    label="Member 3 Email"
                    value={updateValues.founder3_email}
                    onChange={(e) => {
                        setUpdateValues({
                            ...updateValues,
                            founder3_email: e.target.value,
                        });
                    }}
                    size="small"
                    variant="filled"
                />

                <Box
                    sx={{
                        borderTop: "1px solid",
                        borderTopColor: colors.sideBorder[100],
                        pt: "18px",
                        // pr: "16px",
                        mb: "16px",
                    }}
                    display="flex"
                    gap="8px"
                    justifyContent="end"
                    // mt={isNonMobile ? "50px" : "10px"}
                >
                    <Button
                        size={isNonMobile ? "large" : "medium"}
                        type="submit"
                        variant="contained"
                        color="buttonColor"
                        onClick={handleSaveButtonClick}
                        sx={{
                            backgroundColor: "#2595FF",
                            color: "#FFFFFF",
                            // fontFamily: "Arial",
                            // fontSize: "16px",
                            // fontWeight: "bold",
                        }}>
                        {/* {isSubmitting ? (
                  <CircularProgress
                    size={24}
                    style={{ color: colors.grey[100] }}
                    // color="primary"
                  />
                ) : (
                  "Submit"
                )} */}
                        Submit Changes
                    </Button>
                </Box>
            </Box>
        );
    };

    const renderTextInfo = () => {
        return (
            <Box>
                <Box
                    sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: colors.sideBorder[100],
                        maxWidth: isNonMobile ? "80%" : "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pt: "16px",
                        pb: "12px",
                    }}>
                    <Box>
                        <Typography
                            variant="h6"
                            color={colors.grey[100]}
                            fontWeight="bold"
                            sx={{ m: "0 0 1px 0", fontFamily: "Inter" }}>
                            Company Name
                        </Typography>
                        <Typography variant="h7" color={colors.grey[300]}>
                            The name of your company
                        </Typography>
                    </Box>
                    <Typography
                        variant="h6"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{ m: "0 0 1px 0", fontFamily: "Inter" }}>
                        {name}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: colors.sideBorder[100],
                        maxWidth: isNonMobile ? "80%" : "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pt: "16px",
                        pb: "12px",
                    }}>
                    <Box>
                        <Typography
                            variant="h6"
                            color={colors.grey[100]}
                            fontWeight="bold"
                            sx={{ m: "0 0 1px 0", fontFamily: "Inter" }}>
                            Remaining Licence Days
                        </Typography>
                        <Typography variant="h7" color={colors.grey[300]}>
                            The remaining days of your licence
                        </Typography>
                    </Box>
                    <Typography
                        variant="h6"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{ m: "0 0 1px 0", fontFamily: "Inter" }}>
                        {remainingLicenceDays}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: colors.sideBorder[100],
                        maxWidth: isNonMobile ? "80%" : "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pt: "16px",
                        pb: "12px",
                    }}>
                    <Box>
                        <Typography
                            variant="h6"
                            color={colors.grey[100]}
                            fontWeight="bold"
                            sx={{ m: "0 0 1px 0", fontFamily: "Inter" }}>
                            Start Year
                        </Typography>
                        <Typography variant="h7" color={colors.grey[300]}>
                            The year your company was founded
                        </Typography>
                    </Box>
                    <Typography
                        variant="h6"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{ m: "0 0 1px 0", fontFamily: "Inter" }}>
                        {getYearFromDate(companyFoundingDate)}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: colors.sideBorder[100],
                        maxWidth: isNonMobile ? "80%" : "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pt: "16px",
                        pb: "12px",
                    }}>
                    <Box>
                        <Typography
                            variant="h6"
                            color={colors.grey[100]}
                            fontWeight="bold"
                            sx={{ m: "0 0 1px 0", fontFamily: "Inter" }}>
                            Start Month
                        </Typography>
                        <Typography variant="h7" color={colors.grey[300]}>
                            The month your company was founded in number format
                        </Typography>
                    </Box>
                    <Typography
                        variant="h6"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{ m: "0 0 1px 0", fontFamily: "Inter" }}>
                        {getMonthFromDate(companyFoundingDate)}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: colors.sideBorder[100],
                        maxWidth: isNonMobile ? "80%" : "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pt: "16px",
                        pb: "12px",
                    }}>
                    <Box>
                        <Typography
                            variant="h6"
                            color={colors.grey[100]}
                            fontWeight="bold"
                            sx={{ m: "0 0 1px 0", fontFamily: "Inter" }}>
                            Company Description
                        </Typography>
                        <Typography variant="h7" color={colors.grey[300]}>
                            A short description of your company
                        </Typography>
                    </Box>

                    <Typography
                        variant="h7"
                        color={colors.grey[100]}
                        // noWrap={false}
                        fontWeight="bold"
                        sx={{
                            m: "0 0 1px 0",
                            fontFamily: "Inter",
                            maxWidth: "50%",
                        }}>
                        {companyDescription}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: colors.sideBorder[100],
                        maxWidth: isNonMobile ? "80%" : "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pt: "16px",
                        pb: "12px",
                    }}>
                    <Box>
                        <Typography
                            variant="h6"
                            color={colors.grey[100]}
                            fontWeight="bold"
                            sx={{ m: "0 0 1px 0", fontFamily: "Inter" }}>
                            Founder 1 Email
                        </Typography>
                        <Typography variant="h7" color={colors.grey[300]}>
                            The email of the first founder
                        </Typography>
                    </Box>
                    <Typography
                        variant="h6"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{ m: "0 0 1px 0", fontFamily: "Inter" }}>
                        {teamMember1}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: colors.sideBorder[100],
                        maxWidth: isNonMobile ? "80%" : "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pt: "16px",
                        pb: "12px",
                    }}>
                    <Box>
                        <Typography
                            variant="h6"
                            color={colors.grey[100]}
                            fontWeight="bold"
                            sx={{ m: "0 0 1px 0", fontFamily: "Inter" }}>
                            Founder 2 Email
                        </Typography>
                        <Typography variant="h7" color={colors.grey[300]}>
                            The email of the second founder
                        </Typography>
                    </Box>
                    <Typography
                        variant="h6"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{ m: "0 0 1px 0", fontFamily: "Inter" }}>
                        {teamMember2}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        borderBottom: "1px solid",
                        borderBottomColor: colors.sideBorder[100],
                        maxWidth: isNonMobile ? "80%" : "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pt: "16px",
                        pb: "12px",
                    }}>
                    <Box>
                        <Typography
                            variant="h6"
                            color={colors.grey[100]}
                            fontWeight="bold"
                            sx={{ m: "0 0 1px 0", fontFamily: "Inter" }}>
                            Founder 3 Email
                        </Typography>
                        <Typography variant="h7" color={colors.grey[300]}>
                            The email of the third founder
                        </Typography>
                    </Box>
                    <Typography
                        variant="h6"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{ m: "0 0 1px 0", fontFamily: "Inter" }}>
                        {teamMember3}
                    </Typography>
                </Box>
            </Box>
        );
    };

    return (
        <Drawer
            variant="temporary"
            anchor={"right"}
            open={DrawerOpen}
            onClose={toggleDrawer}
            PaperProps={{
                sx: {
                    width: {
                        sm: "100%",
                        md: "80%",
                        lg: "70%",
                        xl: "60%",
                    },

                    border: "1.5px solid",
                    borderColor: colors.sideBorder[100],
                    backgroundColor: colors.backgroundColor[100],
                    backgroundImage: "none",
                    borderRadius: {
                        sm: "0px 0px 0px 0px",
                        md: "8px 8px 8px 8px",
                        lg: "8px 8px 8px 8px",
                        xl: "8px 8px 8px 8px",
                    },

                    height: {
                        sm: "100%",
                        md: "98%",
                        lg: "98%",
                        xl: "98%",
                    },
                    margin: {
                        sm: "0px 0px 0px 0px",
                        md: "8px 8px 8px 8px",
                        lg: "8px 8px 8px 8px ",
                        xl: "8px 8px 16px 8px ",
                    },
                },
            }}>
            {/* {list(anchor)} */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid",
                    borderBottomColor: colors.sideBorder[100],
                    alignItems: "center",
                    height: "100px",
                    padding: "16px 16px 8px 16px",
                }}>
                {/* New Add Title */}
                <Box sx={{ ml: 2 }}>
                    <Typography
                        variant="h2"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{
                            m: "0 0 0px ",
                            fontFamily: "Inter",
                            fontSize: "24px",
                        }}>
                        <Box sx={{ display: "inline" }}>Settings</Box>
                    </Typography>
                    <Typography variant="h5" color={colors.grey[400]}>
                        Manage your account settings and preferences
                    </Typography>
                </Box>
                <Box sx={{ height: "58px" }}>
                    <IconButton size="large" onClick={toggleDrawer}>
                        <HighlightOffIcon />
                    </IconButton>
                </Box>
            </Box>
            <TabContext sx={{ ml: 2 }} value={value}>
                <Box sx={tabStyles}>
                    <TabList
                        variant="scrollable"
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        // textColor='secondary'
                        // indicatorColor='secondary'
                    >
                        <Tab label="Team" value="1" />
                        <Tab label="Password" value="2" />
                        {/* <Tab label='Subscription ' value='3' /> */}
                    </TabList>
                </Box>
                <TabPanel sx={{ padding: 0 }} value="1">
                    <Box sx={{ width: "90%", marginLeft: "24px" }}>
                        <Box
                            sx={{
                                display: "flex",

                                alignItems: "center",
                            }}>
                            <Typography
                                variant="h5"
                                color={colors.grey[100]}
                                fontWeight="bold"
                                sx={{
                                    fontFamily: "Inter",
                                    marginBottom: "16px",
                                    mt: "24px",
                                }}>
                                Team
                            </Typography>

                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleEditButtonClick}
                                sx={
                                    isEditing
                                        ? editButtonActive
                                        : editButtonInactive
                                }
                                endIcon={<ModeEditIcon />}>
                                Edit
                            </Button>
                        </Box>

                        <Box>
                            {isEditing
                                ? renderEditableInputs()
                                : renderTextInfo()}
                        </Box>
                    </Box>
                </TabPanel>
                <TabPanel sx={{ padding: 0 }} value="2">
                    <Box sx={{ width: "90%", marginLeft: "24px" }}>
                        <Typography
                            variant="h5"
                            color={colors.grey[100]}
                            fontWeight="bold"
                            sx={{
                                fontFamily: "Inter",
                                marginBottom: "0px",
                                mt: "24px",
                            }}>
                            Account Password
                        </Typography>
                        <Typography
                            sx={{ fontSize: "14px" }}
                            variant="h5"
                            color={colors.grey[400]}>
                            Please enter your current password to change your
                            password.
                        </Typography>
                        <Formik
                            onSubmit={(values, { setSubmitting }) =>
                                saveNewWCParameters(values, setSubmitting)
                            }
                            initialValues={initialWorkingCapital}
                            validationSchema={workingCapitalSchema}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <form
                                    style={{
                                        height: "50vh",
                                        margin: 0,
                                        padding: 0,
                                    }}
                                    onSubmit={handleSubmit}>
                                    <Box
                                        height="80%"
                                        display="flex"
                                        flexDirection="column"
                                        columnGap="20px"
                                        rowGap="28px"
                                        overflow="auto"
                                        padding="24px 0px"
                                        sx={{
                                            "& 	.MuiFilledInput-root:after": {
                                                borderBottomColor: "#2595FF",
                                            },
                                            "& 	.Mui-focused": {
                                                borderBottomColor: "#2595FF",
                                                color: `${colors.grey[100]} !important`,
                                            },
                                        }}>
                                        <TextField
                                            variant="filled"
                                            type="password"
                                            label="Current Password"
                                            size="small"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.currentPassword}
                                            name="currentPassword"
                                            error={
                                                !!touched.currentPassword &&
                                                !!errors.currentPassword
                                            }
                                            helperText={
                                                touched.currentPassword &&
                                                errors.currentPassword
                                            }
                                            sx={{ width: "60%" }}
                                        />

                                        <TextField
                                            variant="filled"
                                            type="password"
                                            label="New Password"
                                            size="small"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.newPassword}
                                            name="newPassword"
                                            error={
                                                !!touched.newPassword &&
                                                !!errors.newPassword
                                            }
                                            helperText={
                                                touched.newPassword &&
                                                errors.newPassword
                                            }
                                            sx={{ width: "60%" }}
                                        />
                                        <TextField
                                            variant="filled"
                                            type="password"
                                            label="Confirm New Password"
                                            size="small"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.confirmPassword}
                                            name="confirmPassword"
                                            error={
                                                !!touched.confirmPassword &&
                                                !!errors.confirmPassword
                                            }
                                            helperText={
                                                touched.confirmPassword &&
                                                errors.confirmPassword
                                            }
                                            sx={{ width: "60%" }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            borderTop: "1px solid",
                                            borderTopColor:
                                                colors.sideBorder[100],
                                            pt: "18px",
                                            // pr: "16px",
                                            mb: "16px",
                                        }}
                                        display="flex"
                                        gap="8px"
                                        justifyContent="end"
                                        // mt={isNonMobile ? "50px" : "10px"}
                                    >
                                        <Button
                                            size={
                                                isNonMobile ? "large" : "medium"
                                            }
                                            type="submit"
                                            variant="contained"
                                            color="buttonColor"
                                            disabled={true}
                                            sx={{
                                                backgroundColor: "#2595FF",
                                                color: "#FFFFFF",
                                                // fontFamily: "Arial",
                                                // fontSize: "16px",
                                                // fontWeight: "bold",
                                            }}>
                                            {/* {isSubmitting ? (
                  <CircularProgress
                    size={24}
                    style={{ color: colors.grey[100] }}
                    // color="primary"
                  />
                ) : (
                  "Submit"
                )} */}
                                            Reset Password
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </TabPanel>
                <TabPanel sx={{ padding: 0 }} value="3">
                    <Box sx={{ width: "90%", marginLeft: "24px" }}>
                        <Typography
                            variant="h5"
                            color={colors.grey[100]}
                            fontWeight="bold"
                            sx={{
                                fontFamily: "Inter",
                                marginBottom: "16px",
                                mt: "24px",
                            }}>
                            Subscription
                        </Typography>
                        <Formik
                            onSubmit={(values, { setSubmitting }) =>
                                saveNewWCParameters(values, setSubmitting)
                            }
                            initialValues={initialWorkingCapital}
                            validationSchema={workingCapitalSchema}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <form
                                    style={{
                                        height: "90vh",
                                        margin: 0,
                                        padding: 0,
                                    }}
                                    onSubmit={handleSubmit}>
                                    <Box
                                        sx={{
                                            borderTop: "1px solid",
                                            borderTopColor:
                                                colors.sideBorder[100],
                                            pt: "18px",
                                            // pr: "16px",
                                            mb: "16px",
                                        }}
                                        display="flex"
                                        gap="8px"
                                        justifyContent="end"
                                        // mt={isNonMobile ? "50px" : "10px"}
                                    >
                                        <Button
                                            size={
                                                isNonMobile ? "large" : "medium"
                                            }
                                            type="submit"
                                            variant="contained"
                                            color="buttonColor"
                                            disabled={true}
                                            sx={{
                                                backgroundColor: "#2595FF",
                                                color: "#FFFFFF",
                                                // fontFamily: "Arial",
                                                // fontSize: "16px",
                                                // fontWeight: "bold",
                                            }}>
                                            {/* {isSubmitting ? (
                  <CircularProgress
                    size={24}
                    style={{ color: colors.grey[100] }}
                    // color="primary"
                  />
                ) : (
                  "Submit"
                )} */}
                                            Reset Password
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </TabPanel>
            </TabContext>
        </Drawer>
    );
};

export default SettingsDrawer;
