import {
    Box,
    Button,
    Drawer,
    IconButton,
    TextField,
    Typography,
    Tab,
    ButtonGroup,
} from "@mui/material";
import "./ratiosStyling.css";
import ToolbarReport from "../../components/ToolbarReport";
import LoadingSkeleton from "../../components/LoadingSkeleton";
import { DataGrid } from "@mui/x-data-grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { useState, useEffect } from "react";
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file
import { mockLineData as data } from "../../data/mockData";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import { tableStylingReports } from "../../globalStyling/tablesStyling";
import LineChart from "../../components/LineChart";
import Header from "../../components/Header";
import urls from "../../data/cloudFunctionUrls";
import auth from "../../firebase/firebase";
import initialRows from "./initialRows.json";

// title -> backend object dict
const titleDict = {
    Revenue: "",
    "Gross Profit margin": "grossProfitMarginPerYear",
    "Net Profit margin": "netProfitMarginPerYear",
    "Return on equity - ROE": "returnOnEquityPerYear",
    "Return on assets - ROA": "returnOnAssetsPerYear",
    "Financial leverage and capital structure": "",
    "Debt ratio": "debtRatioPerYear",
    "Fixed asset turnover ratio": "fixedAssetTurnoverRatioPerYear",
    "Financial leverage ratio": "financialLeverageRatio",
    "Debt to equity ratio": "debtEquityRatio",
    Liquidity: "",
    "Current ratio": "currentRatioPerYear",
    "Working capital turnover ratio": "operatingWorkingCapitalRatioPerYear",
    "Acid-test ratio": "acidTestRatioPerYear",
    Activity: "",
    "Τurnover ratio": "turnoverRatioPerYear",
    "Performance indicators": "",
    "Sales growth rate": "salesGrowthRatioPerYear",
    "Net income growth rate": "netProfitGrowthRatioPerYear",
};

const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

const objectToTitle = (obj, id, category) => {
    const row = {
        id: id,
        category: category || "",
        year0: null,
        year1: null,
        year2: null,
        year3: null,
        year4: null,
        title: true,
    };
    return row;
};

const objectToRow = (obj, id, category) => {
    const row = {
        id: id,
        category: category || "",
        year0: toPercent(obj["0"], 1) || 0,
        year1: toPercent(obj["1"], 1) || 0,
        year2: toPercent(obj["2"], 1) || 0,
        year3: toPercent(obj["3"], 1) || 0,
        year4: toPercent(obj["4"], 1) || 0,
        title: obj?.title || false,
    };
    return row;
};

const FinancialRatios = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const companyFoundingYear = companyFoundingYearGetter();

    const ratiosGroupingModel = [
        {
            groupId: "Years",
            children: [
                { field: "year0" },
                { field: "year1" },
                { field: "year2" },
                { field: "year3" },
                { field: "year4" },
            ],
        },
    ];

    const columns = [
        {
            field: "category",
            headerName: "Ratio Category",

            width: 280,
            sortable: false,
        },
        ,
        {
            field: "year0",
            headerName: String(companyFoundingYear),
            flex: 0.7,
            minWidth: 140,
            sortable: false,
        },
        {
            field: "year1",
            headerName: String(companyFoundingYear + 1),
            flex: 0.7,
            minWidth: 140,
            sortable: false,
        },
        {
            field: "year2",
            headerName: String(companyFoundingYear + 2),
            flex: 0.7,
            minWidth: 140,
            sortable: false,
        },
        {
            field: "year3",
            headerName: String(companyFoundingYear + 3),
            flex: 0.7,
            minWidth: 140,
            sortable: false,
        },
        {
            field: "year4",
            headerName: String(companyFoundingYear + 4),
            flex: 0.7,
            minWidth: 140,
            sortable: false,
        },
        // add more columns here
    ];
    const tableStyles = tableStylingReports(colors);

    function getRowClassName(params) {
        if (params.row.title) {
            return theme.palette.mode === "dark"
                ? "title-row-dark"
                : "title-row";
        }
    }
    // const [ratioData, setRatioData] = useState(intialRows);
    const [ratioData, setRatioData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [insufficientData, setInsufficientData] = useState(false);

    useEffect(() => {
        setInsufficientData(false);
        // This is the function that will update the values of the break even point
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.reportAPI + "/getCompanyFinancialRatiosAggregations", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else if (response.status === 404) {
                        setInsufficientData(true);
                        setLoading(false);
                    }
                })
                .then((data) => {
                    setLoading(false);
                    data = Object.keys(titleDict).map((key, index) => {
                        if (titleDict[key] === "") {
                            return objectToTitle(
                                data[titleDict[key]],
                                index,
                                key
                            );
                        } else {
                            return objectToRow(
                                data[titleDict[key]],
                                index,
                                key
                            );
                        }
                    });
                    setRatioData(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }, []);

    const CustomNoRowsOverlay = () => {
        return insufficientData ? (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    position: "absolute",
                    zIndex: 10,
                }}>
                <Typography sx={{ mb: "16px", maxWidth: "500px" }}>
                    Before generating the Financial Ratios Report, you need to
                    provide Funding, Cost, Products and Parameters Company Data
                </Typography>
            </Box>
        ) : (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    position: "absolute",
                    zIndex: 10,
                }}>
                <Typography sx={{ mb: "16px", maxWidth: "500px" }}>
                    No rows
                </Typography>
            </Box>
        );
    };

    return (
        <Box m="20px">
            <Header
                title="Financial Ratios"
                subtitle="The position of your economic units"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box ml="15px" height="850px" sx={tableStyles}>
                    <DataGrid
                        loading={loading}
                        rows={ratioData}
                        density="compact"
                        columns={columns}
                        experimentalFeatures={{ columnGrouping: true }}
                        columnGroupingModel={ratiosGroupingModel}
                        components={{
                            LoadingOverlay: LoadingSkeleton,
                            NoRowsOverlay: CustomNoRowsOverlay,
                            Toolbar: () => <ToolbarReport />, //

                            ToolbarReport,
                        }}
                        getRowClassName={getRowClassName}
                    />
                </Box>
            </Box>
        </Box>
    );
};
export default FinancialRatios;
