import { useState } from "react";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import AddIcon from "@mui/icons-material/Add";
import {
    Box,
    Button,
    Drawer,
    IconButton,
    TextField,
    Typography,
    CircularProgress,
    Autocomplete,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// form validation
import {
    initialValuesEstablishment,
    establishmentCostSchema,
} from "./formValues"; // import the objects from the new file

import { tokens } from "../../theme";
// Mock data for the tables

import "./costs.css";

const establishmentCostCategories = [
    "Space setup costs",
    "Infrastructure development",
    "Mechanical equipment",
    "Office equipment",
    "Transportation vehicles",
    "Other equipment",
    "Incorporation expenses",
    "Advertising and promotion expenses",
];

const EstablishmentCostsDrawer = ({
    establishmentDrawerOpen,
    toggleEstablishmentDrawer,
    saveNewEstablishmentCost,
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1000px)");

    return (
        <Drawer
            variant="temporary"
            anchor={"right"}
            open={establishmentDrawerOpen}
            onClose={() => toggleEstablishmentDrawer()}
            PaperProps={{
                sx: {
                    width: {
                        sm: 320,
                        md: 320,
                        lg: 360,
                        xl: 500,
                    },
                    borderRadius: "8px",
                    border: "1.5px solid",
                    borderColor: colors.sideBorder[100],
                    backgroundColor: colors.backgroundColor[100],
                    backgroundImage: "none",
                    height: "98vh",
                    margin: "8px 8px 8px 8px ",
                },
            }}>
            {/* {list(anchor)} */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid",
                    borderBottomColor: colors.sideBorder[100],
                    alignItems: "center",
                    height: "100px",
                    padding: "16px 16px 8px 16px",
                }}>
                {/* New Add Title */}
                <Box>
                    <Typography
                        variant="h2"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{
                            m: "0 0 0px ",
                            fontFamily: "Inter",
                            fontSize: "20px",
                        }}>
                        <Decoration1></Decoration1>
                        <Box sx={{ display: "inline", ml: "12px" }}>
                            New Cost
                        </Box>
                    </Typography>
                    <Typography ml="30px" variant="h5" color={colors.grey[400]}>
                        Establishment
                    </Typography>
                </Box>
                <Box sx={{ height: "58px" }}>
                    <IconButton onClick={() => toggleEstablishmentDrawer()}>
                        <HighlightOffIcon />
                    </IconButton>
                </Box>
            </Box>

            {/* Formik - We use it to create the form inside the*/}
            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    console.log(values);
                    saveNewEstablishmentCost(values, setSubmitting);
                }}
                initialValues={initialValuesEstablishment}
                validationSchema={establishmentCostSchema}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <form style={{ height: "90vh" }} onSubmit={handleSubmit}>
                        <Box
                            height="80%"
                            display="flex"
                            flexDirection="column"
                            columnGap="20px"
                            rowGap="20px"
                            overflow="auto"
                            padding="24px 16px"
                            sx={{
                                "& 	.MuiFilledInput-root:after": {
                                    borderBottomColor: "#2595FF",
                                },
                                "& 	.Mui-focused": {
                                    borderBottomColor: "#2595FF",
                                    color: `${colors.grey[100]} !important`,
                                },
                            }}>
                            <Box fontSize={isNonMobile ? "14px" : "13px"}>
                                Establishment Costs means the inclusion of the
                                initial property purchase price, bulk and
                                internal service installation costs and the
                                initial sales and marketing costs, but exclude
                                maintenance costs, finance charges and deemed
                                costs that do not reflect the spirit of the
                                investment concessions.
                            </Box>

                            <Autocomplete
                                freeSolo
                                name="category"
                                options={establishmentCostCategories}
                                onInputChange={(e, value) => {
                                    setFieldValue("category", value);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="category"
                                        value={values.category}
                                        variant="filled"
                                        size="small"
                                        label="Cost Category"
                                        error={
                                            touched.category &&
                                            !!errors.category
                                        }
                                        helperText={
                                            touched.category && errors.category
                                        }
                                    />
                                )}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Cost Value"
                                size="small"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.cost}
                                name="cost"
                                error={!!touched.cost && !!errors.cost}
                                helperText={touched.cost && errors.cost}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Depreciation Years"
                                size="small"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.depreciationYears}
                                name="depreciationYears"
                                error={
                                    !!touched.depreciationYears &&
                                    !!errors.depreciationYears
                                }
                                helperText={
                                    touched.depreciationYears &&
                                    errors.depreciationYears
                                }
                            />

                            {/* <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Cost Category"
                                size="small"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.category}
                                name="category"
                                error={!!touched.category && !!errors.category}
                                helperText={touched.category && errors.category}
                            /> */}
                        </Box>
                        <Box
                            sx={{
                                borderTop: "1px solid",
                                borderTopColor: colors.sideBorder[100],
                                pt: "18px",
                                pr: "16px",
                            }}
                            display="flex"
                            gap="8px"
                            justifyContent="end"
                            mt={isNonMobile ? "50px" : "15px"}>
                            <Button
                                onClick={() => toggleEstablishmentDrawer()}
                                variant="outlined"
                                size={isNonMobile ? "large" : "medium"}
                                pr="80px"
                                disabled={isSubmitting}
                                sx={{
                                    color: colors.grey[100],
                                    fontWeight: "medium",
                                    borderColor: colors.sideBorder[100],
                                }}>
                                Cancel
                            </Button>
                            <Button
                                startIcon={isSubmitting ? null : <AddIcon />}
                                size={isNonMobile ? "large" : "medium"}
                                type="submit"
                                variant="contained"
                                color="buttonColor"
                                disabled={isSubmitting}
                                sx={{
                                    backgroundColor: "#2595FF",
                                    color: "#FFFFFF",
                                    // fontFamily: "Arial",
                                    // fontSize: "16px",
                                    // fontWeight: "bold",
                                }}>
                                {isSubmitting ? (
                                    <CircularProgress
                                        size={24}
                                        style={{ color: colors.grey[100] }}
                                        // color="primary"
                                    />
                                ) : (
                                    "New Entry"
                                )}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Drawer>
    );
};

export default EstablishmentCostsDrawer;
