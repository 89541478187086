import { useState, useEffect } from "react";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import Header from "../../components/Header";
import CostsTables from "../../components/CostsTables";
import { tokens } from "../../theme";
// Mock data for the tables
import {
  mockEstablishmentData,
  mockInvestmentData,
  mockWorkingData,
} from "../../data/mockDataFinancial";
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file
import "./costs.css";
import urls from "../../data/cloudFunctionUrls";
import auth from "../../firebase/firebase";
import EstablishmentCostsDrawer from "./EstablishmentCostsDrawer";
import OperatingCostsDrawer from "./OperatingCostsDrawer";
import FinancialInvestmentDrawer from "./FinancialInvestmentDrawer";

const companyFoundingYear = companyFoundingYearGetter();

const Costs = () => {
  const isNonMobile = useMediaQuery("(min-width:1000px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // States for the drawers
  const [establishmentDrawerOpen, setEstablishmentDrawerOpen] = useState(false);
  const [operatingDrawerOpen, setOperatingDrawerOpen] = useState(false);
  const [finvestmentsDrawerOpen, setFinvestmentsDrawerOpen] = useState(false);

  // Remote establishment data ---------------------
  const [establishmentData, setEstablishmentData] = useState([]);

  const [refreshEstablishmentData, setRefreshEstablishmentData] =
    useState(false);

  const [fetchingEstablishmentData, setFetchingEstablishmentData] =
    useState(true);

  const toggleRefreshEstablishmentData = () => {
    setRefreshEstablishmentData(!refreshEstablishmentData);
  };

  useEffect(() => {
    auth.currentUser.getIdToken().then((token) => {
      fetch(urls.importAPI + "/getcosts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          cost_type: "establishment_costs",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setFetchingEstablishmentData(false);
          data = data.map((item, index) => {
            return {
              id: index,
              dbid: item.dbid,
              cost: item.cost,
              depreciation: item.depreciation_years,
              category: item.type,
              participationToTotal:
                (item.cost_percentage * 100).toFixed(2) + " %",
              refresh: refreshEstablishmentData,
              toggleRefresh: toggleRefreshEstablishmentData,
            };
          });

          setEstablishmentData(data);
        })
        .catch((error) => {
          console.log(error);
          setFetchingEstablishmentData(false);
        });
    });
  }, [refreshEstablishmentData]);

  // ---------------------------------

  // Remote operating data ---------------------
  const [operatingData, setOperatingData] = useState([{}]);
  const [refreshOperatingData, setRefreshOperatingData] = useState(false);

  const [fetchingOperatingData, setFetchingOperatingData] = useState(true);

  const toggleRefreshOperatingData = () => {
    setRefreshOperatingData(!refreshOperatingData);
  };

  useEffect(() => {
    auth.currentUser.getIdToken().then((token) => {
      fetch(urls.importAPI + "/getcosts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          cost_type: "operating_costs",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          let dataDict = {};
          setFetchingOperatingData(false);
          data.map((item, index) => {
            if (!dataDict[item.type]) {
              dataDict[item.type] = {
                group: item.group,
                dbid: [item.dbid],
                costs: {
                  [item.investment_year]: item.cost,
                },
              };
            } else {
              dataDict[item.type].costs[item.investment_year] = item.cost;
              dataDict[item.type].dbid.push(item.dbid);
            }
          });

          data = Object.keys(dataDict).map((key, index) => {
            const item = dataDict[key];
            return {
              id: index,
              dbid: item.dbid,
              description: key,
              category: item.group,
              year0: item.costs[0],
              year1: item.costs[1],
              year2: item.costs[2],
              year3: item.costs[3],
              year4: item.costs[4],
              refresh: refreshOperatingData,
              toggleRefresh: toggleRefreshOperatingData,
            };
          });

          setOperatingData(data);
        });
    });
  }, [refreshOperatingData]);

  // ---------------------------------

  // Remote future investments data ---------------------
  const [futureInvestmentsData, setFutureInvestmentsData] = useState([]);
  const [refreshFutureInvestmentsData, setRefreshFutureInvestmentsData] =
    useState(false);
  const [fetchingFutureInvestmentsData, setFetchingFutureInvestmentsData] =
    useState(true);

  const toggleRefreshFutureInvestmentsData = () => {
    setRefreshFutureInvestmentsData(!refreshFutureInvestmentsData);
  };

  useEffect(() => {
    auth.currentUser.getIdToken().then((token) => {
      fetch(urls.importAPI + "/getcosts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          cost_type: "future_investments",
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setFetchingFutureInvestmentsData(false);
          data = data.map((item, index) => {
            return {
              id: index,
              dbid: item.dbid,
              amount: item.cost,
              year: companyFoundingYear + parseInt(item.investment_year),
              depreciation: item.depreciation_years,
              description: item.type,
              participationToTotal:
                (item.cost_percentage * 100).toFixed(2) + " %",
              refresh: refreshFutureInvestmentsData,
              toggleRefresh: toggleRefreshFutureInvestmentsData,
            };
          });

          setFutureInvestmentsData(data);
        });
    });
  }, [refreshFutureInvestmentsData]);

  // ---------------------------------

  // Open the establishment drawer
  const toggleEstablishmentDrawer = (e) => {
    setEstablishmentDrawerOpen(!establishmentDrawerOpen);
  };
  // Open the operating drawer
  const toggleOperatingDrawer = (e) => {
    setOperatingDrawerOpen(!operatingDrawerOpen);
  };
  // Open the future investments drawer
  const toggleFinvestmentsDrawer = (e) => {
    setFinvestmentsDrawerOpen(!finvestmentsDrawerOpen);
  };

  const saveNewEstablishmentCost = (values, setSubmitting) => {
    auth.currentUser.getIdToken().then((token) => {
      fetch(urls.importAPI + "/addcostentry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          cost_type: "establishment_costs",
          cost_entry: [
            {
              cost: values.cost,
              depreciation_years: values.depreciationYears,
              type: values.category,
            },
          ],
        }),
      }).then((response) => {
        if (response.status === 200) {
          toggleRefreshEstablishmentData();
          toggleEstablishmentDrawer();
        } else if (response.status === 400) {
          setSubmitting(false);
          alert("You have already added a cost of this category.");
        }
      });
    });
  };

  // Handle the form submit for new operating cost
  const saveNewOperatingCost = (values, setSubmitting) => {
    auth.currentUser.getIdToken().then((token) => {
      fetch(urls.importAPI + "/addcostentry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          cost_type: "operating_costs",
          cost_entry: [
            {
              cost: values.cost0,
              group: values.category,
              type: values.description,
              investment_year: 0,
            },
            {
              cost: values.cost1,
              group: values.category,
              type: values.description,
              investment_year: 1,
            },
            {
              cost: values.cost2,
              group: values.category,
              type: values.description,
              investment_year: 2,
            },
            {
              cost: values.cost3,
              group: values.category,
              type: values.description,
              investment_year: 3,
            },
            {
              cost: values.cost4,
              group: values.category,
              type: values.description,
              investment_year: 4,
            },
          ],
        }),
      }).then((response) => {
        if (response.status === 200) {
          toggleRefreshOperatingData();
          toggleOperatingDrawer();
        } else if (response.status === 400) {
          setSubmitting(false);
          alert("You have already added a cost with this description.");
        }
      });
    });
  };

  // Handle the form submit for new investment
  const saveFinvestments = (values, setSubmitting) => {
    auth.currentUser.getIdToken().then((token) => {
      fetch(urls.importAPI + "/addcostentry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          cost_type: "future_investments",
          cost_entry: [
            {
              cost: values.investment,
              depreciation_years: values.depreciationYears,
              type: values.description,
              investment_year: values.year,
            },
          ],
        }),
      }).then((response) => {
        if (response.status === 200) {
          toggleRefreshFutureInvestmentsData();
          toggleFinvestmentsDrawer();
        } else if (response.status === 400) {
          setSubmitting(false);
          alert("You have already added an investment of this category.");
        }
      });
    });
  };

  return (
    <Box m='20px'>
      <Header
        title='Costs'
        subtitle='Here you can input all of the costs payed for the operation of the company'
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box ml='15px'>
          <CostsTables
            toggleEstablishmentDrawer={toggleEstablishmentDrawer}
            toggleOperatingDrawer={toggleOperatingDrawer}
            toggleFinvestmentsDrawer={toggleFinvestmentsDrawer}
            establishmentData={establishmentData}
            investmentData={futureInvestmentsData}
            workingData={operatingData}
            loadingEstablishment={fetchingEstablishmentData}
            loadingInvestment={fetchingFutureInvestmentsData}
            loadingWorking={fetchingOperatingData}
          />
        </Box>
      </Box>
      <EstablishmentCostsDrawer
        establishmentDrawerOpen={establishmentDrawerOpen}
        toggleEstablishmentDrawer={toggleEstablishmentDrawer}
        saveNewEstablishmentCost={saveNewEstablishmentCost}
      />
      <OperatingCostsDrawer
        operatingDrawerOpen={operatingDrawerOpen}
        toggleOperatingDrawer={toggleOperatingDrawer}
        saveNewOperatingCost={saveNewOperatingCost}
      />
      <FinancialInvestmentDrawer
        finvestmentsDrawerOpen={finvestmentsDrawerOpen}
        toggleFinvestmentsDrawer={toggleFinvestmentsDrawer}
        saveFinvestments={saveFinvestments}
      />
    </Box>
  );
};

export default Costs;
