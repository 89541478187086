import * as yup from "yup";

export const sensitivitySchema_1 = yup.object().shape({
    category_0: yup
        .string("Please select a category")
        .required("Field is required"),
    firstparam_0: yup
        .string("Please select a parameter")
        .required("Field is required"),
    secondparam_0: yup.string().when("category_0", {
        is: (value) =>
            ["Product Price", "Product Sales Quantity"].includes(value),
        then: yup
            .string("Please select a parameter")
            .required("Field is required"),
        otherwise: yup.string(),
    }),
    outputcategory: yup
        .string("Please select a category")
        .required("Field is required"),
    outputparam: yup.string().when("outputcategory", {
        is: (value) =>
            ["Net Cash Flow", "Accumulative Cash Flow"].includes(value),
        then: yup.string().required("Field is required"),
        otherwise: yup.string(),
    }),
});

export const sensitivitySchema_2 = yup.object().shape({
    category_0: yup
        .string("Please select a category")
        .required("Field is required"),
    firstparam_0: yup
        .string("Please select a parameter")
        .required("Field is required"),
    secondparam_0: yup.string().when("category_0", {
        is: (value) =>
            ["Product Price", "Product Sales Quantity"].includes(value),
        then: yup
            .string("Please select a parameter")
            .required("Field is required"),
        otherwise: yup.string(),
    }),
    category_1: yup
        .string("Please select a category")
        .required("Field is required"),
    firstparam_1: yup
        .string("Please select a parameter")
        .required("Field is required"),
    secondparam_1: yup.string().when("category_1", {
        is: (value) =>
            ["Product Price", "Product Sales Quantity"].includes(value),
        then: yup
            .string("Please select a parameter")
            .required("Field is required"),
        otherwise: yup.string(),
    }),
    outputcategory: yup
        .string("Please select a category")
        .required("Field is required"),
    outputparam: yup.string().when("outputcategory", {
        is: (value) =>
            ["Net Cash Flow", "Accumulative Cash Flow"].includes(value),
        then: yup.string().required("Field is required"),
        otherwise: yup.string(),
    }),
});

export const initialSensitivityValues = {
    category_0: "",
    firstparam_0: "",
    secondparam_0: "",
    category_1: "",
    firstparam_1: "",
    secondparam_1: "",
    outputcategory: "",
    outputparam: "",
};
