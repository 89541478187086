import { useState } from "react";
import * as React from "react";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import AddIcon from "@mui/icons-material/Add";

import {
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Formik } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// form validation
import { initialValuesOperational, operationCostSchema } from "./formValues"; // import the objects from the new file
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file

import { tokens } from "../../theme";
// Mock data for the tables

import "./costs.css";

const companyFoundingYear = companyFoundingYearGetter();

const OperatingCostsDrawer = ({
  operatingDrawerOpen,
  toggleOperatingDrawer,
  saveNewOperatingCost,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1000px)");

  return (
    <Drawer
      variant='temporary'
      anchor={"right"}
      open={operatingDrawerOpen}
      onClose={() => toggleOperatingDrawer()}
      PaperProps={{
        sx: {
          width: {
            sm: 320,
            md: 320,
            lg: 360,
            xl: 500,
          },
          borderRadius: "8px",
          border: "1.5px solid",
          borderColor: colors.sideBorder[100],
          backgroundColor: colors.backgroundColor[100],
          backgroundImage: "none",
          height: "98vh",
          margin: "8px 8px 8px 8px ",
        },
      }}
    >
      {/* {list(anchor)} */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid",
          borderBottomColor: colors.sideBorder[100],
          alignItems: "center",
          height: "100px",
          padding: "16px 16px 8px 16px",
        }}
      >
        {/* New Add Title */}
        <Box>
          <Typography
            variant='h2'
            color={colors.grey[100]}
            fontWeight='bold'
            sx={{
              m: "0 0 0px ",
              fontFamily: "Inter",
              fontSize: "20px",
            }}
          >
            <Decoration1></Decoration1>
            <Box sx={{ display: "inline", ml: "12px" }}>New Cost</Box>
          </Typography>
          <Typography ml='30px' variant='h5' color={colors.grey[400]}>
            Operating Costs
          </Typography>
        </Box>
        <Box sx={{ height: "58px" }}>
          <IconButton onClick={() => toggleOperatingDrawer()}>
            <HighlightOffIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Formik - We use it to create the form inside the*/}
      <Formik
        onSubmit={(values, { setSubmitting }) =>
          saveNewOperatingCost(values, setSubmitting)
        }
        initialValues={initialValuesOperational}
        validationSchema={operationCostSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form style={{ height: "90vh" }} onSubmit={handleSubmit}>
            <Box
              height='80%'
              display='flex'
              flexDirection='column'
              columnGap='20px'
              rowGap='20px'
              overflow='auto'
              padding='24px 16px'
              sx={{
                "& 	.MuiFilledInput-root:after": {
                  borderBottomColor: "#2595FF",
                },
                "& 	.Mui-focused": {
                  borderBottomColor: "#2595FF",
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <Box fontSize={isNonMobile ? "14px" : "13px"}>
                Operating costs are associated with the maintenance and
                administration of a business on a day-to-day basis. Operating
                costs include direct costs of goods sold (COGS) and other
                operating expenses—often called selling, general, and
                administrative (SG&A)—which include rent, payroll, and other
                overhead costs, as well as raw materials and maintenance
                expenses.
              </Box>

              {/* <Box width='100%' columnGap='16px' display='flex'> */}

              {/* </Box> */}
              <TextField
                fullWidth
                select
                variant='filled'
                type='text'
                label='Category'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.category}
                name='category'
                error={!!touched.category && !!errors.category}
                helperText={touched.category && errors.category}
              >
                <MenuItem value='Personnel Costs'>Personnel</MenuItem>
                <MenuItem value='Operational Costs'>
                  Operations (e.g. Electricity, Water, Tel, Rent)
                </MenuItem>
                <MenuItem value='Transportation Costs'>
                  Transportation (e.g fuel, tickets, security, vehicle
                  maintenance)
                </MenuItem>
                <MenuItem value='Advertisment Costs'>
                  Marketing (e.g. advertisements, promotional material,
                  exhibitions)
                </MenuItem>
                <MenuItem value='Other Costs'>Other </MenuItem>
              </TextField>
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Description'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name='description'
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />

              <TextField
                fullWidth
                id='cost0'
                variant='filled'
                type='text'
                label={"Cost value for year " + `${companyFoundingYear}`}
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.cost0}
                name='cost0'
                error={!!touched.cost0 && !!errors.cost0}
                helperText={touched.cost0 && errors.cost0}
              />
              <TextField
                id='cost1'
                fullWidth
                variant='filled'
                type='text'
                label={"Cost value for year " + `${companyFoundingYear + 1}`}
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.cost1}
                name='cost1'
                error={!!touched.cost1 && !!errors.cost1}
                helperText={touched.cost1 && errors.cost1}
              />
              <TextField
                id='cost2'
                fullWidth
                variant='filled'
                type='text'
                label={"Cost value for year " + `${companyFoundingYear + 2}`}
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.cost2}
                name='cost2'
                error={!!touched.cost2 && !!errors.cost2}
                helperText={touched.cost2 && errors.cost2}
              />
              <TextField
                id='cost3'
                fullWidth
                variant='filled'
                type='text'
                label={"Cost value for year " + `${companyFoundingYear + 3}`}
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.cost3}
                name='cost3'
                error={!!touched.cost3 && !!errors.cost3}
                helperText={touched.cost3 && errors.cost3}
              />
              <TextField
                id='cost4'
                fullWidth
                variant='filled'
                type='text'
                label={"Cost value for year " + `${companyFoundingYear + 4}`}
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.cost4}
                name='cost4'
                error={!!touched.cost4 && !!errors.cost4}
                helperText={touched.cost4 && errors.cost4}
              />
            </Box>

            <Box
              sx={{
                borderTop: "1px solid",
                borderTopColor: colors.sideBorder[100],
                pt: "18px",
                pr: "16px",
                mb: "16px",
              }}
              display='flex'
              gap='8px'
              justifyContent='end'
              // mt={isNonMobile ? "50px" : "10px"}
            >
              <Button
                onClick={() => toggleOperatingDrawer()}
                variant='outlined'
                size={isNonMobile ? "large" : "medium"}
                pr='80px'
                disabled={isSubmitting}
                sx={{
                  color: colors.grey[100],
                  fontWeight: "medium",
                  borderColor: colors.sideBorder[100],
                }}
              >
                Cancel
              </Button>
              <Button
                startIcon={isSubmitting ? null : <AddIcon />}
                size={isNonMobile ? "large" : "medium"}
                type='submit'
                variant='contained'
                color='buttonColor'
                disabled={isSubmitting}
                sx={{
                  backgroundColor: "#2595FF",
                  color: "#FFFFFF",
                  // fontFamily: "Arial",
                  // fontSize: "16px",
                  // fontWeight: "bold",
                }}
              >
                {isSubmitting ? (
                  <CircularProgress
                    size={24}
                    style={{ color: colors.grey[100] }}
                    // color="primary"
                  />
                ) : (
                  "New Entry"
                )}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Drawer>
  );
};

export default OperatingCostsDrawer;
