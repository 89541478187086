import { tokens } from "../../theme";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { useState, useEffect } from "react";
import ToolbarReport from "../../components/ToolbarReport";
import { tableStylingReports } from "../../globalStyling/tablesStyling";
import { ReactComponent as EmptyState } from "../../assets/noData.svg";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LoadingSkeleton from "../../components/LoadingSkeleton";

import SensitivityDrawer from "./SensitivityDrawer";
import {
    Box,
    Typography,
    useMediaQuery,
    useTheme,
    Button,
} from "@mui/material";
import Header from "../../components/Header";
import LineChartSensitivity from "../../components/LineChartSensitivity";
import urls from "../../data/cloudFunctionUrls";
import auth from "../../firebase/firebase";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const SensitivityAnalysis = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // here is where i construct the object containing the data for the line chart
    const [data, setData] = useState([]);

    const dataToRows = (values) => {
        if (!values) return [];
        let rows = [];
        let id = 1;
        let changeOnInput = -30;
        for (let i = 0; i < 7; i++) {
            rows.push({
                id: id++,
                changeOnInput: changeOnInput.toString() + "%",
                input1:
                    values[0] && values[0][changeOnInput / 100]
                        ? values[0][changeOnInput / 100].toFixed(2)
                        : "",
                input2:
                    values[1] && values[1][changeOnInput / 100]
                        ? values[1][changeOnInput / 100].toFixed(2)
                        : "",
            });
            changeOnInput += 10;
        }

        return rows;
    };

    const tableStyles = tableStylingReports(colors);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const isMobile = useMediaQuery("(max-width:1000px)");

    const heightWindows = isMobile ? "350px" : "500px";
    //  Modal

    const [openSensitivity, setOpenSensitivity] = useState(false);

    const toggleSensitivity = () => {
        setOpenSensitivity(!openSensitivity);
    };

    // takes the form data and makes the sensitivity analysis request
    const handleSubmit = (values) => {
        // Extract the input data from the values
        const categories = [values.category_0, values.category_1];
        const firstparams = [values.firstparam_0, values.firstparam_1];
        const secondparams = [values.secondparam_0, values.secondparam_1];
        const outputcategory = values.outputcategory;
        const outputparam = values.outputparam;

        // Construct the input variables
        const inputVariables = categories
            .map((category, index) => {
                if (category && firstparams[index]) {
                    return {
                        category: category,
                        selection: firstparams[index],
                        year: secondparams[index]
                            ? parseInt(secondparams[index])
                            : 0,
                    };
                }
                return null;
            })
            .filter((item) => item);

        // Construct the output data
        const outputData = {
            inputVariables: inputVariables,
            outputVariable: {
                category: outputcategory,
                year: outputparam ? parseInt(outputparam) : 0,
            },
        };

        // Make the request
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.reportAPI + "/getSensitivityAnalysis", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(outputData),
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    } else
                        throw new Error("Error getting sensitivity analysis");
                })
                .then((data) => {
                    setData(data);
                    // toggleSensitivity();
                })
                .catch((err) => {
                    console.log("err", err);
                    alert(
                        "Something went wrong. Make sure you have filled all necessary company data."
                    );
                    // toggleSensitivity(false);
                });
        });
    };

    const [beenConfigured, setBeenConfigured] = useState(false); // this is to know if the user has already configured the sensitivity analysis

    const [inputVariables, setInputVariables] = useState([]); // this is to know if the user has already configured the sensitivity analysis
    const [outputVariable, setOutputVariable] = useState({}); // this is to know if the user has already configured the sensitivity analysis
    const setVariables = (inputVariables, outputVariable) => {
        setInputVariables(inputVariables);
        setOutputVariable(outputVariable);
    };

    const makeColumns = () => {
        const columns = [
            {
                field: "changeOnInput",
                headerName: "Change on Input",
                flex: 1,
                minWidth: 130,
            },
            {
                field: "input1",
                headerName: inputVariables[0] ? inputVariables[0] : "",
                flex: 1,
                minWidth: 130,
            },
        ];
        if (inputVariables.length > 1) {
            columns.push({
                field: "input2",
                headerName: inputVariables[1] ? inputVariables[1] : "",
                flex: 1,
                minWidth: 130,
            });
        }
        return columns;
    };

    return (
        <Box m="20px">
            <Header
                title="Sensitivity Analysis"
                subtitle="How target variables are affected by changes in other variables known as input variables"
            />
            {beenConfigured ? (
                <Box>
                    <Box
                        sx={{
                            display: "flex",

                            alignItems: "center",
                            flexWrap: "wrap",
                            // gap: "20px",
                            width: "100%",
                        }}>
                        <Typography
                            variant="h4"
                            color={colors.grey[100]}
                            fontWeight="bold"
                            sx={{ m: "8px 16px 16px 0", fontFamily: "Inter" }}>
                            {`How ${
                                inputVariables.length === 1
                                    ? `"${inputVariables[0]}"`
                                    : inputVariables
                                          .slice(0, -1)
                                          .map((v, i) =>
                                              i <
                                              inputVariables.slice(0, -1)
                                                  .length -
                                                  1
                                                  ? `"${v}", `
                                                  : `"${v}" and `
                                          )
                                          .join("") +
                                      `"${
                                          inputVariables[
                                              inputVariables.length - 1
                                          ]
                                      }"`
                            } 
    ${
        inputVariables.length > 1 ? "affect" : "affects"
    } ${`"${outputVariable}"`}`}
                        </Typography>
                        <Button
                            endIcon={<KeyboardArrowRightIcon />}
                            variant="outlined"
                            onClick={toggleSensitivity}
                            sx={{
                                backgroundColor: colors.buttonBackground[100],
                                color: colors.buttonTextColor[100],
                                zIndex: 1,
                                mb: "16px",

                                borderColor: colors.buttonColor[100],
                                "&:hover": {
                                    backgroundColor:
                                        colors.buttonBackground[100],
                                    borderColor: colors.buttonColor[100],
                                },
                            }}>
                            Configure Analysis
                        </Button>
                    </Box>

                    <Box gap={2} display="flex" flexWrap="wrap">
                        <Box
                            flex={"1 0 500px;"}
                            height={heightWindows}
                            position="relative">
                            <Box
                                sx={{
                                    height: "100%",
                                    borderRadius: "4px",
                                    backgroundColor: "transparent",
                                    border: "1px solid ",
                                    width: "100%",
                                    display: "flex",
                                    position: "absolute",

                                    borderColor: colors.sideBorder[100],
                                }}>
                                <LineChartSensitivity
                                    targetVariable={outputVariable}
                                    data={data}
                                    inputVariables={
                                        inputVariables
                                    }></LineChartSensitivity>
                            </Box>
                        </Box>

                        <Box
                            flex={"1 0 500px;"}
                            height={heightWindows}
                            sx={tableStyles}>
                            <DataGrid
                                density="compact"
                                // loading={loading}
                                experimentalFeatures={{ columnGrouping: true }}
                                // columnGroupingModel={merchandiseGroupingModel}
                                rows={dataToRows(data)}
                                components={{
                                    LoadingOverlay: LoadingSkeleton,
                                    Toolbar: () => <ToolbarReport />, //

                                    ToolbarReport,
                                }}
                                columns={makeColumns()}
                            />
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        alignContent: "center",
                        justifyContent: "flex-start",
                        height: "100%",
                        width: "100%",
                        padding: "32px 24px 24px 24px",
                    }}>
                    <EmptyState />
                    <Typography
                        variant="h4"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{ m: "16px 16px 8px 0", fontFamily: "Inter" }}>
                        Awaiting your selections to conduct sensitivity analysis
                    </Typography>
                    <Box
                        color={colors.grey[300]}
                        maxWidth={"700px"}
                        fontSize={"13px"}
                        mb={"16px"}>
                        In sensitivity analysis, different scenarios or
                        variations of input variables are tested to observe the
                        corresponding changes in the output. By systematically
                        adjusting one or more inputs while keeping others
                        constant, sensitivity analysis aims to determine how
                        sensitive the output is to those specific changes. This
                        analysis provides insights into the relative importance
                        of different variables or parameters and helps identify
                        critical factors that significantly influence the
                        results.
                    </Box>

                    <Button
                        endIcon={<KeyboardArrowRightIcon />}
                        variant="outlined"
                        onClick={toggleSensitivity}
                        sx={{
                            backgroundColor: colors.buttonBackground[100],
                            color: colors.buttonTextColor[100],
                            zIndex: 1,
                            mb: "16px",

                            borderColor: colors.buttonColor[100],
                            "&:hover": {
                                backgroundColor: colors.buttonBackground[100],
                                borderColor: colors.buttonColor[100],
                            },
                        }}>
                        Get Started
                    </Button>
                </Box>
            )}
            <SensitivityDrawer
                openSensitivity={openSensitivity}
                toggleSensitivity={toggleSensitivity}
                saveSensitivity={handleSubmit}
                setBeenConfigured={setBeenConfigured}
                setVariables={setVariables}
            />
        </Box>
    );
};

export default SensitivityAnalysis;
