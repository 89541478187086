import { tokens } from "../theme";
import { useTheme, Button } from "@mui/material";

// custom toolbar styling

export const tableStyling = (colors) => {
  const tableStyles = {
    "& .MuiDataGrid-root": {
      // border: "none",
      borderColor: `${colors.sideBorder[100]} !important`,
      //   backgroundColor: colors.primary[400],
    },
    "& .MuiDataGrid-cell": {
      // backgroundColor: colors.grey[100],
      //   borderBottom: "none",
    },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
      {
        outline: "none !important",
      },
    "& .name-column--cell": {
      // color: colors.greenAccent[300],
      fontWeight: "bold!important",
      color: "white!important",
    },
    "& .MuiDataGrid-columnHeaders": {
      //   backgroundColor: colors.blueAccent[700],
      fontWeight: "bold",
      backgroundColor: colors.primary[400],
      // borderBottom: "none",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "600!important",
    },
    "& .MuiDataGrid-virtualScroller": {
      //   backgroundColor: colors.primary[400],
    },
    "& .MuiDataGrid-footerContainer": {
      //   border: "none",

      backgroundColor: colors.primary[400],
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
      color: colors.grey[100],
    },
    "& .MuiDataGrid-toolbarContainer": {
      // border: "none",
      backgroundColor: colors.primary[400],
    },
    "& .MuiDataGrid-virtualScroller": {
      // borderRight: "0.5px solid",
      // borderRightColor: `${colors.sideBorder[100]} !important`,
      // borderLeft: "0.5px solid #e0e0e0",
      // borderLeftColor: `${colors.sideBorder[100]} !important`,
      // backgroundColor: colors.primary[400],
      // border: "none",
      // backgroundColor: colors.primary[400],
    },
  };
  return tableStyles;
};

export const tableStylingReports = (colors) => {
  const tableStyles = {
    "& .MuiDataGrid-root": {
      // border: "none",
      borderColor: `${colors.sideBorder[100]} !important`,
      //   backgroundColor: colors.primary[400],
    },
    "& .MuiDataGrid-cell": {
      // backgroundColor: colors.grey[100],
      //   borderBottom: "none",
    },
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none !important",
    },
    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
      {
        outline: "none !important",
      },
    "& .name-column--cell": {
      // color: colors.greenAccent[300],
      fontWeight: "bold",
    },
    "& .MuiDataGrid-columnHeaders": {
      //   backgroundColor: colors.blueAccent[700],
      fontWeight: "bold",
      // backgroundColor: colors.primary[400],
      // borderBottom: "none",
    },
    "& .MuiDataGrid-virtualScroller": {
      //   backgroundColor: colors.primary[400],
    },
    "& .MuiDataGrid-footerContainer": {
      //   border: "none",
      // backgroundColor: colors.primary[400],
    },
    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
      color: colors.grey[100],
    },
    "& .MuiDataGrid-toolbarContainer": {
      // border: "none",
      // backgroundColor: colors.primary[400],
    },
    "& .MuiDataGrid-virtualScroller": {
      // borderRight: "0.5px solid",
      // borderRightColor: `${colors.sideBorder[100]} !important`,
      // borderLeft: "0.5px solid #e0e0e0",
      // borderLeftColor: `${colors.sideBorder[100]} !important`,
      // backgroundColor: colors.primary[400],
      // border: "none",
      // backgroundColor: colors.primary[400],
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "600!important",
    },
  };
  return tableStyles;
};

export const tabStyling = (colors) => {
  const tabStyles = {
    borderBottom: 1.2,
    borderColor: "divider",
    width: "88%",
    "& .MuiButtonBase-root.MuiTab-root": {
      // color: "red",
      fontSize: "12px",
      fontWeight: "medium",
      mr: 4,
      fontFamily: "Roboto",
    },
    "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
      color: `${colors.grey[100]}`,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: `${colors.buttonColor[100]}`,
    },
  };
  return tabStyles;
};

export const tabStylingSettings = (colors) => {
  const tabStyles = {
    mt: "24px",
    borderBottom: 1.2,
    borderColor: "divider",
    width: "90%",
    ml: "24px",
    mr: 2,
    "& .MuiButtonBase-root.MuiTab-root": {
      // color: "red",
      fontSize: "12px",
      fontWeight: "medium",
      mr: 4,
      fontFamily: "Roboto",
    },
    "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
      color: `${colors.grey[100]}`,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: `${colors.buttonColor[100]}`,
    },
  };
  return tabStyles;
};

export const buttonGroupStyling = (colors) => {
  const buttonGroupStyles = {
    "& .MuiButtonBase-root.MuiButton-root": {
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "medium",
      borderColor: colors.sideBorder[100],

      fontFamily: "Roboto",
    },
  };
  return buttonGroupStyles;
};

export const buttonGroupStyling2 = (colors) => {
  const buttonGroupStyles = {
    "& .MuiButtonBase-root.MuiButton-root": {
      color: colors.grey[100],
      fontSize: "11.5px",
      fontWeight: "medium",
      borderColor: colors.sideBorder[100],

      fontFamily: "Roboto",
    },
  };
  return buttonGroupStyles;
};
export const activeButtonStyling = (colors) => {
  const activeButtonStyles = {
    backgroundColor: colors.buttonBackground[100],
    color: colors.buttonTextColor[100],
    zIndex: 1,

    borderColor: colors.buttonColor[100],
  };
  return activeButtonStyles;
};
