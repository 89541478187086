import Header from "../../components/Header";
import {
    Box,
    Button,
    Drawer,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import {
    mockMerchandiseData,
    mockProductsData,
    mockMaterialsData,
    productsGlobalRevData,
    merchGlobalRevData,
} from "../../data/mockDataFinancial";
import { useState, useEffect } from "react";
import MerchDrawer from "./MerchDrawer";
import MaterialsDrawer from "./MaterialsDrawer";
import ProductsDrawer from "./ProductsDrawer";
import ProductsAndMerchTables from "../../components/ProductsAndMerchTables";
import urls from "../../data/cloudFunctionUrls";
import auth from "../../firebase/firebase";
import {
    materialsColumns,
    merchandiseColumns,
    merchandiseGroupingModel,
    productsColumns,
    productsGroupingModel,
    deleteProductField,
} from "../../data/tableColumns";

import { colorPalette } from "../../data/colors";

const ProductsAndMerch = () => {
    const [merchDrawerOpen, setMerchDrawerOpen] = useState(false);
    const toggleMerchDrawer = (e) => {
        setMerchDrawerOpen(!merchDrawerOpen);
    };
    const saveNewMerch = (e) => {};
    const [materialsDrawerOpen, setMaterialsDrawerOpen] = useState(false);
    const toggleMaterialsDrawer = () => {
        setMaterialsDrawerOpen(!materialsDrawerOpen);
    };

    const [productsDrawerOpen, setProductsDrawerOpen] = useState(false);
    const toggleProductsDrawer = () => {
        setProductsDrawerOpen(!productsDrawerOpen);
    };

    // remote materials data -----------------------------

    const [materialsData, setMaterialsData] = useState([]);
    const [refreshMaterialsData, setRefreshMaterialsData] = useState(false);
    const [fetchingMaterialsData, setFetchingMaterialsData] = useState(true);

    const toggleRefreshMaterialsData = () => {
        toggleRefreshProductsData();
        setRefreshMaterialsData(!refreshMaterialsData);
    };

    useEffect(() => {
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/getmaterials", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    }
                })
                .then((data) => {
                    setFetchingMaterialsData(false);
                    data = data.map((material, index) => {
                        return {
                            id: index,
                            name: material.name,
                            meassure: material.unit,
                            costPerUnit: material.cost_per_unit,
                            dbid: material.dbid,
                            refresh: refreshMaterialsData,
                            toggleRefresh: toggleRefreshMaterialsData,
                        };
                    });
                    setMaterialsData(data);
                })
                .catch((err) => {
                    console.log(err);
                    setFetchingMaterialsData(false);
                });
        });
    }, [refreshMaterialsData]);

    // remote products data -----------------------------
    const [productsData, setProductsData] = useState([]);
    const [refreshProductsData, setRefreshProductsData] = useState(false);
    const [fetchingProductsData, setFetchingProductsData] = useState(true);
    const [barChartData, setBarChartData] = useState([]);

    const toggleRefreshProductsData = () => {
        setRefreshProductsData(!refreshProductsData);
    };

    useEffect(() => {
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/getproducts", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    }
                })
                .then((data) => {
                    setFetchingProductsData(false);
                    let barChartDataTemp = [];

                    data = data.map((product, index) => {
                        product.productionPrice =
                            product?.productionPrice || {};
                        if (product?.productionPrice?.toFixed) {
                            product.productionPrice =
                                product.productionPrice.toFixed(2);
                        } else {
                            product.productionPrice = 0;
                        }

                        barChartDataTemp.push({
                            product: product.name,
                            price: product.productionPrice,
                            priceColor: colorPalette[index],
                        });
                        let obj = {
                            id: index,
                            name: product.name,
                            type: product.type,
                            dbid: product.dbid,
                            refresh: refreshProductsData,
                            toggleRefresh: toggleRefreshProductsData,
                        };
                        if (product.materials) {
                            Object.keys(product.materials).forEach(
                                (material) => {
                                    obj[material] = product.materials[material];
                                }
                            );
                        }

                        return obj;
                    });

                    setBarChartData(barChartDataTemp);
                    setProductsData(data);
                })
                .catch((err) => {
                    console.log(err);
                    setFetchingProductsData(false);
                });
        });
    }, [refreshProductsData]);

    // remote merchandise data -----------------------------
    const [merchandiseData, setMerchandiseData] = useState([]);
    const [refreshMerchandiseData, setRefreshMerchandiseData] = useState(false);
    const [fetchingMerchandiseData, setFetchingMerchandiseData] =
        useState(true);

    const toggleRefreshMerchandiseData = () => {
        setRefreshMerchandiseData(!refreshMerchandiseData);
    };

    useEffect(() => {
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/getmerchandise", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    }
                })
                .then((data) => {
                    setFetchingMerchandiseData(false);
                    data = data.map((merch, index) => {
                        return {
                            id: index,
                            name: merch.name,
                            year0: merch.buying_costs[0],
                            year1: merch.buying_costs[1],
                            year2: merch.buying_costs[2],
                            year3: merch.buying_costs[3],
                            year4: merch.buying_costs[4],
                            dbid: merch.dbid,
                            refresh: refreshMerchandiseData,
                            toggleRefresh: toggleRefreshMerchandiseData,
                        };
                    });
                    setMerchandiseData(data);
                })
                .catch((err) => {
                    console.log(err);
                    setFetchingMerchandiseData(false);
                });
        });
    }, [refreshMerchandiseData]);

    // ----------------------------------------------

    const saveNewMaterial = (material, setSubmitting) => {
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/addmaterial", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({
                    material: {
                        name: material.name,
                        unit: material.unitmeasure,
                        cost_per_unit: material.unitcost,
                    },
                }),
            })
                .then((res) => {
                    if (res.status === 200) {
                        setSubmitting(false);
                        toggleMaterialsDrawer();
                        toggleRefreshMaterialsData();
                    } else if (res.status === 400) {
                        setSubmitting(false);
                        alert(
                            "You have already added a record of this category."
                        );
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setSubmitting(false);
                });
        });
    };

    const saveNewProduct = (product, setSubmitting) => {
        product.materials = {};

        Object.keys(product).forEach((key) => {
            if (key !== "name" && key !== "type" && key !== "materials") {
                product.materials[key] = parseFloat(product[key]);
            }
        });

        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/addproduct", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({
                    product: {
                        name: product.name,
                        type: product.type,
                        materials: product.materials,
                        sales_exports: {},
                        sales_local: {},
                    },
                }),
            })
                .then((res) => {
                    if (res.status === 200) {
                        setSubmitting(false);
                        toggleProductsDrawer();
                        toggleRefreshProductsData();
                    } else if (res.status === 400) {
                        setSubmitting(false);
                        alert(
                            "You have already added a record of this category."
                        );
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setSubmitting(false);
                });
        });
    };

    const saveNewMerchandise = (merchandise, setSubmitting) => {
        // change merchandise.buying_costs keys from costX to X
        merchandise.buying_costs = {
            0: merchandise.cost0,
            1: merchandise.cost1,
            2: merchandise.cost2,
            3: merchandise.cost3,
            4: merchandise.cost4,
        };

        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/addmerchandise", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({
                    merchandise_entry: {
                        name: merchandise.name,
                        buying_costs: merchandise.buying_costs,
                        sales_exports: {},
                        sales_local: {},
                    },
                }),
            })
                .then((res) => {
                    if (res.status === 200) {
                        setSubmitting(false);
                        toggleMerchDrawer();
                        toggleRefreshMerchandiseData();
                    } else if (res.status === 400) {
                        setSubmitting(false);
                        alert(
                            "You have already added a record of this category."
                        );
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setSubmitting(false);
                });
        });
    };

    // dynamic columns for products table
    const [productColumnsDynamic, setProductColumnsDynamic] =
        useState(productsColumns);

    const [productsGroupingModelDynamic, setProductsGroupingModelDynamic] =
        useState(productsGroupingModel);

    useEffect(() => {
        let tempArray = [...productsColumns];
        let tempGroupingModel = [...productsGroupingModel];
        let tempChildren = [];

        materialsData.forEach((material) => {
            tempArray.push({
                field: material.name,
                headerName: material.name + ` (${material.meassure})`,
                flex: 0.5,
                minWidth: 150,
            });
            tempChildren.push({
                field: material.name,
                headerName: material.name + ` (${material.meassure})`,
                flex: 0.5,
                minWidth: 150,
            });
        });

        tempGroupingModel[0].children = tempChildren;
        tempArray.push(deleteProductField);
        setProductColumnsDynamic(tempArray);
        setProductsGroupingModelDynamic(tempGroupingModel);
    }, [materialsData]);

    return (
        <Box m="20px">
            <Header
                title="Products & Services"
                subtitle="The products, services and merchandise your company sells"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box ml="15px">
                    <ProductsAndMerchTables
                        barChartData={barChartData}
                        mockMerchandiseData={merchandiseData}
                        mockMaterialsData={materialsData}
                        mockProductsData={productsData}
                        productColumnsDynamic={productColumnsDynamic}
                        productsGroupingModelDynamic={
                            productsGroupingModelDynamic
                        }
                        loadingMerchandiseData={fetchingMerchandiseData}
                        loadingMaterialsData={fetchingMaterialsData}
                        loadingProductsData={fetchingProductsData}
                        toggleMerchDrawer={toggleMerchDrawer}
                        toggleMaterialsDrawer={toggleMaterialsDrawer}
                        toggleProductsDrawer={
                            toggleProductsDrawer
                        }></ProductsAndMerchTables>
                </Box>
            </Box>
            <MerchDrawer
                merchDrawerOpen={merchDrawerOpen}
                toggleMerchDrawer={toggleMerchDrawer}
                saveNewMerch={saveNewMerchandise}
            />
            <MaterialsDrawer
                materialsDrawerOpen={materialsDrawerOpen}
                toggleMaterialsDrawer={toggleMaterialsDrawer}
                saveNewMaterials={saveNewMaterial}
            />
            <ProductsDrawer
                productsDrawerOpen={productsDrawerOpen}
                toggleProductsDrawer={toggleProductsDrawer}
                saveNewProducts={saveNewProduct}
                materials={materialsData}
            />
        </Box>
    );
};

export default ProductsAndMerch;
