import * as yup from "yup";
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file
const companyFoundingYear = companyFoundingYearGetter();

const date = new Date(companyFoundingYear, 1, -29);
export const initialLoanDetails = {
    description: "",
    loanamount: "",
    annualinterest: "",
    paymentyears: "",
    date: date.toISOString().slice(0, 10),
    paymentsinyear: "",
};

export const loanDetailsSchema = yup.object().shape({
    annualinterest: yup
        .number()
        .typeError("Must be a valid number")
        .positive("Must be a positive number")
        .required("Field is required"),
    loanamount: yup
        .number()
        .typeError("Must be a valid number")
        .positive("Must be a positive number")
        .required("Field is required"),
    paymentsinyear: yup
        .number()
        .typeError("Must be a valid number")
        .positive("Must be a positive number")
        .max(24, "Max 24 payments in a year")
        .required("Field is required"),
    date: yup.string().required("Field is required"),
    description: yup
        .string("Input must be a String")
        .required("Field is required"),
    paymentyears: yup
        .number()
        .typeError("Must be a valid number")
        .positive("Must be a positive number")
        .required("Field is required"),
});

export const initialsFundingDetails = {
    participation: 1,
    category: "",
    investmentvalue: "",
};
export const fundingDetailsSchema = yup.object().shape({
    category: yup
        .string("Input must be a String")
        .required("Field is required"),
    investmentvalue: yup
        .number()
        .typeError("Must be a valid number")
        .positive("Must be a positive number")
        .required("Field is required"),
});
