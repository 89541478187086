import { ColorModeContext, useMode } from "./theme";
import { useState, useContext, useEffect } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./scenes/global/Topbar";
import Dashboard from "./scenes/dashboard";
import Sidebar from "./scenes/global/Sidebar";
import Apology from "./scenes/apology";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Costs from "./scenes/costs";
import ProductsAndMerch from "./scenes/productsandmerch";
import Revenue from "./scenes/revenue";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import LogIn from "./scenes/login";
import Funding from "./scenes/funding";
import BreakEven from "./scenes/breakeven";
import FinancialRatios from "./scenes/financialratios";
import OperatingAccounts from "./scenes/operatingaccounts";
import CashFlows from "./scenes/cashflows";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "./firebase/Authentication";
import PrivateRoute from "./PrivateRoute";
import InvestmentAnalysis from "./scenes/investmentanalysis";
import LoginRoute from "./LoginRoute";
import BalanceSheets from "./scenes/balancesheets";
import RiskAnalysis from "./scenes/riskanalysis";
import SensitivityAnalysis from "./scenes/sensitivityanalysis";
import CompanyParameters from "./scenes/parameters";
import urls from "./data/cloudFunctionUrls";
import auth from "./firebase/firebase";
import generalData from "./data/generalData";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [remainingTime, setRemainingTime] = useState(Infinity);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (currentUser) {
      if (remainingTime === Infinity) {
        currentUser.getIdToken().then((token) => {
          fetch(urls.importAPI + "/checkLicenseRemainingTime", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          })
            .then((res) => {
              if (res.status === 200) {
                return res.json();
              }
            })
            .then((data) => {
              if (data.account_frozen == true) {
                alert(
                  "Your account has been frozen. Please contact us at " +
                    generalData.admin_email +
                    " to unfreeze your account."
                );

                auth.signOut();
                setRemainingTime(Infinity);
              } else {
                if (data.remainingTime > 0) {
                  setRemainingTime(data.remainingTime);
                } else {
                  auth.signOut();

                  alert(
                    "Your license has expired. Please contact us at " +
                      generalData.admin_email +
                      " to renew your license."
                  );

                  setRemainingTime(Infinity);
                }
              }
            })
            .catch((err) => {
              console.log("err", err);
              alert("Something went wrong.");
            });
        });
      }
    } else {
      setRemainingTime(Infinity);
    }
  }, [currentUser]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className='app'>
          {width > 600 && height > 500 && currentUser && (
            <Sidebar
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              isSidebar={isSidebar}
            />
            // <NewSidebar></NewSidebar>
          )}
          <main className='content'>
            {currentUser && width > 600 && height > 500 && (
              <Topbar
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                setIsSidebar={setIsSidebar}
              />
            )}
            {width > 600 && height > 500 && (
              <Routes>
                <Route path='/login' element={<LoginRoute />}>
                  <Route path='/login' element={<LogIn />} />
                </Route>
                <Route exact path='/' element={<PrivateRoute />}>
                  <Route exact path='/' element={<Dashboard />} />
                </Route>

                <Route exact path='/costs' element={<PrivateRoute />}>
                  <Route exact path='/costs' element={<Costs />} />
                </Route>

                <Route
                  exact
                  path='/productsandservices'
                  element={<PrivateRoute />}
                >
                  <Route
                    exact
                    path='/productsandservices'
                    element={<ProductsAndMerch />}
                  />
                </Route>

                <Route exact path='/revenue' element={<PrivateRoute />}>
                  <Route exact path='/revenue' element={<Revenue />} />
                </Route>

                <Route exact path='/faq' element={<PrivateRoute />}>
                  <Route exact path='/faq' element={<FAQ />} />
                </Route>

                <Route exact path='/funding' element={<PrivateRoute />}>
                  <Route exact path='/funding' element={<Funding />} />
                </Route>
                <Route exact path='/breakevenpoint' element={<PrivateRoute />}>
                  <Route exact path='/breakevenpoint' element={<BreakEven />} />
                </Route>
                <Route exact path='/financialratios' element={<PrivateRoute />}>
                  <Route
                    exact
                    path='/financialratios'
                    element={<FinancialRatios />}
                  />
                </Route>
                <Route
                  exact
                  path='/investmentanalysis'
                  element={<PrivateRoute />}
                >
                  <Route
                    exact
                    path='/investmentanalysis'
                    element={<InvestmentAnalysis />}
                  />
                </Route>

                <Route exact path='/cashflows' element={<PrivateRoute />}>
                  <Route exact path='/cashflows' element={<CashFlows />} />
                </Route>
                <Route exact path='/balancesheets' element={<PrivateRoute />}>
                  <Route
                    exact
                    path='/balancesheets'
                    element={<BalanceSheets />}
                  />
                </Route>
                <Route
                  exact
                  path='/operatingaccounts'
                  element={<PrivateRoute />}
                >
                  <Route
                    exact
                    path='/operatingaccounts'
                    element={<OperatingAccounts />}
                  />
                </Route>
                <Route
                  exact
                  path='/companyparameters'
                  element={<PrivateRoute />}
                >
                  <Route
                    exact
                    path='/companyparameters'
                    element={<CompanyParameters />}
                  />
                </Route>
                <Route exact path='/riskanalysis' element={<PrivateRoute />}>
                  <Route
                    exact
                    path='/riskanalysis'
                    element={<RiskAnalysis />}
                  />
                </Route>
                <Route
                  exact
                  path='/sensitivityanalysis'
                  element={<PrivateRoute />}
                >
                  <Route
                    exact
                    path='/sensitivityanalysis'
                    element={<SensitivityAnalysis />}
                  />
                </Route>
                <Route path='*' element={<Navigate to='/' replace />} />
              </Routes>
            )}
            {(width < 600 || height < 500) && <Apology />}
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
