import { useState } from "react";
import * as React from "react";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import AddIcon from "@mui/icons-material/Add";

import {
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Formik } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// form validation
import * as yup from "yup";

import { initialProductSchema, initialProducts } from "./formValues"; // import the objects from the new file
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file

import { tokens } from "../../theme";
// Mock data for the tables

const ProductsDrawer = ({
  productsDrawerOpen,
  toggleProductsDrawer,
  saveNewProducts,
  materials,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1000px)");

  let tempMaterial = null;

  let initialSchema = { ...initialProductSchema };
  let initialProductsList = { ...initialProducts };

  materials.forEach((material) => {
    initialSchema[material.name] = yup
      .number()
      .typeError("Must be a valid number")
      .min(0, "Must be a non-negative number");
  });

  const productsSchema = yup.object().shape(initialSchema);

  return (
    <Drawer
      variant='temporary'
      anchor={"right"}
      open={productsDrawerOpen}
      onClose={toggleProductsDrawer}
      PaperProps={{
        sx: {
          width: {
            sm: 320,
            md: 320,
            lg: 360,
            xl: 500,
          },
          borderRadius: "8px",
          border: "1.5px solid",
          borderColor: colors.sideBorder[100],
          backgroundColor: colors.backgroundColor[100],
          backgroundImage: "none",
          height: "98vh",
          margin: "8px 8px 8px 8px ",
        },
      }}
    >
      {/* {list(anchor)} */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid",
          borderBottomColor: colors.sideBorder[100],
          alignItems: "center",
          height: "100px",
          padding: "16px 16px 8px 16px",
        }}
      >
        {/* New Add Title */}
        <Box>
          <Typography
            variant='h2'
            color={colors.grey[100]}
            fontWeight='bold'
            sx={{
              m: "0 0 0px ",
              fontFamily: "Inter",
              fontSize: "20px",
            }}
          >
            <Decoration1></Decoration1>
            <Box sx={{ display: "inline", ml: "12px" }}>New Product</Box>
          </Typography>
          <Typography ml='30px' variant='h5' color={colors.grey[400]}>
            Products and Merchandise
          </Typography>
        </Box>
        <Box sx={{ height: "58px" }}>
          <IconButton onClick={toggleProductsDrawer}>
            <HighlightOffIcon />
          </IconButton>
        </Box>
      </Box>
      <Formik
        onSubmit={(values, { setSubmitting }) =>
          saveNewProducts(values, setSubmitting)
        }
        initialValues={initialProductsList}
        validationSchema={productsSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form style={{ height: "90vh" }} onSubmit={handleSubmit}>
            <Box
              height='80%'
              display='flex'
              flexDirection='column'
              columnGap='20px'
              rowGap='20px'
              overflow='auto'
              padding='24px 16px'
              sx={{
                "& 	.MuiFilledInput-root:after": {
                  borderBottomColor: "#2595FF",
                },
                "& 	.Mui-focused": {
                  borderBottomColor: "#2595FF",
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <Box fontSize={isNonMobile ? "14px" : "13px"}>
                State the products/services that require a production process
                (i.e. the combination of materials or other economic inputs).
              </Box>
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Product Name'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name='name'
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              ></TextField>
              <TextField
                fullWidth
                select
                variant='filled'
                type='text'
                label='Type'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.type}
                name='type'
                error={!!touched.type && !!errors.type}
                helperText={touched.type && errors.type}
              >
                <MenuItem value='Service'>Service</MenuItem>
                <MenuItem value='Product'>Product</MenuItem>
              </TextField>
              {/*  An zugos arithmos materials vazeis auta ta box apo katw, alliws vazeis kai ena sketo material sto telos   */}
              {materials.map((material, index) => {
                if (index % 2 === 0) {
                  tempMaterial = material;
                } else {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "16px",
                      }}
                    >
                      <TextField
                        fullWidth
                        variant='filled'
                        type='number'
                        label={
                          tempMaterial.name + ` (${tempMaterial.meassure})`
                        }
                        size='small'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values[tempMaterial.name]}
                        name={tempMaterial.name}
                        error={
                          !!touched[tempMaterial.name] &&
                          !!errors[tempMaterial.name]
                        }
                        helperText={
                          touched[tempMaterial.name] &&
                          errors[tempMaterial.name]
                        }
                      />
                      <TextField
                        fullWidth
                        variant='filled'
                        type='number'
                        label={material.name + ` (${material.meassure})`}
                        size='small'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values[material.name]}
                        name={material.name}
                        error={
                          !!touched[material.name] && !!errors[material.name]
                        }
                        helperText={
                          touched[material.name] && errors[material.name]
                        }
                      />
                    </Box>
                  );
                }
              })}
              {materials.length % 2 !== 0 && tempMaterial ? (
                <Box
                  sx={{
                    display: "flex",
                    gap: "16px",
                  }}
                >
                  <TextField
                    fullWidth
                    variant='filled'
                    type='number'
                    label={tempMaterial.name + ` (${tempMaterial.meassure})`}
                    size='small'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values[tempMaterial.name]}
                    name={tempMaterial.name}
                    error={
                      !!touched[tempMaterial.name] &&
                      !!errors[tempMaterial.name]
                    }
                    helperText={
                      touched[tempMaterial.name] && errors[tempMaterial.name]
                    }
                  />
                  {/* this should be invisible */}
                  <TextField
                    fullWidth
                    disabled
                    variant='filled'
                    type='number'
                    label={tempMaterial.name + ` (${tempMaterial.meassure})`}
                    size='small'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values[tempMaterial.name]}
                    name={tempMaterial.name}
                    error={
                      !!touched[tempMaterial.name] &&
                      !!errors[tempMaterial.name]
                    }
                    helperText={
                      touched[tempMaterial.name] && errors[tempMaterial.name]
                    }
                    style={{ opacity: 0 }}
                  />
                </Box>
              ) : null}
            </Box>

            <Box
              sx={{
                borderTop: "1px solid",
                borderTopColor: colors.sideBorder[100],
                pt: "18px",
                pr: "16px",
                mb: "16px",
              }}
              display='flex'
              gap='8px'
              justifyContent='end'
              // mt={isNonMobile ? "50px" : "10px"}
            >
              <Button
                onClick={() => toggleProductsDrawer()}
                variant='outlined'
                size={isNonMobile ? "large" : "medium"}
                pr='80px'
                disabled={isSubmitting}
                sx={{
                  color: colors.grey[100],
                  fontWeight: "medium",
                  borderColor: colors.sideBorder[100],
                }}
              >
                Cancel
              </Button>
              <Button
                startIcon={isSubmitting ? null : <AddIcon />}
                size={isNonMobile ? "large" : "medium"}
                type='submit'
                variant='contained'
                color='buttonColor'
                disabled={isSubmitting}
                sx={{
                  backgroundColor: "#2595FF",
                  color: "#FFFFFF",
                  // fontFamily: "Arial",
                  // fontSize: "16px",
                  // fontWeight: "bold",
                }}
              >
                {isSubmitting ? (
                  <CircularProgress
                    size={24}
                    style={{ color: colors.grey[100] }}
                    // color="primary"
                  />
                ) : (
                  "New Entry"
                )}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Drawer>
  );
};

export default ProductsDrawer;
