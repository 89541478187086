import { useState } from "react";
import * as React from "react";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import AddIcon from "@mui/icons-material/Add";

import {
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Formik } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// form validation
import * as yup from "yup";

import { workingCapitalSchema, initialWorkingCapital } from "./formValues"; // import the objects from the new file
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file

import { tokens } from "../../theme";
// Mock data for the tables

const paramsToForm = (params) => {
  if (Object.keys(params).length === 0) return initialWorkingCapital;
  else
    return {
      inventoryDays: params?.["Raw material inventory days"] || "",
      salesDays: params?.["Merchandise inventory days"] || "",
      finishedGoodsDays: params?.["Finished goods inventory days"] || "",
      expensesDays: params?.["Days of coverage for operating expenses"] || "",
      accountsReceivableDays: params?.["Customer credit days"] || "",
      accountsPayableDays: params?.["Supplier credit days"] || "",
    };
};

const WorkingCapitalDrawer = ({
  workingCapitalDrawerOpen,
  toggleWorkingCapitalDrawer,
  saveNewWCParameters,
  params,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1000px)");

  return (
    <Drawer
      variant='temporary'
      anchor={"right"}
      open={workingCapitalDrawerOpen}
      onClose={toggleWorkingCapitalDrawer}
      PaperProps={{
        sx: {
          width: {
            sm: 320,
            md: 320,
            lg: 360,
            xl: 500,
          },
          borderRadius: "8px",
          border: "1.5px solid",
          borderColor: colors.sideBorder[100],
          backgroundColor: colors.backgroundColor[100],
          backgroundImage: "none",
          height: "98vh",
          margin: "8px 8px 8px 8px ",
        },
      }}
    >
      {/* {list(anchor)} */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid",
          borderBottomColor: colors.sideBorder[100],
          alignItems: "center",
          height: "100px",
          padding: "16px 16px 8px 16px",
        }}
      >
        {/* New Add Title */}
        <Box>
          <Typography
            variant='h2'
            color={colors.grey[100]}
            fontWeight='bold'
            sx={{
              m: "0 0 0px ",
              fontFamily: "Inter",
              fontSize: "20px",
            }}
          >
            <Decoration1></Decoration1>
            <Box sx={{ display: "inline", ml: "12px" }}>Edit Parameters</Box>
          </Typography>
          <Typography ml='30px' variant='h5' color={colors.grey[400]}>
            Working Capital
          </Typography>
        </Box>
        <Box sx={{ height: "58px" }}>
          <IconButton onClick={toggleWorkingCapitalDrawer}>
            <HighlightOffIcon />
          </IconButton>
        </Box>
      </Box>
      <Formik
        onSubmit={(values, { setSubmitting }) =>
          saveNewWCParameters(values, setSubmitting)
        }
        initialValues={paramsToForm(params)}
        validationSchema={workingCapitalSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form style={{ height: "90vh" }} onSubmit={handleSubmit}>
            <Box
              height='80%'
              display='flex'
              flexDirection='column'
              columnGap='20px'
              rowGap='20px'
              overflow='auto'
              padding='24px 16px'
              sx={{
                "& 	.MuiFilledInput-root:after": {
                  borderBottomColor: "#2595FF",
                },
                "& 	.Mui-focused": {
                  borderBottomColor: "#2595FF",
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              {/* <Box width='100%' columnGap='16px' display='flex'> */}
              {/* </Box> */}
              <TextField
                fullWidth
                variant='filled'
                type='number'
                label='Inventory Days(raw materials)'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.inventoryDays}
                name='inventoryDays'
                error={!!touched.inventoryDays && !!errors.inventoryDays}
                helperText={
                  touched.inventoryDays && errors.inventoryDays
                    ? errors.inventoryDays
                    : "On average, how many days do raw materials stay in the warehouse before going into production?"
                }
              ></TextField>

              <TextField
                fullWidth
                variant='filled'
                type='number'
                label='Inventory days (merchandise)'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.salesDays}
                name='salesDays'
                error={!!touched.salesDays && !!errors.salesDays}
                helperText={
                  touched.salesDays && errors.salesDays
                    ? errors.salesDays
                    : "On average, how many days do merchandises stay in the warehouse before delivered to customers (or intermediate partners)?"
                }
              ></TextField>

              <TextField
                fullWidth
                variant='filled'
                type='number'
                label='Inventory days (products/services)'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.finishedGoodsDays}
                name='finishedGoodsDays'
                error={
                  !!touched.finishedGoodsDays && !!errors.finishedGoodsDays
                }
                helperText={
                  touched.finishedGoodsDays && errors.finishedGoodsDays
                    ? errors.finishedGoodsDays
                    : "On average, how many days do products stay in the warehouse before delivered to customers (or intermediate partners)?"
                }
              ></TextField>

              <TextField
                fullWidth
                variant='filled'
                type='number'
                label='Current liability coverage days'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.expensesDays}
                name='expensesDays'
                error={!!touched.expensesDays && !!errors.expensesDays}
                helperText={
                  touched.expensesDays && errors.expensesDays
                    ? errors.expensesDays
                    : "Presuming revenue flows are halted, how many days, on average, can the company cover liabilities?"
                }
              ></TextField>

              <TextField
                fullWidth
                variant='filled'
                type='number'
                label='Collection period'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.accountsReceivableDays}
                name='accountsReceivableDays'
                error={
                  !!touched.accountsReceivableDays &&
                  !!errors.accountsReceivableDays
                }
                helperText={
                  touched.accountsReceivableDays &&
                  errors.accountsReceivableDays
                    ? errors.accountsReceivableDays
                    : "On average, how many days does the company allow its customers to pay off?"
                }
              ></TextField>

              <TextField
                fullWidth
                variant='filled'
                type='number'
                label='Payable period'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.accountsPayableDays}
                name='accountsPayableDays'
                error={
                  !!touched.accountsPayableDays && !!errors.accountsPayableDays
                }
                helperText={
                  touched.accountsPayableDays && errors.accountsPayableDays
                    ? errors.accountsPayableDays
                    : "On average, how many days do suppliers allow the company to pay off?"
                }
              ></TextField>
              {/*  An zugos arithmos materials vazeis auta ta box apo katw, alliws vazeis kai ena sketo material sto telos   */}
            </Box>

            <Box
              sx={{
                borderTop: "1px solid",
                borderTopColor: colors.sideBorder[100],
                pt: "18px",
                pr: "16px",
                mb: "16px",
              }}
              display='flex'
              gap='8px'
              justifyContent='end'
              // mt={isNonMobile ? "50px" : "10px"}
            >
              <Button
                onClick={() => toggleWorkingCapitalDrawer()}
                variant='outlined'
                size={isNonMobile ? "large" : "medium"}
                pr='80px'
                disabled={isSubmitting}
                sx={{
                  color: colors.grey[100],
                  fontWeight: "medium",
                  borderColor: colors.sideBorder[100],
                }}
              >
                Cancel
              </Button>
              <Button
                size={isNonMobile ? "large" : "medium"}
                type='submit'
                variant='contained'
                color='buttonColor'
                disabled={isSubmitting}
                sx={{
                  backgroundColor: "#2595FF",
                  color: "#FFFFFF",
                  // fontFamily: "Arial",
                  // fontSize: "16px",
                  // fontWeight: "bold",
                }}
              >
                {isSubmitting ? (
                  <CircularProgress
                    size={24}
                    style={{ color: colors.grey[100] }}
                    // color="primary"
                  />
                ) : (
                  "Submit"
                )}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Drawer>
  );
};

export default WorkingCapitalDrawer;
