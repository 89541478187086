import {  Skeleton } from "@mui/material";
import Box from "@mui/material/Box";
const LoadingSkeleton = () => (
    <Box
      sx={{
        height: "max-content",
      }}
    >
      {[...Array(10)].map((_) => (
        <Skeleton
          animation='wave'
          height={32}
          // width='100%'
          borderRadius={100}
          variant='rectangular'
          sx={{ my: 4, mx: 1 }}
        />
      ))}
    </Box>
  );

export default LoadingSkeleton;