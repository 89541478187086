import * as yup from "yup";

export const riskSchema_1 = yup.object().shape({
    category_0: yup
        .string("Please select a category")
        .required("Field is required"),
    firstparam_0: yup
        .string("Please select a parameter")
        .required("Field is required"),
    secondparam_0: yup.string().when("category_0", {
        is: (value) =>
            ["Product Price", "Product Sales Quantity"].includes(value),
        then: yup
            .string("Please select a parameter")
            .required("Field is required"),
        otherwise: yup.string(),
    }),
    distribution_0: yup
        .string("Please select a distribution")
        .required("Field is required"),
    minimumValue_0: yup.number("Please enter a number").when("distribution_0", {
        is: (value) => ["Uniform", "Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    middleValue_0: yup.number("Please enter a number").when("distribution_0", {
        is: (value) => ["Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    maximumValue_0: yup.number("Please enter a number").when("distribution_0", {
        is: (value) => ["Uniform", "Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),

    meanValue_0: yup.number("Please enter a number").when("distribution_0", {
        is: (value) => ["Normal"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    standardDeviation_0: yup
        .number("Please enter a number")
        .when("distribution_0", {
            is: (value) => ["Normal"].includes(value),
            then: yup
                .number("Please enter a number")
                .required("Field is required"),
            otherwise: yup.number(),
        }),
    outputcategory: yup
        .string("Please select a category")
        .required("Field is required"),
    outputparam: yup.string().when("outputcategory", {
        is: (value) =>
            ["Net Cash Flow", "Accumulative Cash Flow"].includes(value),
        then: yup.string().required("Field is required"),
        otherwise: yup.string(),
    }),
});

export const riskSchema_2 = yup.object().shape({
    category_0: yup
        .string("Please select a category")
        .required("Field is required"),
    firstparam_0: yup
        .string("Please select a parameter")
        .required("Field is required"),
    secondparam_0: yup.string().when("category_0", {
        is: (value) =>
            ["Product Price", "Product Sales Quantity"].includes(value),
        then: yup
            .string("Please select a parameter")
            .required("Field is required"),
        otherwise: yup.string(),
    }),
    distribution_0: yup
        .string("Please select a distribution")
        .required("Field is required"),
    minimumValue_0: yup.number("Please enter a number").when("distribution_0", {
        is: (value) => ["Uniform", "Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    middleValue_0: yup.number("Please enter a number").when("distribution_0", {
        is: (value) => ["Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    maximumValue_0: yup.number("Please enter a number").when("distribution_0", {
        is: (value) => ["Uniform", "Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    meanValue_0: yup.number("Please enter a number").when("distribution_0", {
        is: (value) => ["Normal"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    standardDeviation_0: yup
        .number("Please enter a number")
        .when("distribution_0", {
            is: (value) => ["Normal"].includes(value),
            then: yup
                .number("Please enter a number")
                .required("Field is required"),
            otherwise: yup.number(),
        }),
    // ---
    category_1: yup
        .string("Please select a category")
        .required("Field is required"),
    firstparam_1: yup
        .string("Please select a parameter")
        .required("Field is required"),
    secondparam_1: yup.string().when("category_1", {
        is: (value) =>
            ["Product Price", "Product Sales Quantity"].includes(value),
        then: yup
            .string("Please select a parameter")
            .required("Field is required"),
        otherwise: yup.string(),
    }),
    distribution_1: yup
        .string("Please select a distribution")
        .required("Field is required"),
    minimumValue_1: yup.number("Please enter a number").when("distribution_1", {
        is: (value) => ["Uniform", "Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    middleValue_1: yup.number("Please enter a number").when("distribution_1", {
        is: (value) => ["Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    maximumValue_1: yup.number("Please enter a number").when("distribution_1", {
        is: (value) => ["Uniform", "Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    meanValue_1: yup.number("Please enter a number").when("distribution_1", {
        is: (value) => ["Normal"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    standardDeviation_1: yup
        .number("Please enter a number")
        .when("distribution_1", {
            is: (value) => ["Normal"].includes(value),
            then: yup
                .number("Please enter a number")
                .required("Field is required"),
            otherwise: yup.number(),
        }),
    outputcategory: yup
        .string("Please select a category")
        .required("Field is required"),
    outputparam: yup.string().when("outputcategory", {
        is: (value) =>
            ["Net Cash Flow", "Accumulative Cash Flow"].includes(value),
        then: yup.string().required("Field is required"),
        otherwise: yup.string(),
    }),
});

export const riskSchema_3 = yup.object().shape({
    category_0: yup
        .string("Please select a category")
        .required("Field is required"),
    firstparam_0: yup
        .string("Please select a parameter")
        .required("Field is required"),
    secondparam_0: yup.string().when("category_0", {
        is: (value) =>
            ["Product Price", "Product Sales Quantity"].includes(value),
        then: yup
            .string("Please select a parameter")
            .required("Field is required"),
        otherwise: yup.string(),
    }),
    distribution_0: yup
        .string("Please select a distribution")
        .required("Field is required"),
    minimumValue_0: yup.number("Please enter a number").when("distribution_0", {
        is: (value) => ["Uniform", "Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    middleValue_0: yup.number("Please enter a number").when("distribution_0", {
        is: (value) => ["Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    maximumValue_0: yup.number("Please enter a number").when("distribution_0", {
        is: (value) => ["Uniform", "Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    meanValue_0: yup.number("Please enter a number").when("distribution_0", {
        is: (value) => ["Normal"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    standardDeviation_0: yup
        .number("Please enter a number")
        .when("distribution_0", {
            is: (value) => ["Normal"].includes(value),
            then: yup
                .number("Please enter a number")
                .required("Field is required"),
            otherwise: yup.number(),
        }),
    // ---
    category_1: yup
        .string("Please select a category")
        .required("Field is required"),
    firstparam_1: yup
        .string("Please select a parameter")
        .required("Field is required"),
    secondparam_1: yup.string().when("category_1", {
        is: (value) =>
            ["Product Price", "Product Sales Quantity"].includes(value),
        then: yup
            .string("Please select a parameter")
            .required("Field is required"),
        otherwise: yup.string(),
    }),
    distribution_1: yup
        .string("Please select a distribution")
        .required("Field is required"),
    minimumValue_1: yup.number("Please enter a number").when("distribution_1", {
        is: (value) => ["Uniform", "Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    middleValue_1: yup.number("Please enter a number").when("distribution_1", {
        is: (value) => ["Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    maximumValue_1: yup.number("Please enter a number").when("distribution_1", {
        is: (value) => ["Uniform", "Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    meanValue_1: yup.number("Please enter a number").when("distribution_1", {
        is: (value) => ["Normal"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    standardDeviation_1: yup
        .number("Please enter a number")
        .when("distribution_1", {
            is: (value) => ["Normal"].includes(value),
            then: yup
                .number("Please enter a number")
                .required("Field is required"),
            otherwise: yup.number(),
        }),
    // ---
    category_2: yup
        .string("Please select a category")
        .required("Field is required"),
    firstparam_2: yup
        .string("Please select a parameter")
        .required("Field is required"),
    secondparam_2: yup.string().when("category_2", {
        is: (value) =>
            ["Product Price", "Product Sales Quantity"].includes(value),
        then: yup
            .string("Please select a parameter")
            .required("Field is required"),
        otherwise: yup.string(),
    }),
    distribution_2: yup
        .string("Please select a distribution")
        .required("Field is required"),
    minimumValue_2: yup.number("Please enter a number").when("distribution_2", {
        is: (value) => ["Uniform", "Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    middleValue_2: yup.number("Please enter a number").when("distribution_2", {
        is: (value) => ["Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    maximumValue_2: yup.number("Please enter a number").when("distribution_2", {
        is: (value) => ["Uniform", "Triangular"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    meanValue_2: yup.number("Please enter a number").when("distribution_2", {
        is: (value) => ["Normal"].includes(value),
        then: yup.number("Please enter a number").required("Field is required"),
        otherwise: yup.number(),
    }),
    standardDeviation_2: yup
        .number("Please enter a number")
        .when("distribution_2", {
            is: (value) => ["Normal"].includes(value),
            then: yup
                .number("Please enter a number")
                .required("Field is required"),
            otherwise: yup.number(),
        }),
    outputcategory: yup
        .string("Please select a category")
        .required("Field is required"),
    outputparam: yup.string().when("outputcategory", {
        is: (value) =>
            ["Net Cash Flow", "Accumulative Cash Flow"].includes(value),
        then: yup.string().required("Field is required"),
        otherwise: yup.string(),
    }),
});

export const initialRiskValues = {
    category_0: "",
    firstparam_0: "",
    secondparam_0: "",
    distribution_0: "",
    minimumValue_0: "",
    maximumValue_0: "",
    middleValue_0: "",
    meanValue_0: "",
    standardDeviation_0: "",
    category_1: "",
    firstparam_1: "",
    secondparam_1: "",
    distribution_1: "",
    minimumValue_1: "",
    maximumValue_1: "",
    middleValue_1: "",
    meanValue_1: "",
    standardDeviation_1: "",
    category_2: "",
    firstparam_2: "",
    secondparam_2: "",
    distribution_2: "",
    minimumValue_2: "",
    maximumValue_2: "",
    middleValue_2: "",
    meanValue_2: "",
    standardDeviation_2: "",
    outputcategory: "",
    outputparam: "",
};
