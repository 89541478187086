import Header from "../../components/Header";
import {
    Box,
    Button,
    Drawer,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import {
    otherRevData,
    productsRevData,
    merchRevData,
    productsGlobalRevData,
    merchGlobalRevData,
} from "../../data/mockDataFinancial";
import MerchRevDrawer from "./MerchRevDrawer";
import OtherRevDrawer from "./OtherRevDrawer";
import ProductRevDrawer from "./ProductRevDrawer";
import MerchGlobalRevDrawer from "./MerchGlobalRevDrawer";
import ProductGlobalRevDrawer from "./ProductGlobalRevDrawer";
import RevenueTables from "../../components/RevenueTables";

import urls from "../../data/cloudFunctionUrls";
import auth from "../../firebase/firebase";

const saveSales = async (
    saleType,
    area,
    product_dbid,
    sales,
    toggleDrawer,
    toggleRefresh,
    setSubmitting
) => {
    await auth.currentUser.getIdToken(true).then(async (token) => {
        await fetch(urls.importAPI + "/updatesales", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                sale_type: saleType,
                area: area,
                dbid: product_dbid,
                sales: sales,
            }),
        })
            .then((res) => {
                if (res.status === 200) {
                    setSubmitting(false);
                    toggleDrawer();
                    toggleRefresh();
                } else if (res.status === 400) {
                    setSubmitting(false);
                    alert("You have already added a record of this category.");
                }
            })
            .catch((err) => {
                console.log(err);
                setSubmitting(false);
            });
    });
};

const getLocalAndExports = (data, sale_type, refresh, toggleRefresh) => {
    const local = [];
    const exports = [];
    data.forEach((product, index) => {
        // check if product.sales_local is not empty
        if (Object.keys(product.sales_local).length > 0) {
            local.push({
                id: index,
                description: product.name,
                dbid: product.dbid,
                sale_type: sale_type,
                area: "sales_local",
                price0: product?.sales_local["0"]
                    ? product?.sales_local["0"]?.price
                    : 0,
                quantity0: product?.sales_local["0"]
                    ? product?.sales_local["0"]?.quantity
                    : 0,
                price1: product?.sales_local["1"]
                    ? product?.sales_local["1"]?.price
                    : 0,
                quantity1: product?.sales_local["1"]
                    ? product?.sales_local["1"]?.quantity
                    : 0,
                price2: product?.sales_local["2"]
                    ? product?.sales_local["2"]?.price
                    : 0,
                quantity2: product?.sales_local["2"]
                    ? product?.sales_local["2"]?.quantity
                    : 0,
                price3: product?.sales_local["3"]
                    ? product?.sales_local["3"]?.price
                    : 0,
                quantity3: product?.sales_local["3"]
                    ? product?.sales_local["3"]?.quantity
                    : 0,
                price4: product?.sales_local["4"]
                    ? product?.sales_local["4"]?.price
                    : 0,
                quantity4: product?.sales_local["4"]
                    ? product?.sales_local["4"]?.quantity
                    : 0,
                refresh: refresh,
                toggleRefresh: toggleRefresh,
            });
        }
        if (Object.keys(product.sales_exports).length > 0) {
            exports.push({
                id: index,
                description: product.name,
                dbid: product.dbid,
                sale_type: sale_type,
                area: "sales_exports",
                price0: product?.sales_exports["0"]
                    ? product?.sales_exports["0"]?.price
                    : 0,
                quantity0: product?.sales_exports["0"]
                    ? product?.sales_exports["0"]?.quantity
                    : 0,
                price1: product?.sales_exports["1"]
                    ? product?.sales_exports["1"]?.price
                    : 0,
                quantity1: product?.sales_exports["1"]
                    ? product?.sales_exports["1"]?.quantity
                    : 0,
                price2: product?.sales_exports["2"]
                    ? product?.sales_exports["2"]?.price
                    : 0,
                quantity2: product?.sales_exports["2"]
                    ? product?.sales_exports["2"]?.quantity
                    : 0,
                price3: product?.sales_exports["3"]
                    ? product?.sales_exports["3"]?.price
                    : 0,
                quantity3: product?.sales_exports["3"]
                    ? product?.sales_exports["3"]?.quantity
                    : 0,
                price4: product?.sales_exports["4"]
                    ? product?.sales_exports["4"]?.price
                    : 0,
                quantity4: product?.sales_exports["4"]
                    ? product?.sales_exports["4"]?.quantity
                    : 0,
                refresh: refresh,
                toggleRefresh: toggleRefresh,
            });
        }
    });
    return { local, exports };
};

const Revenue = () => {
    const [otherDrawerOpen, setOtherDrawerOpen] = useState(false);
    const toggleOtherDrawer = (e) => {
        setOtherDrawerOpen(!otherDrawerOpen);
    };

    const [merchRevDrawerOpen, setMerchRevDrawerOpen] = useState(false);
    const toggleMerchRevDrawer = () => {
        setMerchRevDrawerOpen(!merchRevDrawerOpen);
    };
    const saveMerchRevenues = () => {
        // your logic for saving merch revenues here
    };
    const [productRevDrawerOpen, setProductRevDrawerOpen] = useState(false);
    const toggleProductRevDrawer = () => {
        setProductRevDrawerOpen(!productRevDrawerOpen);
    };
    const saveProductRevenues = () => {
        // your logic for saving product revenues here
    };
    const [prodGlobalRevDrawer, setProdGlobalRevDrawer] = useState(false);
    const toggleProdGlobalRevDrawer = () => {
        setProdGlobalRevDrawer(!prodGlobalRevDrawer);
    };
    const saveProdGlobalRevenues = () => {
        // your logic for saving product global revenues here
    };
    const [merchGlobalRevDrawer, setMerchGlobalRevDrawer] = useState(false);
    const toggleMerchGlobalRevDrawer = () => {
        setMerchGlobalRevDrawer(!merchGlobalRevDrawer);
    };
    const saveMerchGlobalRevenues = () => {
        // your logic for saving merch global revenues here
    };

    // remote product data
    const [products, setProducts] = useState([]); // remote product data
    const [productSalesLocal, setProductSalesLocal] = useState([]); // local product sales data
    const [productSalesExport, setProductSalesExport] = useState([]); // export product sales data
    const [refreshProducts, setRefreshProducts] = useState(false);
    const [fetchingProducts, setFetchingProducts] = useState(true);

    const toggleRefreshProducts = () => {
        setRefreshProducts(!refreshProducts);
    };

    useEffect(() => {
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/getproducts", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    }
                })
                .then((data) => {
                    const { local, exports } = getLocalAndExports(
                        data,
                        "products",
                        refreshProducts,
                        toggleRefreshProducts
                    );
                    data = data.map((item) => {
                        return { name: item.name, dbid: item.dbid };
                    });

                    setProducts(data);
                    setProductSalesExport(exports);
                    setProductSalesLocal(local);
                    setFetchingProducts(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    }, [refreshProducts]);

    // remote merch data
    const [merchandise, setMerchandise] = useState([]); // remote merch data
    const [merchRevData, setMerchRevData] = useState([]);
    const [merchGlobalRevData, setMerchGlobalRevData] = useState([]);
    const [refreshMerch, setRefreshMerch] = useState(false);
    const [fetchingMerch, setFetchingMerch] = useState(true);

    const toggleRefreshMerch = () => {
        setRefreshMerch(!refreshMerch);
    };

    useEffect(() => {
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/getmerchandise", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    }
                })
                .then((data) => {
                    const { local, exports } = getLocalAndExports(
                        data,
                        "merchandise",
                        refreshMerch,
                        toggleRefreshMerch
                    );
                    data = data.map((item) => {
                        return { name: item.name, dbid: item.dbid };
                    });
                    setMerchandise(data);

                    setMerchRevData(local);
                    setMerchGlobalRevData(exports);
                    setFetchingMerch(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    }, [refreshMerch]);

    // remote other data
    const [otherRevData, setOtherRevData] = useState([]);
    const [refreshOther, setRefreshOther] = useState(false);
    const [fetchingOther, setFetchingOther] = useState(true);

    const toggleRefreshOther = () => {
        setRefreshOther(!refreshOther);
    };

    useEffect(() => {
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/getincomesources", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    }
                })
                .then((data) => {
                    data = data.map((item, index) => {
                        return {
                            id: index,
                            dbid: item.dbid,
                            description: item.name,
                            year0: item?.amounts["0"] || 0,
                            year1: item?.amounts["1"] || 0,
                            year2: item?.amounts["2"] || 0,
                            year3: item?.amounts["3"] || 0,
                            year4: item?.amounts["4"] || 0,
                            refresh: refreshOther,
                            toggleRefresh: toggleRefreshOther,
                        };
                    });

                    setOtherRevData(data);
                    setFetchingOther(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    }, [refreshOther]);

    // save functions
    const saveOtherRevenues = (item, setSubmitting) => {
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/addincomesource", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({
                    income_source: {
                        name: item.description,
                        amounts: {
                            0: item.revenue0,
                            1: item.revenue1,
                            2: item.revenue2,
                            3: item.revenue3,
                            4: item.revenue4,
                        },
                    },
                }),
            })
                .then((res) => {
                    if (res.status === 200) {
                        setSubmitting(false);
                        toggleOtherDrawer();
                        toggleRefreshOther();
                    } else if (res.status === 400) {
                        setSubmitting(false);
                        alert(
                            "You have already added a record of this category."
                        );
                    }
                })
                .catch((err) => {
                    console.log("err", err);
                    setSubmitting(false);
                });
        });
    };

    const saveProductLocal = async (item, setSubmitting) => {
        const newItem = {
            0: { price: item.sellingprice0, quantity: item.quantity0 },
            1: { price: item.sellingprice1, quantity: item.quantity1 },
            2: { price: item.sellingprice2, quantity: item.quantity2 },
            3: { price: item.sellingprice3, quantity: item.quantity3 },
            4: { price: item.sellingprice4, quantity: item.quantity4 },
        };
        saveSales(
            "products",
            "sales_local",
            item.description,
            newItem,
            toggleRefreshProducts,
            toggleProductRevDrawer,
            setSubmitting
        );
    };

    const saveProductExports = (item, setSubmitting) => {
        const newItem = {
            0: { price: item.sellingprice0, quantity: item.quantity0 },
            1: { price: item.sellingprice1, quantity: item.quantity1 },
            2: { price: item.sellingprice2, quantity: item.quantity2 },
            3: { price: item.sellingprice3, quantity: item.quantity3 },
            4: { price: item.sellingprice4, quantity: item.quantity4 },
        };
        saveSales(
            "products",
            "sales_exports",
            item.description,
            newItem,
            toggleRefreshProducts,
            toggleProdGlobalRevDrawer,
            setSubmitting
        );
    };

    const saveMerchandiseLocal = (item, setSubmitting) => {
        const newItem = {
            0: { price: item.sellingprice0, quantity: item.quantity0 },
            1: { price: item.sellingprice1, quantity: item.quantity1 },
            2: { price: item.sellingprice2, quantity: item.quantity2 },
            3: { price: item.sellingprice3, quantity: item.quantity3 },
            4: { price: item.sellingprice4, quantity: item.quantity4 },
        };

        saveSales(
            "merchandise",
            "sales_local",
            item.description,
            newItem,
            toggleRefreshMerch,
            toggleMerchRevDrawer,
            setSubmitting
        );
    };

    const saveMerchandiseExports = (item, setSubmitting) => {
        const newItem = {
            0: { price: item.sellingprice0, quantity: item.quantity0 },
            1: { price: item.sellingprice1, quantity: item.quantity1 },
            2: { price: item.sellingprice2, quantity: item.quantity2 },
            3: { price: item.sellingprice3, quantity: item.quantity3 },
            4: { price: item.sellingprice4, quantity: item.quantity4 },
        };

        saveSales(
            "merchandise",
            "sales_exports",
            item.description,
            newItem,
            toggleRefreshMerch,
            toggleMerchGlobalRevDrawer,
            setSubmitting
        );
    };

    return (
        <Box m="20px">
            <Header
                title="Revenue"
                subtitle="Your company's expected revenue"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box ml="15px" mb="16px">
                    <RevenueTables
                        toggleOtherDrawer={toggleOtherDrawer}
                        toggleMerchRevDrawer={toggleMerchRevDrawer}
                        toggleProductRevDrawer={toggleProductRevDrawer}
                        toggleProdGlobalRevDrawer={toggleProdGlobalRevDrawer}
                        toggleMerchGlobalRevDrawer={toggleMerchGlobalRevDrawer}
                        otherRevData={otherRevData}
                        merchRevData={merchRevData}
                        productsRevData={productSalesLocal}
                        productsGlobalRevData={productSalesExport}
                        merchGlobalRevData={merchGlobalRevData}
                        loadingProducts={fetchingProducts}
                        loadingMerch={fetchingMerch}
                        loadingOther={fetchingOther}></RevenueTables>
                </Box>
            </Box>
            <MerchRevDrawer
                merchandise={merchandise}
                merchRevDrawerOpen={merchRevDrawerOpen}
                toggleMerchRevDrawer={toggleMerchRevDrawer}
                saveMerchRevenues={saveMerchandiseLocal}
            />
            <OtherRevDrawer
                otherDrawerOpen={otherDrawerOpen}
                toggleOtherDrawer={toggleOtherDrawer}
                saveOtherRevenues={saveOtherRevenues}
            />
            <ProductRevDrawer
                products={products}
                productRevDrawerOpen={productRevDrawerOpen}
                toggleProductRevDrawer={toggleProductRevDrawer}
                saveProductRevenues={saveProductLocal}></ProductRevDrawer>
            <ProductGlobalRevDrawer
                products={products}
                prodGlobalRevDrawer={prodGlobalRevDrawer}
                toggleProdGlobalRevDrawer={toggleProdGlobalRevDrawer}
                saveProdGlobalRevenues={
                    saveProductExports
                }></ProductGlobalRevDrawer>
            <MerchGlobalRevDrawer
                merchandise={merchandise}
                merchGlobalRevDrawer={merchGlobalRevDrawer}
                toggleMerchGlobalRevDrawer={toggleMerchGlobalRevDrawer}
                saveMerchGlobalRevenues={
                    saveMerchandiseExports
                }></MerchGlobalRevDrawer>
        </Box>
    );
};
export default Revenue;
