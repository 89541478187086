import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
import { mockBarData as data } from "../data/mockDataFinancial";

const BarChart = ({ data }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const Bartheme = {
    axis: {
      domain: {
        line: {
          stroke: colors.grey[100],
        },
      },
      legend: {
        text: {
          fill: colors.grey[100],
        },
      },
      ticks: {
        line: {
          stroke: colors.grey[100],
          strokeWidth: 1,
        },
        text: {
          fill: colors.grey[100],
          fontSize: 9.5,
        },
      },
    },
    legends: {
      text: {
        fill: colors.grey[100],
      },
    },
    tooltip: {
      container: {
        color: colors.primary[500],
      },
    },
  };

  return (
    <ResponsiveBar
      data={data}
      theme={Bartheme}
      //   tooltip={({ point }) => (
      //     <div
      //       style={{
      //         padding: "9px 12px",
      //         color: colors.grey[100],
      //         border: "1.5px solid",
      //         borderColor: colors.sideBorder[100],
      //         backgroundColor: colors.backgroundColor[100],
      //         borderRadius: "4px",
      //       }}
      //     >
      //       <strong>{point.data.x}</strong>
      //       <br />
      //       <strong>{point.data.y}</strong>
      //     </div>
      //   )}
      keys={["price"]}
      indexBy='product'
      padding={0.04}
      margin={{ top: 50, right: 45, bottom: 50, left: 60 }}
      innerPadding={0.5}
      groupMode='grouped'
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      // colors={({ id, data }) => data[`${id}Color`]}
      colors={[
        "#8fcfb7",
        "#7287aa",
        "#c2d5fe",
        "#b8c0f1",
        "#e4d780",
        "#7ccff1",
        "#e8b96d",
        "#4ce7da",
        "#f0b397",
        "#7ce2a6",
        "#7adae2",
        "#8590bd",
        "#b1edd8",
        "#f8ce64",
      ]}
      colorBy='index'
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: "fries",
          },
          id: "dots",
        },
        {
          match: {
            id: "sandwich",
          },
          id: "lines",
        },
      ]}
      borderRadius={4}
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      // axisBottom={{
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: "Product",
      //   legendPosition: "middle",
      //   legendOffset: 32,
      // }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Price",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      // legends={[
      //   {
      //     dataFrom: "indexes",
      //     anchor: "bottom-right",
      //     direction: "column",
      //     justify: false,
      //     translateX: 125,
      //     translateY: 17,
      //     itemsSpacing: 3,
      //     itemWidth: 93,
      //     itemHeight: 41,
      //     itemDirection: "left-to-right",
      //     itemOpacity: 0.85,
      //     symbolSize: 13,
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemOpacity: 1,
      //         },
      //       },
      //     ],
      //   },
      // ]}
      role='application'
      ariaLabel='Nivo bar chart demo'
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " product name " + e.indexValue;
      }}
    />
  );
};

export default BarChart;
