import * as React from "react";
// Data-> Costs Table Columns
import { Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import { tokens } from "../theme";
import { CircularProgress, useTheme } from "@mui/material";
import urls from "./cloudFunctionUrls";
import { useState, useEffect } from "react";
import auth from "../firebase/firebase";
// Dynamic company founding year
import companyFoundingYearGetter from "../data/companyFoundingYear";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

const companyFoundingYear = companyFoundingYearGetter();

export const establishmentCostsColumns = [
    // { field: "id", headerName: "ID", flex: 0.5 },
    {
        field: "category",
        headerName: "Category",
        flex: 1,
        minWidth: 130,
    },
    { field: "cost", headerName: "Cost", flex: 1, minWidth: 130 },
    {
        field: "depreciation",
        headerName: "Depreciation Years",
        flex: 1,
        minWidth: 130,
    },
    {
        field: "participationToTotal",
        headerName: "Participation to Total",
        flex: 1,
        minWidth: 140,
    },

    {
        field: "delete",
        headerName: "",
        flex: 0.5,
        renderCell: (params) => {
            if (params.row.dbid !== "UNAVAILABLE")
                return (
                    <DeleteButton
                        params={params}
                        subcollection={"establishment_costs"}
                    />
                );
        },
    },
];

export const futureInvestmentsColumns = [
    // { field: "id", headerName: "ID", flex: 0.5 },
    { field: "description", headerName: "Description", flex: 1, minWidth: 160 },
    { field: "amount", headerName: "Amount", flex: 1, minWidth: 120 },
    {
        field: "depreciation",
        headerName: "Depreciation Years",
        flex: 1,
        minWidth: 120,
    },
    {
        field: "participationToTotal",
        headerName: "Participation to Total",
        flex: 1,
        minWidth: 120,
    },
    { field: "year", headerName: "Year of Investment", flex: 1, minWidth: 120 },
    {
        field: "delete",
        headerName: "",
        flex: 0.5,
        renderCell: (params) => {
            if (params.row.dbid !== "UNAVAILABLE")
                return (
                    <DeleteButton
                        params={params}
                        subcollection={"future_investments"}
                    />
                );
        },
    },
];

export const workingColumns = [
    // { field: "id", headerName: "ID", flex: 0.5 },
    { field: "category", headerName: "Category", flex: 1, minWidth: 140 },
    { field: "description", headerName: "Description", flex: 1, minWidth: 140 },
    {
        field: "year0",
        headerName: (companyFoundingYear + 0).toString(),
        flex: 1,
        minWidth: 115,
    },
    {
        field: "year1",
        headerName: (companyFoundingYear + 1).toString(),
        flex: 1,
        minWidth: 115,
    },
    {
        field: "year2",
        headerName: (companyFoundingYear + 2).toString(),
        flex: 1,
        minWidth: 115,
    },
    {
        field: "year3",
        headerName: (companyFoundingYear + 3).toString(),
        flex: 1,
        minWidth: 115,
    },
    {
        field: "year4",
        headerName: (companyFoundingYear + 4).toString(),
        flex: 1,
        minWidth: 115,
    },
    {
        field: "delete",
        headerName: "",
        flex: 0.5,
        renderCell: (params) => {
            if (params.row.dbid !== "UNAVAILABLE")
                return (
                    <DeleteButton
                        params={params}
                        subcollection={"operating_costs"}
                    />
                );
        },
    },
];

export const operationalGroupingModel = [
    {
        groupId: "Costs by Year",
        children: [
            { field: "year0" },
            { field: "year1" },
            { field: "year2" },
            { field: "year3" },
            { field: "year4" },
        ],
    },
];

export const fundingStructureColumns = [
    { field: "category", headerName: "Category", flex: 1 },
    { field: "amount", headerName: "Amount", flex: 1 },
    { field: "participation", headerName: "Participation to total", flex: 1 },
    {
        field: "delete",
        headerName: "",
        flex: 0.5,
        renderCell: (params) => {
            if (params.row.dbid !== "UNAVAILABLE")
                return (
                    <DeleteButton
                        params={params}
                        subcollection={"initial_funds"}
                    />
                );
            else
                return (
                    <InfoButtonPopover
                        text={
                            "This row is not deletable because it shows the aggregation of all loans taken by the company."
                        }
                    />
                );
        },
    },
];

export const loanDetailsColumns = [
    { field: "description", headerName: "Description", flex: 1, minWidth: 120 },
    { field: "amount", headerName: "Amount Loaned", flex: 1, minWidth: 130 },
    {
        field: "interest",
        headerName: "Annual Interest",
        flex: 1,
        minWidth: 100,
    },
    { field: "years", headerName: "Payment Years", flex: 1, minWidth: 100 },
    { field: "date", headerName: "Date", flex: 1, minWidth: 100 },
    {
        field: "paymentsnumber",
        headerName: "Payments in a Year",
        flex: 1,
        minWidth: 120,
    },
    {
        field: "delete",
        headerName: "",
        flex: 0.5,
        renderCell: (params) => {
            if (params.row.dbid !== "UNAVAILABLE")
                return <DeleteButton params={params} subcollection={"loans"} />;
        },
    },
];

export const productsColumns = [
    // { field: "id", headerName: "ID", flex: 0.5 },
    { field: "type", headerName: "Type", flex: 0.7, minWidth: 70 },
    { field: "name", headerName: "Product Name", flex: 1, minWidth: 100 },
];

export const deleteProductField = {
    field: "delete",
    headerName: "",
    flex: 0.5,
    renderCell: (params) => {
        if (params.row.dbid !== "UNAVAILABLE")
            return <DeleteButton params={params} subcollection={"products"} />;
    },
};

export const productsGroupingModel = [
    {
        groupId: "Materials used in production",
        children: [],
    },
];

export const merchandiseColumns = [
    // { field: "id", headerName: "ID", flex: 0.5 },
    { field: "name", headerName: "Name", flex: 1, minWidth: 115 },
    {
        field: "year0",
        headerName: (companyFoundingYear + 0).toString(),
        flex: 1,
        minWidth: 90,
    },
    {
        field: "year1",
        headerName: (companyFoundingYear + 1).toString(),
        flex: 1,
        minWidth: 90,
    },
    {
        field: "year2",
        headerName: (companyFoundingYear + 2).toString(),
        flex: 1,
        minWidth: 90,
    },
    {
        field: "year3",
        headerName: (companyFoundingYear + 3).toString(),
        flex: 1,
        minWidth: 90,
    },
    {
        field: "year4",
        headerName: (companyFoundingYear + 4).toString(),
        flex: 1,
        minWidth: 90,
    },
    {
        field: "delete",
        headerName: "",
        flex: 0.5,
        renderCell: (params) => {
            if (params.row.dbid !== "UNAVAILABLE")
                return (
                    <DeleteButton
                        params={params}
                        subcollection={"merchandise"}
                    />
                );
        },
    },
];

export const merchandiseGroupingModel = [
    {
        groupId: "Acquisition Cost per unit",
        children: [
            { field: "year0" },
            { field: "year1" },
            { field: "year2" },
            { field: "year3" },
            { field: "year4" },
        ],
    },
];

export const materialsColumns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "name", headerName: "Material Name", flex: 1 },
    { field: "meassure", headerName: "Unit Measure", flex: 1 },
    { field: "costPerUnit", headerName: "Unit Cost", flex: 1 },
    {
        field: "delete",
        headerName: "",
        flex: 0.5,
        renderCell: (params) => {
            if (params.row.dbid !== "UNAVAILABLE")
                return <DeleteMaterialButton params={params} />;
        },
    },
];

const InfoButtonPopover = ({ text }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            <IconButton onClick={handleClick}>
                <InfoIcon />
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}>
                <Typography
                    sx={{
                        p: 2,
                        maxWidth: "200px",
                        borderRadius: "0",
                    }}>
                    {text}
                </Typography>
            </Popover>
        </>
    );
};

const DeleteButton = ({ params, subcollection }) => {
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        setLoading(false);
    }, [params.row.toggleRefresh]);

    return (
        <IconButton
            onClick={() => {
                setLoading(true);
                // check if dbid is array or string
                let dbid = params.row.dbid;
                // if dbid is not an array, make it
                if (!Array.isArray(dbid)) {
                    dbid = [dbid];
                }

                auth.currentUser.getIdToken().then((token) => {
                    fetch(urls.importAPI + "/deleteEntry", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        },
                        body: JSON.stringify({
                            subcollection_name: subcollection,
                            // check if dbid is array or string
                            dbid: dbid,
                        }),
                    })
                        .then((response) => {
                            if (response.status === 200) {
                                params.row.toggleRefresh();
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                            alert(
                                "Error deleting entry. Please try again later."
                            );
                        });
                });
            }}>
            {loading ? (
                <CircularProgress
                    style={{ color: colors.grey[100] }}
                    size={20}
                />
            ) : (
                <DeleteIcon />
            )}
        </IconButton>
    );
};

// this is different because we have to remove material from produts as well
const DeleteMaterialButton = ({ params }) => {
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        setLoading(false);
    }, [params.row.toggleRefresh]);

    return (
        <IconButton
            onClick={() => {
                setLoading(true);
                // check if dbid is array or string
                let dbid = params.row.dbid;
                // if dbid is not an array, make it

                auth.currentUser.getIdToken().then((token) => {
                    fetch(urls.importAPI + "/deletematerial", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        },
                        body: JSON.stringify({
                            // check if dbid is array or string
                            dbid: dbid,
                        }),
                    })
                        .then((response) => {
                            if (response.status === 200) {
                                params.row.toggleRefresh();
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                            alert(
                                "Error deleting entry. Please try again later."
                            );
                        });
                });
            }}>
            {loading ? (
                <CircularProgress
                    style={{ color: colors.grey[100] }}
                    size={20}
                />
            ) : (
                <DeleteIcon />
            )}
        </IconButton>
    );
};

export const otherRevColumns = [
    { field: "id", headerName: "ID", flex: 0.5, hide: true },
    { field: "description", headerName: "Description", flex: 1, minWidth: 150 },
    {
        field: "year0",
        headerName: (companyFoundingYear + 0).toString(),
        flex: 1,
        minWidth: 125,
    },
    {
        field: "year1",
        headerName: (companyFoundingYear + 1).toString(),
        flex: 1,
        minWidth: 125,
    },
    {
        field: "year2",
        headerName: (companyFoundingYear + 2).toString(),
        flex: 1,
        minWidth: 125,
    },
    {
        field: "year3",
        headerName: (companyFoundingYear + 3).toString(),
        flex: 1,
        minWidth: 125,
    },
    {
        field: "year4",
        headerName: (companyFoundingYear + 4).toString(),
        flex: 1,
        minWidth: 125,
    },
    {
        field: "delete",
        headerName: "",
        // minWidth: 25,
        flex: 0.5,
        renderCell: (params) => {
            if (params.row.dbid !== "UNAVAILABLE")
                return (
                    <DeleteButton
                        params={params}
                        subcollection="income_sources"
                    />
                );
        },
    },
];
export const RevColumns = [
    { field: "id", headerName: "ID", flex: 0.5, hide: true },
    {
        field: "description",
        headerName: "Description",
        flex: 0.7,
        minWidth: 140,
    },
    { field: "price0", headerName: "Price", flex: 0.7, minWidth: 110 },
    { field: "quantity0", headerName: "Quantity", flex: 0.7, minWidth: 110 },
    { field: "price1", headerName: "Price", flex: 0.7, minWidth: 110 },
    { field: "quantity1", headerName: "Quantity", flex: 0.7, minWidth: 110 },
    { field: "price2", headerName: "Price", flex: 0.7, minWidth: 110 },
    { field: "quantity2", headerName: "Quantity", flex: 0.7, minWidth: 110 },
    { field: "price3", headerName: "Price", flex: 0.7, minWidth: 110 },
    { field: "quantity3", headerName: "Quantity", flex: 0.7, minWidth: 110 },
    { field: "price4", headerName: "Price", flex: 0.7, minWidth: 110 },
    { field: "quantity4", headerName: "Quantity", flex: 0.7, minWidth: 110 },
    {
        field: "delete",
        headerName: "",
        flex: 0.5,
        renderCell: (params) => {
            if (params.row.dbid !== "UNAVAILABLE")
                return <DeleteSalesButton params={params} />;
        },
    },
];

export const RevGroupingModel = [
    {
        groupId: (companyFoundingYear + 0).toString(),
        children: [{ field: "price0" }, { field: "quantity0" }],
    },
    {
        groupId: (companyFoundingYear + 1).toString(),
        children: [{ field: "price1" }, { field: "quantity1" }],
    },
    {
        groupId: (companyFoundingYear + 2).toString(),
        children: [{ field: "price2" }, { field: "quantity2" }],
    },
    {
        groupId: (companyFoundingYear + 3).toString(),
        children: [{ field: "price3" }, { field: "quantity3" }],
    },
    {
        groupId: (companyFoundingYear + 4).toString(),
        children: [{ field: "price4" }, { field: "quantity4" }],
    },
];

export const merchRevColumns = [];
export const merchGlobalRevColumns = [];
export const productsGlobalRevColumns = [];

const DeleteSalesButton = ({ params }) => {
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    useEffect(() => {
        setLoading(false);
    }, [params.row.toggleRefresh]);

    return (
        <IconButton
            onClick={() => {
                setLoading(true);
                // check if dbid is array or string
                let dbid = params.row.dbid;
                // if dbid is not an array, make it
                saveSales(
                    params.row.sale_type,
                    params.row.area,
                    dbid,
                    {},
                    params.row.toggleRefresh
                );
            }}>
            {loading ? (
                <CircularProgress
                    style={{ color: colors.grey[100] }}
                    size={20}
                />
            ) : (
                <DeleteIcon />
            )}
        </IconButton>
    );
};

const saveSales = async (
    saleType,
    area,
    product_dbid,
    sales,
    toggleRefresh
) => {
    await auth.currentUser.getIdToken(true).then(async (token) => {
        await fetch(urls.importAPI + "/updatesales", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({
                sale_type: saleType,
                area: area,
                dbid: product_dbid,
                sales: {},
            }),
        })
            .then((res) => {
                if (res.status === 200) {
                    toggleRefresh();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    });
};
