const companyFoundingYearGetter = () => {
    if (
        localStorage.getItem("companyFoundingYear") === null ||
        localStorage.getItem("companyFoundingYear") === undefined
    ) {
        return new Date().getFullYear();
    }
    return parseInt(localStorage.getItem("companyFoundingYear"));
};

export default companyFoundingYearGetter;
