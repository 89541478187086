import Header from "../../components/Header";
import {
    Box,
    Button,
    Drawer,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import LoadingSkeleton from "../../components/LoadingSkeleton";
import ToolbarReport2 from "../../components/ToolbarReport2";
import { tableStyling } from "../../globalStyling/tablesStyling";
import ParametersDrawer from "./ParametersDrawer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { DataGrid, NoRowsOverlay } from "@mui/x-data-grid";
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file

import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { useState, useEffect } from "react";
import urls from "../../data/cloudFunctionUrls";
import auth from "../../firebase/firebase";

const objectToRow = (obj, id, parameter) => {
    const values = obj?.values;
    const row = {
        id: id,
        parameter: parameter || "",
        year0: toPercent(values?.["0"]) || null,
        year1: toPercent(values?.["1"]) || null,
        year2: toPercent(values?.["2"]) || null,
        year3: toPercent(values?.["3"]) || null,
        year4: toPercent(values?.["4"]) || null,
    };

    return row;
};

// function that turns number to % string
const toPercent = (number) => {
    number = parseFloat(number) || 0;
    return (number * 100).toFixed(1) + "%";
};

const CompanyParameters = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const companyFoundingYear = companyFoundingYearGetter();
    const [parametersDrawerOpen, setParametersDrawerOpen] = useState(false);

    const tableStyles = tableStyling(colors);

    // dividendPayoutRatio0
    // royaltyRate0;
    // taxRate0

    const saveParametersEmpty = (newParameters, setSubmitting) => {
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/setoperatingaccountparameters", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },

                body: JSON.stringify({
                    parameters: [
                        {
                            type: "Tax rate",
                            values: {
                                0: parseFloat(newParameters.taxRate0),
                                1: parseFloat(newParameters.taxRate1),
                                2: parseFloat(newParameters.taxRate2),
                                3: parseFloat(newParameters.taxRate3),
                                4: parseFloat(newParameters.taxRate4),
                            },
                            costOfEquity: parseFloat(
                                newParameters.costofequitycapital
                            ),
                        },
                        {
                            type: "Dividend payout ratio",
                            values: {
                                0: parseFloat(
                                    newParameters.dividendPayoutRatio0
                                ),
                                1: parseFloat(
                                    newParameters.dividendPayoutRatio1
                                ),
                                2: parseFloat(
                                    newParameters.dividendPayoutRatio2
                                ),
                                3: parseFloat(
                                    newParameters.dividendPayoutRatio3
                                ),
                                4: parseFloat(
                                    newParameters.dividendPayoutRatio4
                                ),
                            },
                        },
                        {
                            type: "Royalty Rate",
                            values: {
                                0: parseFloat(newParameters.royaltyRate0),
                                1: parseFloat(newParameters.royaltyRate1),
                                2: parseFloat(newParameters.royaltyRate2),
                                3: parseFloat(newParameters.royaltyRate3),
                                4: parseFloat(newParameters.royaltyRate4),
                            },
                        },
                    ],
                    costOfEquity: parseFloat(newParameters.costofequitycapital),
                }),
            })
                .then((res) => {
                    if (res.status === 200) {
                        setRefreshData(!refreshData);
                        setSubmitting(false);
                        toggleParametersDrawer();
                    } else if (res.status === 400) {
                        setSubmitting(false);
                        alert(
                            "You have already added a record of this category."
                        );
                    }
                })
                .catch((err) => {
                    console.log("err", err);
                    setSubmitting(false);
                });
        });
    };

    const saveParametersNonEmpty = (newParameters, setSubmitting) => {
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/updateoperatingaccountparameters", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },

                body: JSON.stringify({
                    parameters: {
                        type: newParameters.parameter,
                        values: {
                            0: parseFloat(newParameters.param0),
                            1: parseFloat(newParameters.param1),
                            2: parseFloat(newParameters.param2),
                            3: parseFloat(newParameters.param3),
                            4: parseFloat(newParameters.param4),
                        },
                    },
                    costOfEquity: parseFloat(newParameters.costofequitycapital),
                }),
            })
                .then((res) => {
                    if (res.status === 200) {
                        setRefreshData(!refreshData);
                        setSubmitting(false);
                        toggleParametersDrawer();
                    } else if (res.status === 400) {
                        setSubmitting(false);
                        alert(
                            "You have already added a record of this category."
                        );
                    }
                })
                .catch((err) => {
                    console.log("err", err);
                    setSubmitting(false);
                });
        });
    };

    const toggleParametersDrawer = (e) => {
        setParametersDrawerOpen(!parametersDrawerOpen);
    };

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [costOfEquity, setCostOfEquity] = useState("");
    const [refreshData, setRefreshData] = useState(false);

    const columns = [
        {
            field: "parameter",
            headerName: "Parameter",
            width: 180,
            sortable: false,
        },
        ,
        {
            field: "year0",
            headerName: String(companyFoundingYear),
            flex: 0.7,
            minWidth: 100,
            sortable: false,
        },
        {
            field: "year1",
            headerName: String(companyFoundingYear + 1),
            flex: 0.7,
            minWidth: 100,
            sortable: false,
        },
        {
            field: "year2",
            headerName: String(companyFoundingYear + 2),
            flex: 0.7,
            minWidth: 100,
            sortable: false,
        },
        {
            field: "year3",
            headerName: String(companyFoundingYear + 3),
            flex: 0.7,
            minWidth: 100,
            sortable: false,
        },
        {
            field: "year4",
            headerName: String(companyFoundingYear + 4),
            flex: 0.7,
            minWidth: 100,
            sortable: false,
        },
        // add more columns here
    ];

    useEffect(() => {
        // This is the function that will update the values of the working capital
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.reportAPI + "/getCompanyOperatingAccountParameters", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else if (response.status === 404) {
                        throw new Error("No parameters found");
                    }
                })
                .then((data) => {
                    const parameterList = [
                        "Tax rate",
                        "Dividend payout ratio",
                        "Royalties rate",
                    ];

                    const parameterData = parameterList.map(
                        (parameter, index) => {
                            return objectToRow(
                                data.parameters[index],
                                index + 1,
                                data.parameters[index].type
                            );
                        }
                    );

                    console.log("data.costOfEquity", data.costOfEquity);

                    setCostOfEquity(data.costOfEquity);

                    setLoading(false);

                    setData(parameterData);
                })
                .catch((error) => {
                    console.log(error);
                    setData([]);
                    setLoading(false);
                });
        });
    }, [refreshData]);

    const CustomNoRowsOverlay = () => {
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    position: "absolute",
                    zIndex: 10,
                }}>
                <Typography sx={{ mb: "16px", maxWidth: "500px" }}>
                    You have not added any parameters yet. Click on the button
                    below to set parameters.
                </Typography>
                <IconButton
                    onClick={toggleParametersDrawer}
                    aria-label="delete"
                    sx={{
                        backgroundColor: colors.buttonBackground[100],
                        color: colors.buttonTextColor[100],
                        zIndex: 1,
                        border: "1px solid",
                        borderColor: colors.buttonColor[100],
                    }}>
                    <ArrowForwardIcon />
                </IconButton>
            </Box>
        );
    };

    return (
        <Box m="20px">
            <Header
                title="Parameters"
                subtitle="Various parameters needed for following calculations that are unique to your company."
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box ml="15px" height="500px" sx={tableStyles}>
                    <DataGrid
                        rows={data}
                        loading={loading}
                        density="compact"
                        columns={columns}
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                            LoadingOverlay: LoadingSkeleton,
                            Toolbar: () => (
                                <ToolbarReport2
                                    editDrawer={toggleParametersDrawer}
                                />
                            ), //

                            ToolbarReport2,
                        }}
                        autoHeight={!loading && data.length >= 1}
                    />
                </Box>
            </Box>
            <ParametersDrawer
                costOfEquity={costOfEquity}
                parametersDrawerOpen={parametersDrawerOpen}
                toggleParametersDrawer={toggleParametersDrawer}
                saveParameters={
                    data.length !== 0
                        ? saveParametersNonEmpty
                        : saveParametersEmpty
                }
                parameterData={data}
                isNotFirstTime={data.length !== 0}
            />
        </Box>
    );
};

export default CompanyParameters;
