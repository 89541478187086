import {
    Box,
    Button,
    Drawer,
    IconButton,
    TextField,
    Typography,
    Tab,
    ButtonGroup,
} from "@mui/material";
import "./ratiosStyling.css";
import ToolbarReport from "../../components/ToolbarReport";
import LoadingSkeleton from "../../components/LoadingSkeleton";
import { DataGrid } from "@mui/x-data-grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { useState, useEffect } from "react";
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file
import { mockLineData as data } from "../../data/mockData";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import { tableStylingReports } from "../../globalStyling/tablesStyling";
import LineChart from "../../components/LineChart";
import Header from "../../components/Header";
import urls from "../../data/cloudFunctionUrls";
import auth from "../../firebase/firebase";
import initialRows from "./initialRows.json";

const titleDict = {
    Assets: "Assets",
    "Fixed assets": "fixedAssetsPerYear",
    "Property, plant and equipment (PPE)": "constructionCosts",
    Depreciation: "depreciationsPerYear",
    "Inventory-raw materials": "rawMaterialsAndMerchandiseInventoryPerYear",
    "Inventory-finished goods": "productInventoryPerYear",
    "Accounts receivables": "customerCreditsPerYear",
    Cash: "cashPerYear",
    "Total assets value": "totalActivePerYear",
    "": "",
    "Liabilities and Net Position": "Liabilities and Net Position",
    Equity: "equityPerYear",
    "Share capital": "shareCapitalPerYear",
    Reserves: "reservesPerYear",
    Losses: "damagesPerYear",
    Loans: "loansPerYear",
    "Accounts payable": "supplierCreditsPerYear",
    "Total liabilities": "totalPassivePerYear",
    empty: "",
    "Control check": "controlCheck",
};

const objectToTitleWithContent = (obj, id, category) => {
    const row = {
        id: id,
        category: category || "",
        year01: obj?.["0"].toFixed() || null,
        year11: obj?.["1"].toFixed() || null,
        year21: obj?.["2"].toFixed() || null,
        year31: obj?.["3"].toFixed() || null,
        year41: obj?.["4"].toFixed() || null,
        title: true,
    };
    return row;
};

const objectToTitle = (obj, id, category) => {
    const row = {
        id: id,
        category: category || "",
        year0: null,
        year1: null,
        year2: null,
        year3: null,
        year4: null,
        title: true,
    };
    return row;
};

const objectToRowLeft = (obj, id, category) => {
    const row = {
        id: id,
        category: category || "",
        year00: obj?.["0"].toFixed() || null,
        year10: obj?.["1"].toFixed() || null,
        year20: obj?.["2"].toFixed() || null,
        year30: obj?.["3"].toFixed() || null,
        year40: obj?.["4"].toFixed() || null,
        title: false,
    };
    return row;
};

const objectToRowRight = (obj, id, category) => {
    const row = {
        id: id,
        category: category || "",
        year01: obj?.["0"].toFixed() || null,
        year11: obj?.["1"].toFixed() || null,
        year21: obj?.["2"].toFixed() || null,
        year31: obj?.["3"].toFixed() || null,
        year41: obj?.["4"].toFixed() || null,
        title: false,
    };
    return row;
};

const BalanceSheets = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const companyFoundingYear = companyFoundingYearGetter();
    // If i have null you dont change it, if it has a zero you must input a value

    const ratiosGroupingModel = [
        {
            groupId: String(companyFoundingYear),
            children: [{ field: "year00" }, { field: "year01" }],
        },
        {
            groupId: String(companyFoundingYear + 1),
            children: [{ field: "year10" }, { field: "year11" }],
        },
        {
            groupId: String(companyFoundingYear + 2),
            children: [{ field: "year20" }, { field: "year21" }],
        },
        {
            groupId: String(companyFoundingYear + 3),
            children: [{ field: "year30" }, { field: "year31" }],
        },
        {
            groupId: String(companyFoundingYear + 4),
            children: [{ field: "year40" }, { field: "year41" }],
        },
    ];

    const columns = [
        {
            field: "category",
            headerName: "",
            width: 230,
            sortable: false,
        },
        {
            field: "year00",
            headerName: "",
            flex: 0.7,
            minWidth: 130,
            sortable: false,
        },
        {
            field: "year01",
            headerName: "",
            flex: 0.7,
            minWidth: 130,
            sortable: false,
        },
        {
            field: "year10",
            headerName: "",
            flex: 0.7,
            minWidth: 130,
            sortable: false,
        },
        {
            field: "year11",
            headerName: "",
            flex: 0.7,
            minWidth: 130,
            sortable: false,
        },
        {
            field: "year20",
            headerName: "",
            flex: 0.7,
            minWidth: 130,
            sortable: false,
        },
        {
            field: "year21",
            headerName: "",
            flex: 0.7,
            minWidth: 130,
            sortable: false,
        },
        {
            field: "year30",
            headerName: "",
            flex: 0.7,
            minWidth: 130,
            sortable: false,
        },
        {
            field: "year31",
            headerName: "",
            flex: 0.7,
            minWidth: 130,
            sortable: false,
        },
        {
            field: "year40",
            headerName: "",
            flex: 0.7,
            minWidth: 130,
            sortable: false,
        },
        {
            field: "year41",
            headerName: "",
            flex: 0.7,
            minWidth: 130,
            sortable: false,
        },
    ];

    const tableStyles = tableStylingReports(colors);

    function getRowClassName(params) {
        if (params.row.title) {
            return theme.palette.mode === "dark"
                ? "title-row-dark"
                : "title-row";
        }
    }

    const [balanceSheetData, setBalanceSheetData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [insufficientData, setInsufficientData] = useState(false);

    useEffect(() => {
        setInsufficientData(false);
        // This is the function that will update the values of the break even point
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.reportAPI + "/getCompanyBalanceSheetsAggregations", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else if (response.status === 404) {
                        setInsufficientData(true);
                        setLoading(false);
                    }
                })
                .then((data) => {
                    setLoading(false);
                    data = Object.keys(titleDict).map((key, index) => {
                        if (key == "" || key == "empty") {
                            return {
                                id: key,
                                category: "",
                                title: false,
                            };
                        } else if (
                            ["Assets", "Liabilities and Net Position"].includes(
                                key
                            )
                        ) {
                            return objectToTitle(
                                data[titleDict[key]],
                                index,
                                key
                            );
                        } else if (
                            [
                                "Total assets value",
                                "Total liabilities",
                                "Control check",
                            ].includes(key)
                        ) {
                            return objectToTitleWithContent(
                                data[titleDict[key]],
                                index,
                                key
                            );
                        } else if (
                            [
                                "Property, plant and equipment (PPE)",
                                "Depreciation",
                                "Share capital",
                                "Losses",
                            ].includes(key)
                        ) {
                            return objectToRowLeft(
                                data[titleDict[key]],
                                index,
                                key
                            );
                        } else
                            return objectToRowRight(
                                data[titleDict[key]],
                                index,
                                key
                            );
                    });
                    setBalanceSheetData(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }, []);

    const CustomNoRowsOverlay = () => {
        return insufficientData ? (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    position: "absolute",
                    zIndex: 10,
                }}>
                <Typography sx={{ mb: "16px", maxWidth: "500px" }}>
                    Before generating the Balance Sheets, you need to provide
                    Funding, Cost, Products, Revenue and Parameters Company Data
                </Typography>
            </Box>
        ) : (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    position: "absolute",
                    zIndex: 10,
                }}>
                <Typography sx={{ mb: "16px", maxWidth: "500px" }}>
                    No rows
                </Typography>
            </Box>
        );
    };

    return (
        <Box m="20px">
            <Header
                title="Balance Sheets"
                subtitle=" A snapshot of your company's financial position"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box ml="15px" height="900px" sx={tableStyles}>
                    <DataGrid
                        loading={loading}
                        density="compact"
                        rows={balanceSheetData}
                        columns={columns}
                        experimentalFeatures={{ columnGrouping: true }}
                        columnGroupingModel={ratiosGroupingModel}
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                            LoadingOverlay: LoadingSkeleton,
                            Toolbar: () => <ToolbarReport />, //

                            ToolbarReport,
                        }}
                        getRowClassName={getRowClassName}
                    />
                </Box>
            </Box>
        </Box>
    );
};
export default BalanceSheets;
