export const colorPalette = [
    "#f9fd7c",
    "#a3bd8d",
    "#c1d485",
    "#d6e481",
    "#abc38a",
    "#c5d784",
    "#94b193",
    "#f1f87d",
    "#b3c988",
    "#e2ed7f",
    "#b5ca87",
    "#cddd82",
    "#9eb88f",
    "#aac28a",
    "#d8e580",
    "#bed185",
    "#b1c888",
    "#a7c08b",
    "#93b093",
    "#e6f07e",
    "#edf57e",
    "#b6cb87",
    "#a1bb8d",
    "#cfde82",
    "#b0c789",
    "#d7e581",
    "#dae780",
    "#dde980",
    "#e4ee7f",

    "#bfd285",
    "#a0ba8e",
    "#97b392",
    "#eaf27e",
    "#e3ed7f",
    "#c2d584",
    "#d1e082",
    "#a8c18b",
    "#b8cd87",
    "#acc48a",
    "#f2f97d",
    "#e0eb7f",
    "#e7f17e",
    "#ecf47e",
    "#a4bd8c",
    "#b2c988",
    "#dee980",
    "#d5e381",
    "#9bb690",
    "#cedd82",
    "#e1ec7f",
    "#b9cd86",
    "#c9da83",
    "#bcd086",
    "#a5be8c",
    "#dfea80",
    "#f0f77d",
    "#eff67d",
    "#f4fa7d",
    "#e8f17e",
    "#9ab590",
    "#9fb98e",
    "#c3d584",
    "#92b094",
    "#adc589",
    "#9c9eff",
    "#a6bf8c",
    "#eef57e",
    "#d0df82",
    "#a9c18b",
    "#98b491",
    "#bdd185",
    "#bbcf86",
    "#c4d684",
    "#bace86",
    "#d9e680",
    "#fafe7c",
    "#ccdc83",
    "#c7d983",
    "#d2e181",
    "#c8d983",
    "#dce880",
    "#d4e281",
    "#fbff7c",
    "#9cb78f",
    "#f5fb7d",
    "#f3f97d",
    "#c6d884",
    "#ebf37e",
];
