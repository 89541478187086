import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file
// form validation
import { initialFinvestments, finvestmentsSchema } from "./formValues"; // import the objects from the new file

import { tokens } from "../../theme";
// Mock data for the tables
import "./costs.css";

const companyFoundingYear = companyFoundingYearGetter();

const FinancialInvestmentDrawer = ({
  finvestmentsDrawerOpen,
  toggleFinvestmentsDrawer,
  saveFinvestments,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1000px)");

  return (
    <Drawer
      variant='temporary'
      anchor={"right"}
      open={finvestmentsDrawerOpen}
      onClose={() => toggleFinvestmentsDrawer()}
      PaperProps={{
        sx: {
          width: {
            sm: 320,
            md: 320,
            lg: 360,
            xl: 500,
          },
          borderRadius: "8px",
          border: "1.5px solid",
          borderColor: colors.sideBorder[100],
          backgroundColor: colors.backgroundColor[100],
          backgroundImage: "none",
          height: "98vh",
          margin: "8px 8px 8px 8px ",
        },
      }}
    >
      {/* {list(anchor)} */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid",
          borderBottomColor: colors.sideBorder[100],
          alignItems: "center",
          height: "100px",
          padding: "16px 16px 8px 16px",
        }}
      >
        {/* New Add Title */}
        <Box>
          <Typography
            variant='h2'
            color={colors.grey[100]}
            fontWeight='bold'
            sx={{
              m: "0 0 0px ",
              fontFamily: "Inter",
              fontSize: "20px",
            }}
          >
            <Decoration1></Decoration1>
            <Box sx={{ display: "inline", ml: "12px" }}>New Cost</Box>
          </Typography>
          <Typography ml='30px' variant='h5' color={colors.grey[400]}>
            Additional Future Investments
          </Typography>
        </Box>
        <Box sx={{ height: "58px" }}>
          <IconButton onClick={() => toggleFinvestmentsDrawer()}>
            <HighlightOffIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Formik - We use it to create the form inside the*/}
      <Formik
        onSubmit={(values, { setSubmitting }) =>
          saveFinvestments(values, setSubmitting)
        }
        initialValues={initialFinvestments}
        validationSchema={finvestmentsSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form style={{ height: "90vh" }} onSubmit={handleSubmit}>
            <Box
              height='80%'
              display='flex'
              flexDirection='column'
              columnGap='20px'
              rowGap='20px'
              overflow='auto'
              padding='24px 16px'
              sx={{
                "& 	.MuiFilledInput-root:after": {
                  borderBottomColor: "#2595FF",
                },
                "& 	.Mui-focused": {
                  borderBottomColor: "#2595FF",
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <Box fontSize={isNonMobile ? "14px" : "13px"}>
                Establishment costs planned after company has started operations
                (within years 1-5). These costs usually include purchase of
                buildings, infrastructure development, servers, machinery, new
                office equipment etc. Several of these costs are subject to
                depreciation, such as buildings, machines, computers. In this
                case, the depreciation period must be stated, in years (straight
                line depreciation method is applied).
              </Box>

              {/* <Box width='100%' columnGap='16px' display='flex'> */}

              {/* <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Investment Category"
                                size="small"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.category}
                                name="category"
                                error={!!touched.category && !!errors.category}
                                helperText={touched.category && errors.category}
                            /> */}
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Description'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name='description'
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Investment Value'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.investment}
                name='investment'
                error={!!touched.investment && !!errors.investment}
                helperText={touched.investment && errors.investment}
              />
              <TextField
                fullWidth
                select
                variant='filled'
                type='text'
                label='Investment Year'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.year}
                name='year'
                error={!!touched.year && !!errors.year}
                helperText={touched.year && errors.year}
              >
                <MenuItem value='0'>{companyFoundingYear}</MenuItem>
                <MenuItem value='1'>{companyFoundingYear + 1}</MenuItem>
                <MenuItem value='2'>{companyFoundingYear + 2}</MenuItem>
                <MenuItem value='3'>{companyFoundingYear + 3}</MenuItem>
                <MenuItem value='4'>{companyFoundingYear + 4}</MenuItem>
              </TextField>
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Depreciation Years'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.depreciationYears}
                name='depreciationYears'
                error={
                  !!touched.depreciationYears && !!errors.depreciationYears
                }
                helperText={
                  touched.depreciationYears && errors.depreciationYears
                }
              />
            </Box>
            <Box
              sx={{
                borderTop: "1px solid",
                borderTopColor: colors.sideBorder[100],
                pt: "18px",
                pr: "16px",
              }}
              display='flex'
              gap='8px'
              disabled={isSubmitting}
              justifyContent='end'
              mt={isNonMobile ? "50px" : "15px"}
            >
              <Button
                onClick={() => toggleFinvestmentsDrawer()}
                variant='outlined'
                size={isNonMobile ? "large" : "medium"}
                pr='80px'
                sx={{
                  color: colors.grey[100],
                  fontWeight: "medium",
                  borderColor: colors.sideBorder[100],
                }}
              >
                Cancel
              </Button>
              <Button
                startIcon={isSubmitting ? null : <AddIcon />}
                size={isNonMobile ? "large" : "medium"}
                type='submit'
                variant='contained'
                color='buttonColor'
                disabled={isSubmitting}
                sx={{
                  backgroundColor: "#2595FF",
                  color: "#FFFFFF",
                  // fontFamily: "Arial",
                  // fontSize: "16px",
                  // fontWeight: "bold",
                }}
              >
                {isSubmitting ? (
                  <CircularProgress
                    size={24}
                    style={{ color: colors.grey[100] }}
                  />
                ) : (
                  "New Entry"
                )}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Drawer>
  );
};

export default FinancialInvestmentDrawer;
