import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { useTheme } from "@mui/material";

import { tokens } from "../theme";

const BarChartRisk = ({ data, targetVariable }) => {
    const formatYAxis = (value) => {
        return `${value / 1000}`;
    };
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const Bartheme = {
        axis: {
            domain: {
                line: {
                    stroke: colors.grey[100],
                },
            },
            legend: {
                text: {
                    fill: colors.grey[100],
                    fontWeight: "bold",
                },
            },
            ticks: {
                line: {
                    stroke: colors.grey[100],
                    strokeWidth: 1,
                },
                text: {
                    fill: colors.grey[100],
                    fontSize: 9.5,
                },
            },
        },
        legends: {
            text: {
                fill: colors.grey[100],
            },
        },
        tooltip: {
            container: {
                color: colors.primary[500],
            },
        },
    };

    return (
        <ResponsiveBar
            data={data}
            theme={Bartheme}
            keys={["probability"]}
            indexBy="value"
            // margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
            margin={{ top: 50, right: 45, bottom: 60, left: 60 }}
            colors="#CCCCFF"
            axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -45,
                legend: targetVariable,

                legendOffset: 50,
                legendPosition: "middle",
            }}
            enableLabel={false}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                format: (value) => `${value}%`, // Format y-axis ticks as percentage
            }}
            enableGridY={true}
        />
    );
};

export default BarChartRisk;
