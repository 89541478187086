import { useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import CustomToolbar from "./CustomToolbar";
import { tokens } from "../theme";
import { tableStyling, tabStyling } from "../globalStyling/tablesStyling";
import { Box, Tab, Typography, IconButton } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import LoadingSkeleton from "./LoadingSkeleton";
import HoverColumn from "./HoverColumn";
// We import the columns names
import {
  establishmentCostsColumns,
  futureInvestmentsColumns,
  workingColumns,
  operationalGroupingModel,
} from "../data/tableColumns";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CostsTables = ({
  establishmentData,
  investmentData,
  workingData,
  toggleEstablishmentDrawer,
  toggleOperatingDrawer,
  toggleFinvestmentsDrawer,
  loadingEstablishment,
  loadingInvestment,
  loadingWorking,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //   State for the tabs
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // styling for all the tables
  const tableStyles = tableStyling(colors);
  //   Styling for the tabs
  const tabStyles = tabStyling(colors);

  // here we customize the toolbar to include our buttons to add more rows

  const CustomNoRowsOverlay = (props) => {
    const { message, functionDrawer } = props;
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          backgroundColor: "transparent",
          position: "absolute",
          zIndex: 10,
        }}
      >
        <Typography sx={{ mb: "16px", maxWidth: "500px", fontSize: "12px" }}>
          {message}
        </Typography>
        <IconButton
          onClick={functionDrawer}
          aria-label='delete'
          sx={{
            backgroundColor: colors.buttonBackground[100],
            color: colors.buttonTextColor[100],
            zIndex: 1,
            border: "1px solid",
            borderColor: colors.buttonColor[100],
          }}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    );
  };

  // Columns for the establishment costs table
  return (
    <TabContext value={value}>
      <Box sx={tabStyles}>
        <TabList
          variant='scrollable'
          onChange={handleChange}
          aria-label='lab API tabs example'
          // textColor='secondary'
          // indicatorColor='secondary'
        >
          <Tab label='Establishment Costs' value='1' />
          <Tab label='Operating Expenses' value='2' />
          <Tab label='Additional Future Investments' value='3' />
        </TabList>
      </Box>
      <TabPanel value='1'>
        <Box m='0 0 0 0' height='60vh' width='100%' sx={tableStyles}>
          <Typography
            variant='h4'
            color={colors.grey[100]}
            fontWeight='bold'
            sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}
          >
            Establishment Costs Details
          </Typography>
          {/* Establishment Costs array */}
          <DataGrid
            density='compact'
            loading={loadingEstablishment}
            rows={establishmentData}
            components={{
              NoRowsOverlay: (renderProps) => (
                <CustomNoRowsOverlay
                  functionDrawer={toggleEstablishmentDrawer}
                  message='Here you can add your establishment costs'
                  {...renderProps}
                />
              ),
              LoadingOverlay: LoadingSkeleton,
              Toolbar: () => (
                <CustomToolbar onClickAdd={toggleEstablishmentDrawer} />
              ), //

              CustomToolbar,
            }}
            // rowsPerPageOptionsSkeleton
            renderHeader={(params) => <HoverColumn {...params} />}
            columns={establishmentCostsColumns}
          />
        </Box>
      </TabPanel>
      <TabPanel value='2'>
        <Box m='0 0 0 0' height='60vh' width='100%' sx={tableStyles}>
          <Typography
            variant='h4'
            color={colors.grey[100]}
            fontWeight='bold'
            sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}
          >
            Operating Expenses Details
          </Typography>
          {/* Operating Costs array */}
          <DataGrid
            density='compact'
            loading={loadingWorking}
            rows={workingData}
            // experimentalFeatures={{ columnGrouping: true }}
            // columnGroupingModel={operationalGroupingModel}
            components={{
              NoRowsOverlay: (renderProps) => (
                <CustomNoRowsOverlay
                  functionDrawer={toggleOperatingDrawer}
                  message='Here you can add your operating expenses'
                  {...renderProps}
                />
              ),
              LoadingOverlay: LoadingSkeleton,
              Toolbar: () => (
                <CustomToolbar onClickAdd={toggleOperatingDrawer} />
              ), //

              CustomToolbar,
            }}
            sortModel={[
              {
                field: "category",
                sort: "desc",
              },
            ]}
            columns={workingColumns}
          />
        </Box>
      </TabPanel>
      <TabPanel value='3'>
        <Box m='0 0 0 0' height='60vh' width='100%' sx={tableStyles}>
          <Typography
            variant='h4'
            color={colors.grey[100]}
            fontWeight='bold'
            sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}
          >
            Future Investments Details
          </Typography>
          {/* Future Investments array */}
          <DataGrid
            density='compact'
            loading={loadingInvestment}
            rows={investmentData}
            components={{
              NoRowsOverlay: (renderProps) => (
                <CustomNoRowsOverlay
                  functionDrawer={toggleFinvestmentsDrawer}
                  message='Here you can add the cost of future investments'
                  {...renderProps}
                />
              ),
              LoadingOverlay: LoadingSkeleton,
              Toolbar: () => (
                <CustomToolbar onClickAdd={toggleFinvestmentsDrawer} />
              ), //

              CustomToolbar,
            }}
            columns={futureInvestmentsColumns}
          />
        </Box>
      </TabPanel>
    </TabContext>
  );
};

export default CostsTables;
