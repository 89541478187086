import * as yup from "yup";

export const paramNotEmptySchema = yup.object().shape({
    param0: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    param1: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    param2: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    param3: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    param4: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    parameter: yup
        .string("Input must be a String")
        .required("Field is required"),
    costofequitycapital: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
});

export const initialValuesNonEmpty = {
    param0: "",
    param1: "",
    param2: "",
    param3: "",
    param4: "",
    parameter: "",
    costofequitycapital: "",
};

export const paramEmptySchema = yup.object().shape({
    taxRate0: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    taxRate1: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    taxRate2: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    taxRate3: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    taxRate4: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),

    royaltyRate0: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    royaltyRate1: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    royaltyRate2: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    royaltyRate3: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    royaltyRate4: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),

    dividendPayoutRatio0: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    dividendPayoutRatio1: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    dividendPayoutRatio2: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    dividendPayoutRatio3: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    dividendPayoutRatio4: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
    costofequitycapital: yup
        .number()
        .typeError("Param must be a valid number")
        .min(0, "Param must be non negative")
        .max(1, "Param must be equal or less than 1")
        .required("Field is required"),
});

export const initialValuesEmpty = {
    taxRate0: "",
    taxRate1: "",
    taxRate2: "",
    taxRate3: "",
    taxRate4: "",
    royaltyRate0: "",
    royaltyRate1: "",
    royaltyRate2: "",
    royaltyRate3: "",
    royaltyRate4: "",
    dividendPayoutRatio0: "",
    dividendPayoutRatio1: "",
    dividendPayoutRatio2: "",
    dividendPayoutRatio3: "",
    dividendPayoutRatio4: "",
    costofequitycapital: "",
};
