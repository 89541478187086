import * as firebase from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";

const app = firebase.initializeApp({
    apiKey: "AIzaSyA61-gcqJ4DZY28JmdLe97Q2b6dJuhxuIw",
    authDomain: "fptool-b3b65.firebaseapp.com",
    projectId: "fptool-b3b65",
    storageBucket: "fptool-b3b65.appspot.com",
    messagingSenderId: "1064975985596",
    appId: "1:1064975985596:web:d5971b385f73a86364d9b9",
    measurementId: "G-Y3RS5WDXWS",
});

const auth = getAuth(app);

if (process.env.NODE_ENV === "development") {
    // connectAuthEmulator(auth, "http://localhost:9099");
}

export default auth;
