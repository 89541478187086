import { useTheme, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from "./CustomToolbar";
import BarChart from "./BarChart";
import { tokens } from "../theme";
import { tableStyling, tabStyling } from "../globalStyling/tablesStyling";
import { Box, Tab, IconButton } from "@mui/material";
import {
  otherRevColumns,
  RevColumns,
  RevGroupingModel,
} from "../data/tableColumns";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import LoadingSkeleton from "./LoadingSkeleton";
const RevenueTables = ({
  toggleProductRevDrawer,
  toggleMerchRevDrawer,
  toggleOtherDrawer,
  toggleProdGlobalRevDrawer,
  toggleMerchGlobalRevDrawer,
  otherRevData,
  productsRevData,
  merchRevData,
  productsGlobalRevData,
  merchGlobalRevData,
  loadingProducts,
  loadingMerch,
  loadingOther,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = useState("6");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // styling for all the tables
  const tableStyles = tableStyling(colors);
  //   Styling for the tabs
  const tabStyles = tabStyling(colors);
  const CustomNoRowsOverlay = (props) => {
    const { message, functionDrawer } = props;
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          backgroundColor: "transparent",
          position: "absolute",
          zIndex: 10,
        }}
      >
        <Typography sx={{ mb: "16px", maxWidth: "500px", fontSize: "12px" }}>
          {message}
        </Typography>
        <IconButton
          onClick={functionDrawer}
          aria-label='delete'
          sx={{
            backgroundColor: colors.buttonBackground[100],
            color: colors.buttonTextColor[100],
            zIndex: 1,
            border: "1px solid",
            borderColor: colors.buttonColor[100],
          }}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    );
  };

  return (
    <TabContext value={value}>
      <Box sx={tabStyles}>
        <TabList
          variant='scrollable'
          onChange={handleChange}
          aria-label='lab API tabs example'
          // textColor='secondary'
          // indicatorColor='secondary'
        >
          <Tab label='Product and Services Sales' value='6' />
          <Tab label='Merchandise Sales' value='7' />
          <Tab label='Other Revenue' value='8' />
        </TabList>
      </Box>
      {/* Products and Services Sales */}
      <TabPanel value='6'>
        <Box
          m='0 0 0 0'
          display='flex'
          flexDirection='column'
          height='140vh'
          gap={8}
          width='100%'
          sx={tableStyles}
        >
          <Box flex={1}>
            <Typography
              variant='h4'
              color={colors.grey[100]}
              fontWeight='bold'
              sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}
            >
              Global Product Sales
            </Typography>
            <DataGrid
              density='compact'
              loading={loadingProducts}
              experimentalFeatures={{ columnGrouping: true }}
              columnGroupingModel={RevGroupingModel}
              rows={productsGlobalRevData}
              components={{
                NoRowsOverlay: (renderProps) => (
                  <CustomNoRowsOverlay
                    functionDrawer={toggleProdGlobalRevDrawer}
                    message='Here you can add your global product sales'
                    {...renderProps}
                  />
                ),
                LoadingOverlay: LoadingSkeleton,
                Toolbar: () => (
                  <CustomToolbar onClickAdd={toggleProdGlobalRevDrawer} />
                ), //

                CustomToolbar,
              }}
              columns={RevColumns}
            />
          </Box>
          <Box flex={1}>
            <Typography
              variant='h4'
              color={colors.grey[100]}
              fontWeight='bold'
              sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}
            >
              Domestic Product Sales
            </Typography>
            <DataGrid
              density='compact'
              loading={loadingProducts}
              experimentalFeatures={{ columnGrouping: true }}
              columnGroupingModel={RevGroupingModel}
              rows={productsRevData}
              components={{
                NoRowsOverlay: (renderProps) => (
                  <CustomNoRowsOverlay
                    functionDrawer={toggleProductRevDrawer}
                    message='Here you can add your domestic product sales'
                    {...renderProps}
                  />
                ),
                LoadingOverlay: LoadingSkeleton,
                Toolbar: () => (
                  <CustomToolbar onClickAdd={toggleProductRevDrawer} />
                ), //

                CustomToolbar,
              }}
              columns={RevColumns}
            />
          </Box>
        </Box>
      </TabPanel>
      {/* Merch sales */}
      <TabPanel value='7'>
        <Box
          m='0 0 0 0'
          display='flex'
          flexDirection='column'
          height='140vh'
          gap={8}
          width='100%'
          sx={tableStyles}
        >
          <Box flex={1}>
            <Typography
              variant='h4'
              color={colors.grey[100]}
              fontWeight='bold'
              sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}
            >
              Global Merchandise Sales
            </Typography>
            <DataGrid
              density='compact'
              loading={loadingMerch}
              experimentalFeatures={{ columnGrouping: true }}
              columnGroupingModel={RevGroupingModel}
              rows={merchGlobalRevData}
              components={{
                NoRowsOverlay: (renderProps) => (
                  <CustomNoRowsOverlay
                    functionDrawer={toggleMerchGlobalRevDrawer}
                    message='Here you can add your global merchandise sales'
                    {...renderProps}
                  />
                ),
                LoadingOverlay: LoadingSkeleton,
                Toolbar: () => (
                  <CustomToolbar onClickAdd={toggleMerchGlobalRevDrawer} />
                ), //

                CustomToolbar,
              }}
              columns={RevColumns}
            />
          </Box>
          <Box flex={1}>
            <Typography
              variant='h4'
              color={colors.grey[100]}
              fontWeight='bold'
              sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}
            >
              Domestic Merchandise Sales
            </Typography>
            <DataGrid
              density='compact'
              loading={loadingMerch}
              rows={merchRevData}
              experimentalFeatures={{ columnGrouping: true }}
              columnGroupingModel={RevGroupingModel}
              components={{
                NoRowsOverlay: (renderProps) => (
                  <CustomNoRowsOverlay
                    functionDrawer={toggleMerchRevDrawer}
                    message='Here you can add your domestic merchandise sales'
                    {...renderProps}
                  />
                ),
                LoadingOverlay: LoadingSkeleton,
                Toolbar: () => (
                  <CustomToolbar onClickAdd={toggleMerchRevDrawer} />
                ), //

                CustomToolbar,
              }}
              columns={RevColumns}
            />
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value='8'>
        <Box
          m='0 0 0 0'
          display='flex'
          flexDirection='column'
          height='60vh'
          // gap={8}
          width='100%'
          sx={tableStyles}
        >
          <Typography
            variant='h4'
            color={colors.grey[100]}
            fontWeight='bold'
            sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}
          >
            Other Revenue Details
          </Typography>
          <DataGrid
            density='compact'
            loading={loadingOther}
            rows={otherRevData}
            components={{
              NoRowsOverlay: (renderProps) => (
                <CustomNoRowsOverlay
                  functionDrawer={toggleOtherDrawer}
                  message='Here you can add your other revenue'
                  {...renderProps}
                />
              ),
              LoadingOverlay: LoadingSkeleton,
              Toolbar: () => <CustomToolbar onClickAdd={toggleOtherDrawer} />, //

              CustomToolbar,
            }}
            columns={otherRevColumns}
          />
        </Box>
      </TabPanel>
    </TabContext>
  );
};

export default RevenueTables;
