import { Box, IconButton, useTheme, Button } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { useState, useEffect } from "react";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import auth from "../../firebase/firebase";

import SettingsIcon from "@mui/icons-material/Settings";
import SettingsDrawer from "./SettingsDrawer";
const Topbar = ({ isCollapsed, setIsCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const thresholdWidth = 950;
  const [settingsOpen, setSettingsOpen] = useState(false);
  const toggleSettingsOpen = () => {
    setSettingsOpen(!settingsOpen);
  };
  // This useEffect is used to detect the screen width and collapse the sidebar if the screen is below 950px
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const collapseSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Box
      color={colors.sideBorder[100]}
      overflow='hidden'
      borderBottom='1px solid !important'
      display='flex'
      //   backgroundColor={colors.primary[500]}
      justifyContent='space-between'
      p={1}
    >
      {/* SEARCH BAR */}
      <Box
        sx={{
          fontWeight: "medium",
          mt: "3px",
          backgroundColor: "transparent",
        }}
        display='flex'
        borderRadius='3px'
      >
        <IconButton onClick={() => collapseSidebar()}>
          <MenuOpenRoundedIcon />
        </IconButton>
      </Box>

      {/* ICONS */}
      <Box display='flex'>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        {/* <IconButton sx={{ ml: 2 }}>
          <NotificationsOutlinedIcon />
        </IconButton> */}
        {/* <IconButton sx={{ ml: 2 }}>
          <SettingsOutlinedIcon />
        </IconButton> */}
        <IconButton sx={{ ml: 2 }} onClick={() => toggleSettingsOpen()}>
          <SettingsIcon />
        </IconButton>

        <IconButton sx={{ ml: 2 }} onClick={() => auth.signOut()}>
          <LogoutIcon />
        </IconButton>
      </Box>
      <SettingsDrawer
        DrawerOpen={settingsOpen}
        toggleDrawer={toggleSettingsOpen}
      />
    </Box>
  );
};

export default Topbar;
