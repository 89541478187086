import {
    Box,
    Button,
    Drawer,
    IconButton,
    TextField,
    Typography,
    Tab,
    ButtonGroup,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { useState, useEffect } from "react";
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file
import { mockLineData as data } from "../../data/mockData";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import {
    tableStyling,
    tabStyling,
    buttonGroupStyling,
    activeButtonStyling,
} from "../../globalStyling/tablesStyling";
import LineChart from "../../components/LineChart";
import Header from "../../components/Header";
import urls from "../../data/cloudFunctionUrls";
import auth from "../../firebase/firebase";

const destructuredData = (data) => {
    const newLineChartData = {
        0: {},
        1: {},
        2: {},
        3: {},
        4: {},
    };
    Object.keys(data).forEach((key) => {
        Object.keys(data.breakEvenPointMoneyPerYear).forEach((year) => {
            newLineChartData[year] = {
                fixedCost: data.fixedCostsPerYear[year].toFixed(2) || 0,
                FixedMinusRevenues:
                    data.fixedCostsMinusOtherRevenuesPerYear[year].toFixed(2) ||
                    0,
                // the second point of the sales line
                salesQuantity: data.totalUnitsSoldPerYear[year].toFixed(2) || 0,
                salesValue: data.totalSalesPerYear[year].toFixed(2) || 0,
                // the first point of the total cost line
                totalCostatZeroSales:
                    data.fixedCostsMinusOtherRevenuesPerYear[year].toFixed(2) ||
                    0,
                // the second point of the total cost line
                totalCostatSales:
                    data.totalCostsMinusOtherRevenuesPerYear[year].toFixed(2) ||
                    0,
                totalCostQuantity:
                    data.totalUnitsSoldPerYear[year].toFixed(2) || 0,
                bepValue: data.breakEvenPointMoneyPerYear[year].toFixed(2),
                bepQuantity: data.breakEvenPointUnitsPerYear[year].toFixed(0),
            };
        });
    });
    return newLineChartData;
};

const BreakEven = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    //   State for the tabs
    const [lineChartData, setLineChartData] = useState({
        0: {},
        1: {},
        2: {},
        3: {},
        4: {},
    });

    const [yearData, setyearData] = useState(0);
    const changeDataYear = (year) => {
        setyearData(year);
    };
    const [insufficientData, setInsufficientData] = useState(false);

    useEffect(() => {
        setInsufficientData(false);
        // This is the function that will update the values of the break even point
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.reportAPI + "/getCompanyBreakEvenPointAggregations", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else if (response.status === 404) {
                        setInsufficientData(true);
                    }
                })
                .then((data) => {
                    setLineChartData(destructuredData(data));
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }, []);

    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const buttonGroupStyles = buttonGroupStyling(colors);
    const activeButton = activeButtonStyling(colors);
    // This is the object that will hold the values for the break even point
    // On the change data year function, the values will be updated allongside the button

    const companyFoundingYear = companyFoundingYearGetter();
    return (
        <Box m="20px">
            <Header
                title="Break Even Point"
                subtitle="The point at which you are profitable"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box ml="15px">
                    <Box sx={buttonGroupStyles}>
                        <ButtonGroup
                            variant="outlined"
                            aria-label="outlined button group">
                            <Button
                                onClick={() => changeDataYear(0)}
                                style={yearData === 0 ? activeButton : {}}>
                                {companyFoundingYear}
                            </Button>
                            <Button
                                onClick={() => changeDataYear(1)}
                                style={yearData === 1 ? activeButton : {}}>
                                {companyFoundingYear + 1}
                            </Button>
                            <Button
                                style={yearData === 2 ? activeButton : {}}
                                onClick={() => changeDataYear(2)}>
                                {companyFoundingYear + 2}
                            </Button>
                            <Button
                                style={yearData === 3 ? activeButton : {}}
                                onClick={() => changeDataYear(3)}>
                                {companyFoundingYear + 3}
                            </Button>
                            <Button
                                style={yearData === 4 ? activeButton : {}}
                                onClick={() => changeDataYear(4)}>
                                {companyFoundingYear + 4}
                            </Button>
                        </ButtonGroup>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0px",
                            flexWrap: "wrap",
                            border: "1px solid ",
                            borderRadius: "4px",

                            marginTop: "20px",
                            borderColor: colors.sideBorder[100],

                            // flexDirection: { xs: "column", sm: "row" },
                        }}>
                        <Box
                            flex={3}
                            flexBasis="100px"
                            // height='450px'
                            display="flex"
                            position="relative"
                            maxWidth="100%"
                            gap={10}
                            p={3}>
                            <Box>
                                {" "}
                                <Decoration1 />
                                <Typography
                                    variant="h4"
                                    color={colors.grey[400]}
                                    fontWeight="medium"
                                    sx={{
                                        m: "0px 0px 0px 16px",
                                        fontFamily: "Inter",
                                        fontSize: "0.8rem",
                                        display: "inline",
                                    }}>
                                    Quantity to break even
                                </Typography>
                                <Typography
                                    variant="h5"
                                    color={colors.grey[100]}
                                    sx={{
                                        m: "0px 0px 0px 33px",
                                        fontFamily: "Inter",
                                        fontWeight: "bold",
                                        fontSize: "1.5rem",
                                    }}>
                                    {lineChartData[yearData].bepQuantity}
                                </Typography>
                            </Box>
                            <Box>
                                {" "}
                                <Decoration1 />
                                <Typography
                                    variant="h4"
                                    color={colors.grey[400]}
                                    fontWeight="medium"
                                    sx={{
                                        m: "0px 0px 0px 16px",
                                        fontFamily: "Inter",
                                        fontSize: "0.8rem",
                                        display: "inline",
                                    }}>
                                    Revenue to break even
                                </Typography>
                                <Typography
                                    variant="h5"
                                    color={colors.grey[100]}
                                    sx={{
                                        m: "0px 0px 0px 33px",
                                        fontFamily: "Inter",
                                        fontWeight: "bold",
                                        fontSize: "1.5rem",
                                    }}>
                                    {lineChartData[yearData].bepValue}
                                </Typography>
                            </Box>
                        </Box>

                        <Box
                            flex={3}
                            flexBasis="500px"
                            // height='450px'
                            position="relative"
                            maxWidth="100%">
                            <Box
                                sx={{
                                    height: "100%",

                                    backgroundColor: "transparent",
                                    position: "absolute",

                                    width: "100%",
                                    display: "flex",

                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <LineChart
                                    breakEvenValues={lineChartData[yearData]}
                                    insufficientData={
                                        insufficientData
                                    }></LineChart>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default BreakEven;
