import { tokens } from "../../theme";
import React from "react";
import { useState, useEffect } from "react";
import { ReactComponent as EmptyState } from "../../assets/noData.svg";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import BarChartRisk from "../../components/BarChartRisk";
import LineChartRisk from "../../components/LineChartRisk";

import {
    Box,
    TextField,
    useTheme,
    Button,
    Typography,
    useMediaQuery,
} from "@mui/material";
import Header from "../../components/Header";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RiskDrawer from "./RiskDrawer";
import urls from "../../data/cloudFunctionUrls";
import auth from "../../firebase/firebase";

const MonteCarlo = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [beenConfigured, setBeenConfigured] = useState(false); // this is to know if the user has already configured the sensitivity analysis
    const [openRisk, setOpenRisk] = useState(false); // this is to know if the user has already configured the sensitivity analysis
    const toggleRisk = () => setOpenRisk(!openRisk);
    const isMobile = useMediaQuery("(max-width:1170px)");

    const dataDensity = [
        { value: 100, probability: 0.3 },
        { value: 30, probability: 0.2 },
        { value: 50, probability: 0.15 },
        { value: 80, probability: 0.1 },
        { value: 20, probability: 0.08 },
        { value: 10, probability: 0.05 },
        { value: 60, probability: 0.05 },
        // Add more data points here
    ];
    const dataCumulative = [
        {
            id: "line",
            data: [
                // { x: 0, y: 0 },
                // { x: 0.1, y: 10 },
                // { x: 0.3, y: 20 },
                // { x: 0.6, y: 30 },
                // { x: 0.9, y: 40 },
                // { x: 0.95, y: 50 },
                // { x: 0.97, y: 60 },
                // { x: 0.99, y: 70 },
                // { x: 0.995, y: 80 },
                // { x: 0.999, y: 90 },
                // { x: 1, y: 100 },
                // Add more data points here
            ],
        },
    ];
    const [chartDataDensity, setChartDataDensity] = useState(dataDensity);
    const [chartDataCumulative, setChartDataCumulative] =
        useState(dataCumulative);

    console.log("chartDataCumulative", chartDataCumulative);

    const [targetVariable, setTargetVariable] = useState("Target Variable");

    const [upperBound, setUpperBound] = useState(100);
    const [lowerBound, setLowerBound] = useState(0);
    const [probability, setProbability] = useState(0.5);

    useEffect(() => {
        if (chartDataCumulative[0].data.length > 0) {
            setLowerBound(chartDataCumulative[0].data[0].x);
            setUpperBound(
                chartDataCumulative[0].data[
                    chartDataCumulative[0].data.length - 1
                ].x
            );
        }
    }, [chartDataCumulative]);

    useEffect(() => {
        // find the probability of the upper bound
        let upperBoundProb = 0;
        let upperBoundDist = Infinity;
        let lowerBoundProb = 0;
        let lowerBoundDist = Infinity;

        chartDataCumulative[0].data.forEach((item) => {
            if (Math.abs(item.x - upperBound) < upperBoundDist) {
                upperBoundDist = Math.abs(item.x - upperBound);
                upperBoundProb = item.y;
            }

            if (Math.abs(item.x - lowerBound) < lowerBoundDist) {
                lowerBoundDist = Math.abs(item.x - lowerBound);
                lowerBoundProb = item.y;
            }
        });

        console.log("upperBoundProb", upperBoundProb);
        console.log("lowerBoundProb", lowerBoundProb);

        setProbability((upperBoundProb - lowerBoundProb) * 100);
    }, [upperBound, lowerBound]);

    const [outputVariable, setOutputVariable] = useState({});

    // takes the form data and makes the sensitivity analysis request
    const handleSubmit = (values) => {
        // Extract the input data from the values
        const categories = [
            values.category_0,
            values.category_1,
            values.category_2,
        ];
        const firstparams = [
            values.firstparam_0,
            values.firstparam_1,
            values.firstparam_2,
        ];
        const secondparams = [
            values.secondparam_0,
            values.secondparam_1,
            values.secondparam_2,
        ];

        const distribution = [
            values.distribution_0,
            values.distribution_1,
            values.distribution_2,
        ];

        const meanValue = [
            values.meanValue_0,
            values.meanValue_1,
            values.meanValue_2,
        ];

        const standardDeviation = [
            values.standardDeviation_0,
            values.standardDeviation_1,
            values.standardDeviation_2,
        ];

        const minimumValue = [
            values.minimumValue_0,
            values.minimumValue_1,
            values.minimumValue_2,
        ];

        const maximumValue = [
            values.maximumValue_0,
            values.maximumValue_1,
            values.maximumValue_2,
        ];

        const middleValue = [
            values.middleValue_0,
            values.middleValue_1,
            values.middleValue_2,
        ];

        const outputcategory = values.outputcategory;
        const outputparam = values.outputparam;

        // Construct the input variables
        const inputVariables = categories
            .map((category, index) => {
                if (category && firstparams[index]) {
                    return {
                        category: category,
                        selection: firstparams[index],
                        year: secondparams[index]
                            ? parseInt(secondparams[index])
                            : 0,
                        distribution: {
                            type: distribution[index],
                            mean: meanValue[index]
                                ? parseFloat(meanValue[index])
                                : 0,
                            std: standardDeviation[index]
                                ? parseFloat(standardDeviation[index])
                                : 0,
                            min: minimumValue[index]
                                ? parseFloat(minimumValue[index])
                                : 0,
                            max: maximumValue[index]
                                ? parseFloat(maximumValue[index])
                                : 0,
                            mid: middleValue[index]
                                ? parseFloat(middleValue[index])
                                : 0,
                        },
                    };
                }
                return null;
            })
            .filter((item) => item);

        // Construct the output data
        const outputData = {
            inputVariables: inputVariables,
            outputVariable: {
                category: outputcategory,
                year: outputparam ? parseInt(outputparam) : 0,
            },
        };

        // Make the request
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.reportAPI + "/getRiskAnalysis", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(outputData),
            })
                .then((res) => {
                    if (res.status === 200) {
                        return res.json();
                    }
                })
                .then((data) => {
                    console.log("data", data);
                    let dataDensity = [];
                    let dataCumulative = [{ id: "line", data: [] }];

                    // data.pdf is object
                    Object.keys(data.pdf).forEach((key) => {
                        dataDensity.push({
                            value: parseFloat(key),
                            probability: data.pdf[key],
                        });
                    });

                    // data.cdf is object
                    Object.keys(data.cdf).forEach((key) => {
                        dataCumulative[0].data.push({
                            x: parseFloat(key),
                            y: data.cdf[key],
                        });
                    });

                    setChartDataDensity(dataDensity);
                    setChartDataCumulative(dataCumulative);

                    // setData(data);
                    setOutputVariable(outputcategory);
                    setBeenConfigured(true);
                    // setChartDataDensity(data.dataDensity);
                    // setChartDataCumulative(data.dataCumulative);
                    // setTargetVariable(data.targetVariable);
                    // setUpperBound(data.upperBound);
                    // setLowerBound(data.lowerBound);
                    // setProbability(data.probability);

                    toggleRisk();
                    // setFetchingProductsData(false);
                })
                .catch((err) => {
                    console.log("err", err);
                    alert(
                        "Something went wrong. Make sure you have filled all necessary company data."
                    );
                    // setFetchingProductsData(false);
                });
        });
    };
    const [hasError, setHasError] = useState(false);
    const [hasError2, setHasError2] = useState(false);

    const handleChangeBound = (event) => {
        const inputValue = event.target.value;

        if (inputValue === "") {
            setHasError(true);
            setLowerBound(""); // Allow empty input
            return;
        } else {
            setHasError(false);
        }

        // Check if the inputValue is an integer or an empty string (to allow clearing the input)
        if (parseFloat(inputValue) < parseFloat(upperBound)) {
            setLowerBound(inputValue);
        }
    };
    const handleChangeBound2 = (event) => {
        const inputValue = event.target.value;

        if (inputValue === "") {
            setHasError(true);
            setUpperBound(""); // Allow empty input
            return;
        } else {
            setHasError(false);
        }

        // Check if the inputValue is an float or an empty string (to allow clearing the input)
        // if (/^[-+]?\d*\.?\d+$/.test(inputValue)) {
        //     setUpperBound(inputValue);
        // }

        if (parseFloat(inputValue) > parseFloat(lowerBound)) {
            setUpperBound(inputValue);
        }
    };

    return (
        <Box m="20px">
            <Header
                title="Risk Analysis"
                subtitle="Account for randomness and variability when examining your company"
            />
            {beenConfigured ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <Button
                        endIcon={<KeyboardArrowRightIcon />}
                        variant="outlined"
                        onClick={toggleRisk}
                        sx={{
                            backgroundColor: colors.buttonBackground[100],
                            color: colors.buttonTextColor[100],
                            zIndex: 1,
                            width: "fit-content",
                            // mb: "16px",

                            borderColor: colors.buttonColor[100],
                            "&:hover": {
                                backgroundColor: colors.buttonBackground[100],
                                borderColor: colors.buttonColor[100],
                            },
                        }}>
                        Configure Analysis
                    </Button>
                    <Box
                        flex={3}
                        flexBasis="100px"
                        // height='450px'
                        display="flex"
                        position="relative"
                        maxWidth="100%"
                        gap={10}
                        sx={{
                            "& 	.MuiFilledInput-root:after": {
                                borderBottomColor: "#2595FF",
                            },
                            "& 	.Mui-focused": {
                                borderBottomColor: "#2595FF",
                                color: `${colors.grey[100]} !important`,
                            },
                        }}
                        pt={3}
                        pb={3}>
                        <Box>
                            {" "}
                            <Decoration1 />
                            <Typography
                                variant="h4"
                                color={colors.grey[400]}
                                fontWeight="medium"
                                sx={{
                                    m: "0px 0px 0px 16px",
                                    fontFamily: "Inter",
                                    fontSize: "0.8rem",
                                    display: "inline",
                                }}>
                                Upper Limit
                            </Typography>
                            <Typography
                                variant="h5"
                                color={colors.grey[100]}
                                sx={{
                                    m: "0px 0px 0px 33px",
                                    fontFamily: "Inter",
                                    fontWeight: "bold",
                                    fontSize: "1.5rem",
                                }}>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    sx={{
                                        mt: "8px",
                                    }}
                                    type="number"
                                    label="Upper Limit"
                                    size="small"
                                    // onBlur={handleBlur}
                                    onChange={handleChangeBound2}
                                    value={upperBound}
                                    name="Upper Limit"
                                    error={hasError2}
                                    helperText={
                                        hasError2
                                            ? "Value cannot be empty."
                                            : null
                                    }
                                    // error={!!touched.inventoryDays && !!errors.inventoryDays}
                                    // helperText={touched.inventoryDays && errors.inventoryDays}
                                ></TextField>
                            </Typography>
                        </Box>
                        <Box>
                            {" "}
                            <Decoration1 />
                            <Typography
                                variant="h4"
                                color={colors.grey[400]}
                                fontWeight="medium"
                                sx={{
                                    m: "0px 0px 0px 16px",
                                    fontFamily: "Inter",
                                    fontSize: "0.8rem",
                                    display: "inline",
                                }}>
                                Lower Limit
                            </Typography>
                            <Typography
                                variant="h5"
                                color={colors.grey[100]}
                                sx={{
                                    m: "0px 0px 0px 33px",
                                    fontFamily: "Inter",
                                    fontWeight: "bold",
                                    fontSize: "1.5rem",
                                }}>
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    sx={{
                                        mt: "8px",
                                    }}
                                    type="number"
                                    label="Lower Limit"
                                    size="small"
                                    // onBlur={handleBlur}
                                    onChange={handleChangeBound}
                                    value={lowerBound}
                                    name="Lower Limit"
                                    error={hasError}
                                    helperText={
                                        hasError
                                            ? "Value cannot be empty."
                                            : null
                                    }
                                    // error={!!touched.inventoryDays && !!errors.inventoryDays}
                                    // helperText={touched.inventoryDays && errors.inventoryDays}
                                ></TextField>
                            </Typography>
                        </Box>
                        <Box>
                            {" "}
                            <Decoration1 />
                            <Typography
                                variant="h4"
                                color={colors.grey[400]}
                                fontWeight="medium"
                                sx={{
                                    m: "0px 0px 0px 16px",
                                    fontFamily: "Inter",
                                    fontSize: "0.8rem",
                                    display: "inline",
                                }}>
                                Probability
                            </Typography>
                            <Typography
                                variant="h5"
                                color={colors.grey[100]}
                                sx={{
                                    m: "0px 0px 0px 33px",
                                    fontFamily: "Inter",
                                    fontWeight: "bold",
                                    fontSize: "1.5rem",
                                }}>
                                {probability}
                                {"%"}
                            </Typography>
                        </Box>
                    </Box>
                    <Box display="flex" flexWrap="wrap" gap={0.5}>
                        <Box
                            flex="1 1 450px"
                            minWidth="550px"
                            minHeight={isMobile ? "50vh" : "60vh"}
                            m={1}>
                            <Typography
                                variant="h4"
                                color={colors.grey[100]}
                                fontWeight="bold"
                                sx={{
                                    m: "8px 16px 16px 0",
                                    fontFamily: "Inter",
                                }}>
                                Probability Density Function
                            </Typography>
                            <Box
                                sx={{
                                    padding: 2,
                                    height: "85%",
                                    borderRadius: "4px",
                                    width: "100%",
                                    border: "1px solid ",

                                    borderColor: colors.sideBorder[100],
                                }}>
                                <Box
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                    }}>
                                    <BarChartRisk
                                        data={chartDataDensity}
                                        targetVariable={outputVariable}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box
                            flex="1 1 450px"
                            minWidth="550px"
                            minHeight={isMobile ? "50vh" : "60vh"}
                            m={1}>
                            <Typography
                                variant="h4"
                                color={colors.grey[100]}
                                fontWeight="bold"
                                sx={{
                                    m: "8px 16px 16px 0",
                                    fontFamily: "Inter",
                                }}>
                                Cumulative Distribution Function
                            </Typography>
                            <Box
                                sx={{
                                    padding: 2,
                                    height: "85%",
                                    borderRadius: "4px",
                                    backgroundColor: "transparent",
                                    // position: "absolute",
                                    border: "1px solid ",

                                    display: "flex",
                                    borderColor: colors.sideBorder[100],
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                <Box
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                    }}>
                                    <LineChartRisk
                                        data={chartDataCumulative}
                                        targetVariable={targetVariable}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        alignContent: "center",
                        justifyContent: "flex-start",
                        height: "100%",
                        width: "100%",
                        padding: "32px 24px 24px 24px",
                    }}>
                    <EmptyState />
                    <Typography
                        variant="h4"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{ m: "16px 16px 8px 0", fontFamily: "Inter" }}>
                        Awaiting your selections to conduct risk analysis, also
                        known as Monte Carlo simulation
                    </Typography>
                    <Box
                        color={colors.grey[300]}
                        maxWidth={"700px"}
                        fontSize={"13px"}
                        mb={"16px"}>
                        In Monte Carlo simulation, random sampling is performed
                        on input parameters within a defined range or
                        probability distribution. These inputs are then used to
                        generate multiple simulations or iterations of the
                        system being studied. Each iteration represents a
                        possible outcome or scenario.
                    </Box>

                    <Button
                        endIcon={<KeyboardArrowRightIcon />}
                        variant="outlined"
                        onClick={toggleRisk}
                        sx={{
                            backgroundColor: colors.buttonBackground[100],
                            color: colors.buttonTextColor[100],
                            zIndex: 1,
                            mb: "16px",

                            borderColor: colors.buttonColor[100],
                            "&:hover": {
                                backgroundColor: colors.buttonBackground[100],
                                borderColor: colors.buttonColor[100],
                            },
                        }}>
                        Get Started
                    </Button>
                </Box>
            )}
            <RiskDrawer
                openRisk={openRisk}
                toggleRisk={toggleRisk}
                saveRisk={handleSubmit}
            />
        </Box>
    );
};

export default MonteCarlo;
