import { useState } from "react";
import * as React from "react";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import AddIcon from "@mui/icons-material/Add";

import {
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Formik } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// form validation
import { materialsSchema, initialMaterials } from "./formValues"; // import the objects from the new file
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file

import { tokens } from "../../theme";
// Mock data for the tables

const companyFoundingYear = companyFoundingYearGetter();

const MaterialsDrawer = ({
  materialsDrawerOpen,
  toggleMaterialsDrawer,
  saveNewMaterials,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1000px)");
  return (
    <Drawer
      variant='temporary'
      anchor={"right"}
      open={materialsDrawerOpen}
      onClose={() => toggleMaterialsDrawer()}
      PaperProps={{
        sx: {
          width: {
            sm: 320,
            md: 320,
            lg: 360,
            xl: 500,
          },
          borderRadius: "8px",
          border: "1.5px solid",
          borderColor: colors.sideBorder[100],
          backgroundColor: colors.backgroundColor[100],
          backgroundImage: "none",
          height: "98vh",
          margin: "8px 8px 8px 8px",
        },
      }}
    >
      {/* {list(anchor)} */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid",
          borderBottomColor: colors.sideBorder[100],
          alignItems: "center",
          height: "100px",
          padding: "16px 16px 8px 16px",
        }}
      >
        {/* New Add Title */}
        <Box>
          <Typography
            variant='h2'
            color={colors.grey[100]}
            fontWeight='bold'
            sx={{
              m: "0 0 0px ",
              fontFamily: "Inter",
              fontSize: "20px",
            }}
          >
            <Decoration1></Decoration1>
            <Box sx={{ display: "inline", ml: "12px" }}>New Material</Box>
          </Typography>
          <Typography ml='30px' variant='h5' color={colors.grey[400]}>
            Products and Merchandise
          </Typography>
        </Box>
        <Box sx={{ height: "58px" }}>
          <IconButton onClick={() => toggleMaterialsDrawer()}>
            <HighlightOffIcon />
          </IconButton>
        </Box>
      </Box>
      <Formik
        onSubmit={(values, { setSubmitting }) =>
          saveNewMaterials(values, setSubmitting)
        }
        initialValues={initialMaterials}
        validationSchema={materialsSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form style={{ height: "90vh" }} onSubmit={handleSubmit}>
            <Box
              height='80%'
              display='flex'
              flexDirection='column'
              columnGap='20px'
              rowGap='20px'
              overflow='auto'
              padding='24px 16px'
              sx={{
                "& 	.MuiFilledInput-root:after": {
                  borderBottomColor: "#2595FF",
                },
                "& 	.Mui-focused": {
                  borderBottomColor: "#2595FF",
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <Box fontSize={isNonMobile ? "14px" : "13px"}>
                State the raw materials needed in the production of the products
                and/or other variable costs included in the production of the
                services.
              </Box>

              {/* <Box width='100%' columnGap='16px' display='flex'> */}

              {/* </Box> */}
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Material Name'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name='name'
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              ></TextField>
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Unit Measure'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.unitmeasure}
                name='unitmeasure'
                error={!!touched.unitmeasure && !!errors.unitmeasure}
                helperText={touched.unitmeasure && errors.unitmeasure}
              >
                {" "}
              </TextField>

              <TextField
                fullWidth
                variant='filled'
                type='number'
                label='Unit Cost'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.unitcost}
                name='unitcost'
                error={!!touched.unitcost && !!errors.unitcost}
                helperText={touched.unitcost && errors.unitcost}
              />
            </Box>

            <Box
              sx={{
                borderTop: "1px solid",
                borderTopColor: colors.sideBorder[100],
                pt: "18px",
                pr: "16px",
                mb: "16px",
              }}
              display='flex'
              gap='8px'
              justifyContent='end'
              // mt={isNonMobile ? "50px" : "10px"}
            >
              <Button
                onClick={() => toggleMaterialsDrawer()}
                variant='outlined'
                size={isNonMobile ? "large" : "medium"}
                pr='80px'
                disabled={isSubmitting}
                sx={{
                  color: colors.grey[100],
                  fontWeight: "medium",
                  borderColor: colors.sideBorder[100],
                }}
              >
                Cancel
              </Button>
              <Button
                startIcon={isSubmitting ? null : <AddIcon />}
                size={isNonMobile ? "large" : "medium"}
                type='submit'
                variant='contained'
                color='buttonColor'
                disabled={isSubmitting}
                sx={{
                  backgroundColor: "#2595FF",
                  color: "#FFFFFF",
                  // fontFamily: "Arial",
                  // fontSize: "16px",
                  // fontWeight: "bold",
                }}
              >
                {isSubmitting ? (
                  <CircularProgress
                    size={24}
                    style={{ color: colors.grey[100] }}
                    // color="primary"
                  />
                ) : (
                  "New Entry"
                )}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Drawer>
  );
};

export default MaterialsDrawer;
