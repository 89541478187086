import { Box, Typography } from "@mui/material";
import "./ratiosStyling.css";
import ToolbarReport from "../../components/ToolbarReport";
import LoadingSkeleton from "../../components/LoadingSkeleton";
import { DataGrid } from "@mui/x-data-grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { useState, useEffect } from "react";
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file
import { mockLineData as data } from "../../data/mockData";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import { tableStylingReports } from "../../globalStyling/tablesStyling";
import Header from "../../components/Header";
import urls from "../../data/cloudFunctionUrls";
import auth from "../../firebase/firebase";
import initialRows from "./initialRows.json";

const titleDict = {
    "Profit before interest and taxes + Depreciation":
        "earningsBeforeInterestTaxDepreciationPerYear",
    "Minus: interest expenses and exploitation rights":
        "interestsCostsOperatingRightsPerYear",
    "Minus: income tax": "incomeTaxPerYear",
    "Minus: increase in inventories": "reservesIncreasePerYear",
    "Minus: increase in receivables": "customerCreditsAggregatePerYear",
    "Plus: increase in payables": "payablesIncreasePerYear",
    "Operating Activities Total": "fromOperatingActivitiesPerYear",
    "Minus: increase in fixed assets": "fixedAssetsIncreasePerYear",
    "Investing Activities": "fromInvestingActivitiesPerYear",
    "Minus: dividends": "dividendsPerYear",
    "Plus: increase in loans": "loanIncreasePerYear",
    "Initial costs and funding": "",
    "Equity participation": "equity",
    Loans: "initialLoans",
    "Investment expenses": "capitalExpenditures",
    "Financial Activities Total": "cashFlowPerYear",
    "Cash Flows Categories": "",
    "Net Annual Cash Flows": "cashFlowPerYear",
    "Cumulative Cash Flows": "cumulativeCashFlowPerYear",
    "Free Cash Flows before borrowing": "freeCashFlowBeforeDebtServicePeryear",
    "Cumulative Free Cash Flows before financing":
        "cumulativeFreeCashFlowBeforeDebtServicePeryear",
};

const objectToRow = (obj, id, category) => {
    const row = {
        id: id,
        category: category || "",
        yearMinus1: obj?.["-1"] || null,
        year0: obj?.["0"].toFixed() || null,
        year1: obj?.["1"].toFixed() || null,
        year2: obj?.["2"].toFixed() || null,
        year3: obj?.["3"].toFixed() || null,
        year4: obj?.["4"].toFixed() || null,
        title: obj?.title || false,
    };
    return row;
};

const CashFlows = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const companyFoundingYear = companyFoundingYearGetter();
    // If i have null you dont change it, if it has a zero you must input a value

    const ratiosGroupingModel = [
        {
            groupId: "Years",
            children: [
                { field: "yearMinus1" },
                { field: "year0" },
                { field: "year1" },
                { field: "year2" },
                { field: "year3" },
                { field: "year4" },
            ],
        },
    ];

    const columns = [
        {
            field: "category",
            headerName: "",
            width: 270,
            sortable: false,
        },
        {
            field: "yearMinus1",
            headerName: String(companyFoundingYear - 1),
            flex: 0.7,
            minWidth: 145,
            sortable: false,
        },
        {
            field: "year0",
            headerName: String(companyFoundingYear),
            flex: 0.7,
            minWidth: 145,
            sortable: false,
        },
        {
            field: "year1",
            headerName: String(companyFoundingYear + 1),
            flex: 0.7,
            minWidth: 145,
            sortable: false,
        },
        {
            field: "year2",
            headerName: String(companyFoundingYear + 2),
            flex: 0.7,
            minWidth: 145,
            sortable: false,
        },
        {
            field: "year3",
            headerName: String(companyFoundingYear + 3),
            flex: 0.7,
            minWidth: 145,
            sortable: false,
        },
        {
            field: "year4",
            headerName: String(companyFoundingYear + 4),
            flex: 0.7,
            minWidth: 145,
            sortable: false,
        },
        // add more columns here
    ];
    const tableStyles = tableStylingReports(colors);

    function getRowClassName(params) {
        if (params.row.title) {
            return theme.palette.mode === "dark"
                ? "title-row-dark"
                : "title-row";
        }
    }
    const [ratioData, setRatioData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [insufficientData, setInsufficientData] = useState(false);

    useEffect(() => {
        setInsufficientData(false);
        // This is the function that will update the values of the break even point
        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.reportAPI + "/getCompanyCashFlowAggregations", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else if (response.status === 404) {
                        setInsufficientData(true);
                        setLoading(false);
                    }
                })
                .then((data) => {
                    setLoading(false);
                    data = Object.keys(titleDict).map((key, index) => {
                        if (
                            [
                                "Initial costs and funding",
                                "Cash Flows Categories",
                            ].includes(key)
                        ) {
                            return {
                                id: index,
                                category: key,
                                yearMinus1: null,
                                year0: null,
                                year1: null,
                                year2: null,
                                year3: null,
                                year4: null,
                                title: true,
                            };
                        } else if (
                            [
                                "Equity participation",
                                "Loans",
                                "Investment expenses",
                            ].includes(key)
                        ) {
                            return {
                                id: index,
                                category: key,
                                yearMinus1:
                                    (data?.[titleDict[key]]).toFixed() || null,
                                year0: null,
                                year1: null,
                                year2: null,
                                year3: null,
                                year4: null,
                                title: false,
                            };
                        } else if (key === "Cash flows categories") {
                            return {
                                id: index,
                                category: key,
                                yearMinus1: null,
                                year0: null,
                                year1: null,
                                year2: null,
                                year3: null,
                                year4: null,
                                title: true,
                            };
                        } else if (
                            [
                                "Operating Activities Total",
                                "Investing Activities",
                                "Financial Activities Total",
                            ].includes(key)
                        ) {
                            return {
                                id: index,
                                category: key,
                                yearMinus1: null,
                                year0:
                                    data[titleDict[key]]?.["0"].toFixed() ||
                                    "-",
                                year1:
                                    data[titleDict[key]]?.["1"].toFixed() ||
                                    "-",
                                year2:
                                    data[titleDict[key]]?.["2"].toFixed() ||
                                    "-",
                                year3:
                                    data[titleDict[key]]?.["3"].toFixed() ||
                                    "-",
                                year4:
                                    data[titleDict[key]]?.["4"].toFixed() ||
                                    "-",
                                title: true,
                            };
                        } else {
                            return objectToRow(
                                data[titleDict[key]],
                                index,
                                key
                            );
                        }
                    });
                    setRatioData(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }, []);

    const CustomNoRowsOverlay = () => {
        return insufficientData ? (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    position: "absolute",
                    zIndex: 10,
                }}>
                <Typography sx={{ mb: "16px", maxWidth: "500px" }}>
                    Before generating the Cash Flows Report, you need to provide
                    Funding, Cost and Parameters Company Data
                </Typography>
            </Box>
        ) : (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    position: "absolute",
                    zIndex: 10,
                }}>
                <Typography sx={{ mb: "16px", maxWidth: "500px" }}>
                    No rows
                </Typography>
            </Box>
        );
    };
    return (
        <Box m="20px">
            <Header
                title="Cash Flows Forecast"
                subtitle="Anticipate your liquidity needs and make informed decisions"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box ml="15px" height="930px" sx={tableStyles}>
                    <DataGrid
                        density="compact"
                        loading={loading}
                        rows={ratioData}
                        columns={columns}
                        experimentalFeatures={{ columnGrouping: true }}
                        columnGroupingModel={ratiosGroupingModel}
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                            LoadingOverlay: LoadingSkeleton,
                            Toolbar: () => <ToolbarReport />, //

                            ToolbarReport,
                        }}
                        getRowClassName={getRowClassName}
                    />
                </Box>
            </Box>
        </Box>
    );
};
export default CashFlows;
