import React, { useEffect, useState } from "react";
import auth from "./firebase.js";
import urls from "../data/cloudFunctionUrls.json";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [companyDetails, setCompanyDetails] = useState(null);
    const [pending, setPending] = useState(true);

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setPending(false);

            user.getIdToken(true).then((token) => {
                // BE CAREFUL !!!
                console.log(token);
                fetch(urls.importAPI + "/getcompany", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        }
                    })
                    .then(async (data) => {
                        const companyFoundingYear = new Date(
                            data.company_founding_date._seconds * 1000
                        ).getFullYear();

                        // save companyFoundingYear to local storage
                        await localStorage.setItem(
                            "companyFoundingYear",
                            companyFoundingYear
                        );

                        setCompanyDetails(data);
                    });
            });
        });
    }, []);

    if (pending) {
        return <></>;
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                companyDetails,
            }}>
            {children}
        </AuthContext.Provider>
    );
};
