import { useState, useEffect } from "react";
import * as React from "react";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
    Box,
    Button,
    Drawer,
    IconButton,
    TextField,
    Typography,
    CircularProgress,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import {
    useMediaQuery,
    useTheme,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { Formik } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// form validation
import {
    initialValuesNonEmpty,
    paramNotEmptySchema,
    paramEmptySchema,
    initialValuesEmpty,
} from "./formValues"; // import the objects from the new file
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file

import { tokens } from "../../theme";
// Mock data for the tables

import "./costs.css";

const percentageStringToDecimal = (percentageString) => {
    return parseFloat(percentageString) / 100;
};

const listToObject = (list) => {
    const obj = {};
    list.forEach((item) => {
        obj[item.parameter] = { ...item };
    });
    return obj;
};

const companyFoundingYear = companyFoundingYearGetter();

const ParametersDrawer = ({
    costOfEquity,
    parametersDrawerOpen,
    toggleParametersDrawer,
    saveParameters,
    parameterData,
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    isNotFirstTime,
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
}) => {
    console.log("costOfEquity", costOfEquity);
    const [parameters, setParameters] = useState(listToObject(parameterData));
    useEffect(() => {
        setParameters(listToObject(parameterData));
    }, [parameterData]);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:1000px)");

    const handleSelectChange = (event, resetFormFunction, costOfEquityVal) => {
        resetFormFunction({
            values: {
                param0: percentageStringToDecimal(
                    parameters[event.target.value].year0
                ).toString(),
                param1: percentageStringToDecimal(
                    parameters[event.target.value].year1
                ).toString(),
                param2: percentageStringToDecimal(
                    parameters[event.target.value].year2
                ).toString(),
                param3: percentageStringToDecimal(
                    parameters[event.target.value].year3
                ).toString(),
                param4: percentageStringToDecimal(
                    parameters[event.target.value].year4
                ).toString(),
                parameter: event.target.value,
                costofequitycapital: costOfEquityVal.toString(),
            },
        });
    };

    const accordions = ["Tax Rate", "Royalty Rate", "Divident payout ratio"];
    return (
        <Drawer
            variant="temporary"
            anchor={"right"}
            open={parametersDrawerOpen}
            onClose={() => toggleParametersDrawer()}
            PaperProps={{
                sx: {
                    width: {
                        sm: 320,
                        md: 320,
                        lg: 360,
                        xl: 500,
                    },
                    borderRadius: "8px",
                    border: "1.5px solid",
                    borderColor: colors.sideBorder[100],
                    backgroundColor: colors.backgroundColor[100],
                    backgroundImage: "none",
                    height: "98vh",
                    margin: "8px 8px 8px 8px ",
                },
            }}>
            {/* {list(anchor)} */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderBottom: "1px solid",
                    borderBottomColor: colors.sideBorder[100],
                    alignItems: "center",
                    height: "100px",
                    padding: "16px 16px 8px 16px",
                }}>
                {/* New Add Title */}
                <Box>
                    <Typography
                        variant="h2"
                        color={colors.grey[100]}
                        fontWeight="bold"
                        sx={{
                            m: "0 0 0px ",
                            fontFamily: "Inter",
                            fontSize: "20px",
                        }}>
                        <Decoration1></Decoration1>
                        <Box sx={{ display: "inline", ml: "12px" }}>
                            Company Parameters
                        </Box>
                    </Typography>
                    <Typography ml="30px" variant="h5" color={colors.grey[400]}>
                        Configure company operational parameters
                    </Typography>
                </Box>
                <Box sx={{ height: "58px" }}>
                    <IconButton onClick={() => toggleParametersDrawer()}>
                        <HighlightOffIcon />
                    </IconButton>
                </Box>
            </Box>

            <Formik
                onSubmit={(values, { setSubmitting }) => {
                    console.log("Submitting values: ", values);
                    saveParameters(values, setSubmitting);
                }}
                initialValues={
                    isNotFirstTime
                        ? {
                              ...initialValuesNonEmpty,
                              costofequitycapital: costOfEquity.toString(),
                          }
                        : {
                              ...initialValuesEmpty,
                              costofequitycapital: costOfEquity.toString(),
                          }
                }
                validationSchema={
                    isNotFirstTime ? paramNotEmptySchema : paramEmptySchema
                }>
                {({
                    values,
                    errors,
                    touched,
                    resetForm,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form style={{ height: "90vh" }} onSubmit={handleSubmit}>
                        <Box
                            height="80%"
                            display="flex"
                            flexDirection="column"
                            columnGap="20px"
                            rowGap="20px"
                            overflow="auto"
                            padding="24px 16px"
                            sx={{
                                "& 	.MuiFilledInput-root:after": {
                                    borderBottomColor: "#2595FF",
                                },
                                "& 	.Mui-focused": {
                                    borderBottomColor: "#2595FF",
                                    color: `${colors.grey[100]} !important`,
                                },
                            }}>
                            <Box fontSize={isNonMobile ? "14px" : "13px"}>
                                {isNotFirstTime ? (
                                    <React.Fragment>
                                        {values.parameter === "Tax Rate" && (
                                            <p>
                                                Select the rate at which your
                                                company's revenue will be taxed
                                                each year.
                                            </p>
                                        )}
                                        {values.parameter ===
                                            "Dividends Rate" && (
                                            <p>
                                                Select what percentage of your
                                                revenue you need to give as
                                                dividends each year.
                                            </p>
                                        )}
                                        {values.parameter ===
                                            "Royalties Rate" && (
                                            <p>
                                                Select what percentage of your
                                                revenue you need to pay out as
                                                royalties.
                                            </p>
                                        )}
                                        {!values.parameter && (
                                            <p>Please select an option.</p>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        Please configure all the following
                                        parameters{" "}
                                    </React.Fragment>
                                )}
                            </Box>

                            {/* <Box width='100%' columnGap='16px' display='flex'> */}

                            {/* </Box> */}
                            {isNotFirstTime ? (
                                <>
                                    <TextField
                                        id="costofequitycapital"
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={"Cost of Equity Capital "}
                                        size="small"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.costofequitycapital}
                                        name="costofequitycapital"
                                        error={
                                            !!touched.costofequitycapital &&
                                            !!errors.costofequitycapital
                                        }
                                        helperText={
                                            touched.costofequitycapital &&
                                            errors.costofequitycapital
                                        }
                                    />

                                    <TextField
                                        fullWidth
                                        select
                                        variant="filled"
                                        type="text"
                                        label="Parameter"
                                        size="small"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange(e);
                                            handleSelectChange(
                                                e,
                                                resetForm,
                                                values.costofequitycapital
                                            );
                                        }}
                                        value={values.parameter}
                                        name="parameter"
                                        error={
                                            !!touched.parameter &&
                                            !!errors.parameter
                                        }
                                        helperText={
                                            touched.parameter &&
                                            errors.parameter
                                        }>
                                        <MenuItem value="Tax rate">
                                            Tax Rate
                                        </MenuItem>
                                        <MenuItem value="Dividend payout ratio">
                                            Dividends Rate
                                        </MenuItem>
                                        <MenuItem value="Royalty Rate">
                                            Royalties Rate
                                        </MenuItem>
                                    </TextField>

                                    {values.parameter !== "" && (
                                        <>
                                            <TextField
                                                fullWidth
                                                id="param0"
                                                variant="filled"
                                                type="text"
                                                label={`${values.parameter} for ${companyFoundingYear}`}
                                                size="small"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.param0}
                                                name="param0"
                                                error={
                                                    !!touched.param0 &&
                                                    !!errors.param0
                                                }
                                                helperText={
                                                    touched.param0 &&
                                                    errors.param0
                                                }
                                            />
                                            <TextField
                                                id="param1"
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label={`${
                                                    values.parameter
                                                } for ${
                                                    companyFoundingYear + 1
                                                }`}
                                                size="small"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.param1}
                                                name="param1"
                                                error={
                                                    !!touched.param1 &&
                                                    !!errors.param1
                                                }
                                                helperText={
                                                    touched.param1 &&
                                                    errors.param1
                                                }
                                            />
                                            <TextField
                                                id="param2"
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label={`${
                                                    values.parameter
                                                } for ${
                                                    companyFoundingYear + 2
                                                }`}
                                                size="small"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.param2}
                                                name="param2"
                                                error={
                                                    !!touched.param2 &&
                                                    !!errors.param2
                                                }
                                                helperText={
                                                    touched.param2 &&
                                                    errors.param2
                                                }
                                            />
                                            <TextField
                                                id="param3"
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label={`${
                                                    values.parameter
                                                } for ${
                                                    companyFoundingYear + 3
                                                }`}
                                                size="small"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.param3}
                                                name="param3"
                                                error={
                                                    !!touched.param3 &&
                                                    !!errors.param3
                                                }
                                                helperText={
                                                    touched.param3 &&
                                                    errors.param3
                                                }
                                            />
                                            <TextField
                                                id="param4"
                                                fullWidth
                                                variant="filled"
                                                type="text"
                                                label={`${
                                                    values.parameter
                                                } for ${
                                                    companyFoundingYear + 4
                                                }`}
                                                size="small"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.param4}
                                                name="param4"
                                                error={
                                                    !!touched.param4 &&
                                                    !!errors.param4
                                                }
                                                helperText={
                                                    touched.param4 &&
                                                    errors.param4
                                                }
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {" "}
                                    {accordions.map((accordion, index) => (
                                        <Accordion
                                            key={accordion}
                                            sx={{
                                                borderWidth: "1px",
                                                borderStyle: "solid",
                                                // marginBottom: "16px",
                                                borderRadius: "8px",

                                                boxShadow: "none", // Remove box shadow

                                                borderColor:
                                                    colors.sideBorder[100],
                                                backgroundColor:
                                                    colors.backgroundColor[100],
                                                "&:before": {
                                                    display: "none",
                                                },
                                                "&.Mui-expanded": {
                                                    margin: "0px",
                                                },
                                            }}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}>
                                                <Typography>
                                                    {" "}
                                                    {accordion}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Divider
                                                    sx={{
                                                        ml: "0px",
                                                        mr: "0px",
                                                        mb: "16px",
                                                    }}
                                                    variant="middle"
                                                />
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        rowGap: "16px",
                                                    }}>
                                                    {accordion ===
                                                        "Tax Rate" && (
                                                        <>
                                                            {Array.from(
                                                                { length: 5 },
                                                                (_, index) => (
                                                                    <TextField
                                                                        fullWidth
                                                                        variant="filled"
                                                                        type="text"
                                                                        label={`Year ${
                                                                            index +
                                                                            companyFoundingYear
                                                                        }`}
                                                                        size="small"
                                                                        onBlur={
                                                                            handleBlur
                                                                        }
                                                                        onChange={
                                                                            handleChange
                                                                        }
                                                                        value={
                                                                            values[
                                                                                `taxRate${index}`
                                                                            ]
                                                                        }
                                                                        name={`taxRate${index}`}
                                                                        error={
                                                                            !!touched[
                                                                                `taxRate${index}`
                                                                            ] &&
                                                                            !!errors[
                                                                                `taxRate${index}`
                                                                            ]
                                                                        }
                                                                        helperText={
                                                                            touched[
                                                                                `taxRate${index}`
                                                                            ] &&
                                                                            errors[
                                                                                `taxRate${index}`
                                                                            ]
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {accordion ===
                                                        "Royalty Rate" && (
                                                        <>
                                                            {Array.from(
                                                                { length: 5 },
                                                                (_, index) => (
                                                                    <TextField
                                                                        fullWidth
                                                                        variant="filled"
                                                                        type="text"
                                                                        label={`Year ${
                                                                            index +
                                                                            companyFoundingYear
                                                                        }`}
                                                                        size="small"
                                                                        onBlur={
                                                                            handleBlur
                                                                        }
                                                                        onChange={
                                                                            handleChange
                                                                        }
                                                                        value={
                                                                            values[
                                                                                `royaltyRate${index}`
                                                                            ]
                                                                        }
                                                                        name={`royaltyRate${index}`}
                                                                        error={
                                                                            !!touched[
                                                                                `royaltyRate${index}`
                                                                            ] &&
                                                                            !!errors[
                                                                                `royaltyRate${index}`
                                                                            ]
                                                                        }
                                                                        helperText={
                                                                            touched[
                                                                                `royaltyRate${index}`
                                                                            ] &&
                                                                            errors[
                                                                                `royaltyRate${index}`
                                                                            ]
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {accordion ===
                                                        "Divident payout ratio" && (
                                                        <>
                                                            {Array.from(
                                                                { length: 5 },
                                                                (_, index) => (
                                                                    <TextField
                                                                        fullWidth
                                                                        variant="filled"
                                                                        type="text"
                                                                        label={`Year ${
                                                                            index +
                                                                            companyFoundingYear
                                                                        }`}
                                                                        size="small"
                                                                        onBlur={
                                                                            handleBlur
                                                                        }
                                                                        onChange={
                                                                            handleChange
                                                                        }
                                                                        value={
                                                                            values[
                                                                                `dividendPayoutRatio${index}`
                                                                            ]
                                                                        }
                                                                        name={`dividendPayoutRatio${index}`}
                                                                        error={
                                                                            !!touched[
                                                                                `dividendPayoutRatio${index}`
                                                                            ] &&
                                                                            !!errors[
                                                                                `dividendPayoutRatio${index}`
                                                                            ]
                                                                        }
                                                                        helperText={
                                                                            touched[
                                                                                `dividendPayoutRatio${index}`
                                                                            ] &&
                                                                            errors[
                                                                                `dividendPayoutRatio${index}`
                                                                            ]
                                                                        }
                                                                        key={
                                                                            index
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                    <TextField
                                        id="costofequitycapital"
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={"Cost of Equity Capital "}
                                        size="small"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.costofequitycapital}
                                        name="costofequitycapital"
                                        error={
                                            !!touched.costofequitycapital &&
                                            !!errors.costofequitycapital
                                        }
                                        helperText={
                                            touched.costofequitycapital &&
                                            errors.costofequitycapital
                                        }
                                    />
                                </>
                            )}
                        </Box>

                        <Box
                            sx={{
                                borderTop: "1px solid",
                                borderTopColor: colors.sideBorder[100],
                                pt: "18px",
                                pr: "16px",
                                mb: "16px",
                            }}
                            display="flex"
                            gap="8px"
                            justifyContent="end"
                            // mt={isNonMobile ? "50px" : "10px"}
                        >
                            <Button
                                onClick={() => toggleParametersDrawer()}
                                variant="outlined"
                                size={isNonMobile ? "large" : "medium"}
                                pr="80px"
                                disabled={isSubmitting}
                                sx={{
                                    color: colors.grey[100],
                                    fontWeight: "medium",
                                    borderColor: colors.sideBorder[100],
                                }}>
                                Cancel
                            </Button>
                            <Button
                                startIcon={isSubmitting ? null : <AddIcon />}
                                size={isNonMobile ? "large" : "medium"}
                                type="submit"
                                variant="contained"
                                color="buttonColor"
                                disabled={isSubmitting}
                                sx={{
                                    backgroundColor: "#2595FF",
                                    color: "#FFFFFF",
                                    // fontFamily: "Arial",
                                    // fontSize: "16px",
                                    // fontWeight: "bold",
                                }}>
                                {isSubmitting ? (
                                    <CircularProgress
                                        size={24}
                                        style={{ color: colors.grey[100] }}
                                        // color="primary"
                                    />
                                ) : (
                                    "Submit"
                                )}
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Drawer>
    );
};

export default ParametersDrawer;
