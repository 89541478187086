import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
const ToolbarReport = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <GridToolbarContainer
      sx={{
        "& .MuiButton-text": {
          fontSize: "12px",
          // color: colors.buttonColor[100],
        },
        "& .MuiButton-text:hover": {
          color: colors.buttonColor[100] + " !important",
          backgroundColor: "transparent!important",
        },
      }}
    >
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />

      <GridToolbarExport
        csvOptions={{
          fileName: "data_export.csv",
          delimiter: ",",
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );
};

export default ToolbarReport;
