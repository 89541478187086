import * as yup from "yup";
export const initialOtherRevValues = {
  revenue0: "",
  revenue1: "",
  revenue2: "",
  revenue3: "",
  revenue4: "",
  description: "",
};

export const initialOtherRevSchema = yup.object().shape({
  revenue0: yup
    .number()
    .typeError("Price must be a valid number")
    .min(0, "Price must be a positive number")

    .required("Field is required"),
  revenue1: yup
    .number()
    .typeError("Price must be a valid number")
    .min(0, "Price must be a positive number")

    .required("Field is required"),
  revenue2: yup
    .number()
    .typeError("Price must be a valid number")
    .min(0, "Price must be a positive number")

    .required("Field is required"),
  revenue3: yup
    .number()
    .typeError("Price must be a valid number")
    .min(0, "Price must be a positive number")

    .required("Field is required"),
  revenue4: yup
    .number()
    .typeError("Price must be a valid number")
    .min(0, "Price must be a positive number")

    .required("Field is required"),
  description: yup
    .string("Input must be a String")
    .required("Field is required"),
});

export const revSchema = yup.object().shape({
  description: yup
    .string("Input must be a String")
    .required("Field is required"),
  quantity0: yup
    .number()
    .typeError("Price must be a valid number")
    .min(0, "Price must be a positive number")

    .required("Field is required"),
  sellingprice0: yup
    .number()
    .typeError("Price must be a valid number")
    .min(0, "Price must be a positive number")

    .required("Field is required"),
  quantity1: yup
    .number()
    .typeError("Price must be a valid number")
    .min(0, "Price must be a positive number")

    .required("Field is required"),
  sellingprice1: yup
    .number()
    .typeError("Price must be a valid number")
    .min(0, "Price must be a positive number")

    .required("Field is required"),
  quantity2: yup
    .number()
    .typeError("Price must be a valid number")
    .min(0, "Price must be a positive number")

    .required("Field is required"),
  sellingprice2: yup
    .number()
    .typeError("Price must be a valid number")
    .min(0, "Price must be a positive number")

    .required("Field is required"),
  quantity3: yup
    .number()
    .typeError("Price must be a valid number")
    .min(0, "Price must be a positive number")

    .required("Field is required"),
  sellingprice3: yup
    .number()
    .typeError("Price must be a valid number")
    .min(0, "Price must be a positive number")

    .required("Field is required"),
  quantity4: yup
    .number()
    .typeError("Price must be a valid number")
    .min(0, "Price must be a positive number")

    .required("Field is required"),
  sellingprice4: yup
    .number()
    .typeError("Price must be a valid number")
    .min(0, "Price must be a positive number")

    .required("Field is required"),
});
export const initialRevValues = {
  description: "",
  quantity0: "",
  sellingprice0: "",
  quantity1: "",
  sellingprice1: "",
  quantity2: "",
  sellingprice2: "",
  quantity3: "",
  sellingprice3: "",
  quantity4: "",
  sellingprice4: "",
};
