import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from "./CustomToolbar";
import BarChart from "./BarChart";
import { tokens } from "../theme";
import { tableStyling, tabStyling } from "../globalStyling/tablesStyling";
import { Box, Tab, useTheme, Typography, IconButton } from "@mui/material";
import {
  materialsColumns,
  merchandiseColumns,
  merchandiseGroupingModel,
  productsColumns,
  productsGroupingModel,
} from "../data/tableColumns";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import NoRowsOverlay from "./NoRowsOverlay";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import LoadingSkeleton from "./LoadingSkeleton";

const ProductsAndMerchTables = ({
  mockMerchandiseData,
  toggleMerchDrawer,
  mockMaterialsData,
  mockProductsData,
  toggleMaterialsDrawer,
  toggleProductsDrawer,
  productColumnsDynamic,
  productsGroupingModelDynamic,
  loadingMaterialsData,
  loadingProductsData,
  loadingMerchandiseData,
  barChartData,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = useState("4");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // styling for all the tables
  const tableStyles = tableStyling(colors);
  //   Styling for the tabs
  const tabStyles = tabStyling(colors);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const CustomNoRowsOverlay = (props) => {
    const { message, functionDrawer } = props;
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          backgroundColor: "transparent",
          position: "absolute",
          zIndex: 10,
        }}
      >
        <Typography sx={{ mb: "16px", maxWidth: "500px", fontSize: "12px" }}>
          {message}
        </Typography>
        <IconButton
          onClick={functionDrawer}
          aria-label='delete'
          sx={{
            backgroundColor: colors.buttonBackground[100],
            color: colors.buttonTextColor[100],
            zIndex: 1,
            border: "1px solid",
            borderColor: colors.buttonColor[100],
          }}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    );
  };

  return (
    <TabContext value={value}>
      <Box sx={tabStyles}>
        <TabList
          variant='scrollable'
          onChange={handleChange}
          aria-label='lab API tabs example'
          // textColor='secondary'
          // indicatorColor='secondary'
        >
          <Tab label='Products and Raw Materials' value='4' />
          <Tab label='Merchandise' value='5' />
        </TabList>
      </Box>
      <TabPanel value='4'>
        <Box
          m='0 0 0 0'
          display='flex'
          flexDirection='column'
          gap={8}
          width='100%'
          sx={tableStyles}
        >
          {/* Establishment Costs array */}
          <Box gap={2} display='flex' flexWrap='wrap'>
            <Box flex={"1 0 430px;"} height='350px' position='relative'>
              <Typography
                variant='h4'
                color={colors.grey[100]}
                fontWeight='bold'
                sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}
              >
                Cost per Unit
              </Typography>
              <Box
                sx={{
                  height: width < 1059 ? "90%" : "100%",
                  borderRadius: "4px",
                  backgroundColor: "transparent",
                  border: "1px solid ",
                  width: "100%",
                  display: "flex",
                  position: "absolute",

                  borderColor: colors.sideBorder[100],
                }}
              >
                <BarChart data={barChartData} />
              </Box>
            </Box>

            <Box flex={"1 0 430px;"} height='350px'>
              <Typography
                variant='h4'
                color={colors.grey[100]}
                fontWeight='bold'
                sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}
              >
                Raw Materials and Variable Cost
              </Typography>
              <DataGrid
                density='compact'
                loading={loadingMaterialsData}
                rows={mockMaterialsData}
                components={{
                  NoRowsOverlay: (renderProps) => (
                    <CustomNoRowsOverlay
                      functionDrawer={toggleMaterialsDrawer}
                      message='Here you can add your raw materials use in the production processes of your company'
                      {...renderProps}
                    />
                  ),
                  LoadingOverlay: LoadingSkeleton,
                  Toolbar: () => (
                    <CustomToolbar onClickAdd={toggleMaterialsDrawer} />
                  ), //

                  CustomToolbar,
                }}
                sortModel={[
                  {
                    field: "name",
                    sort: "asc",
                  },
                ]}
                columns={materialsColumns}
              />
            </Box>
          </Box>
          <Box mb={1} height='500px'>
            <Typography
              variant='h4'
              color={colors.grey[100]}
              fontWeight='bold'
              sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}
            >
              Products
            </Typography>
            <DataGrid
              density='compact'
              loading={loadingProductsData}
              experimentalFeatures={{ columnGrouping: true }}
              columnGroupingModel={productsGroupingModelDynamic}
              rows={mockProductsData}
              components={{
                NoRowsOverlay: (renderProps) => (
                  <CustomNoRowsOverlay
                    functionDrawer={toggleProductsDrawer}
                    message='Here you can add your products and services that your company offers'
                    {...renderProps}
                  />
                ),
                LoadingOverlay: LoadingSkeleton,
                Toolbar: () => (
                  <CustomToolbar onClickAdd={toggleProductsDrawer} />
                ), //

                CustomToolbar,
              }}
              columns={productColumnsDynamic}
            />
          </Box>
        </Box>
      </TabPanel>
      <TabPanel value='5'>
        <Box m='0 0 0 0' height='60vh' width='100%' sx={tableStyles}>
          <Typography
            variant='h4'
            color={colors.grey[100]}
            fontWeight='bold'
            sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}
          >
            Merchandise Details
          </Typography>
          {/* Merchandise  array */}
          <DataGrid
            sortModel={[
              {
                field: "name",
                sort: "asc",
              },
            ]}
            density='compact'
            loading={loadingMerchandiseData}
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={merchandiseGroupingModel}
            rows={mockMerchandiseData}
            components={{
              NoRowsOverlay: (renderProps) => (
                <CustomNoRowsOverlay
                  functionDrawer={toggleMerchDrawer}
                  message='Here you can add your merchandise that your company offers for sale'
                  {...renderProps}
                />
              ),
              LoadingOverlay: LoadingSkeleton,
              Toolbar: () => <CustomToolbar onClickAdd={toggleMerchDrawer} />, //

              CustomToolbar,
            }}
            columns={merchandiseColumns}
          />
        </Box>
      </TabPanel>
    </TabContext>
  );
};

export default ProductsAndMerchTables;
