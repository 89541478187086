import {
    Box,
    Button,
    Drawer,
    IconButton,
    TextField,
    Typography,
    Tab,
    ButtonGroup,
} from "@mui/material";
import ToolbarReport2 from "../../components/ToolbarReport2";
import LoadingSkeleton from "../../components/LoadingSkeleton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { DataGrid, NoRowsOverlay } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../../theme";
import { useState, useEffect } from "react";
import NoRowsOverlayhello from "../../components/NoRowsOverlay";
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file
import { tableStyling } from "../../globalStyling/tablesStyling";
import Header from "../../components/Header";
import WorkingCapitalDrawer from "./Drawer";
import urls from "../../data/cloudFunctionUrls";
import auth from "../../firebase/firebase";
import initialRows from "./initialRows.json";

const titleDict = {
    "Accounts Receivable": "customerCreditsPerYear",
    "Required Αvailable": "requiredAvailableFundsPerYear",
    "Inventory (finished products)": "finishedGoodsInventoryPerYear",
    "Inventory (goods)": "merchandiseInventoryPerYear",
    "Inventory (raw materials)": "rawMaterialInventoryPerYear",
    Total: "totalPerYear",
    "Minus Expenses": "minusSupplierCreditsPerYear",
    "Required Working Capital": "workingCapitalRequiredPerYear",
};

const titleDict2DB = {
    "Raw material inventory days": "inventoryDays",
    "Merchandise inventory days": "salesDays",
    "Finished goods inventory days": "finishedGoodsDays",
    "Days of coverage for operating expenses": "expensesDays",
    "Customer credit days": "accountsReceivableDays",
    "Supplier credit days": "accountsPayableDays",
};

const objectToTitleWithContent = (obj, id, category) => {
    const row = {
        id: id,
        category: category || "",
        year0: obj?.["0"].toFixed() || null,
        year1: obj?.["1"].toFixed() || null,
        year2: obj?.["2"].toFixed() || null,
        year3: obj?.["3"].toFixed() || null,
        year4: obj?.["4"].toFixed() || null,
        header: true,
    };

    return row;
};

const objectToRow = (obj, id, category) => {
    const row = {
        id: id,
        category: category || "",
        year0: obj?.["0"].toFixed() || null,
        year1: obj?.["1"].toFixed() || null,
        year2: obj?.["2"].toFixed() || null,
        year3: obj?.["3"].toFixed() || null,
        year4: obj?.["4"].toFixed() || null,
        header: false,
    };

    return row;
};

const WorkingCapital = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const companyFoundingYear = companyFoundingYearGetter();

    const ratiosGroupingModel = [
        {
            groupId: "Years",
            children: [
                { field: "year0" },
                { field: "year1" },
                { field: "year2" },
                { field: "year3" },
                { field: "year4" },
            ],
        },
    ];

    const columns = [
        {
            field: "category",
            headerName: "",
            width: 200,

            sortable: false,
        },
        ,
        {
            field: "year0",
            headerName: String(companyFoundingYear),
            flex: 0.7,
            minWidth: 155,
            sortable: false,
        },
        {
            field: "year1",
            headerName: String(companyFoundingYear + 1),
            flex: 0.7,
            minWidth: 155,

            sortable: false,
        },
        {
            field: "year2",
            headerName: String(companyFoundingYear + 2),
            flex: 0.7,
            minWidth: 155,

            sortable: false,
        },
        {
            field: "year3",
            headerName: String(companyFoundingYear + 3),
            flex: 0.7,
            minWidth: 155,

            sortable: false,
        },
        {
            field: "year4",
            headerName: String(companyFoundingYear + 4),
            flex: 0.7,
            minWidth: 155,

            sortable: false,
        },
        // add more columns here
    ];
    const tableStyles = tableStyling(colors);

    const [workingCapitalDrawerOpen, setWorkingCapitalDrawerOpen] =
        useState(false);
    const toggleWorkingCapitalDrawer = () => {
        setWorkingCapitalDrawerOpen(!workingCapitalDrawerOpen);
    };

    const [refreshWorkingCapitalData, setRefreshWorkingCapitalData] =
        useState(false);

    const toggleRefreshWorkingCapitalData = () => {
        setRefreshWorkingCapitalData(!refreshWorkingCapitalData);
    };

    const saveNewWCParameters = (values, setSubmitting) => {
        const parametersArray = Object.keys(titleDict2DB).map((key) => {
            return {
                type: key,
                value: values[titleDict2DB[key]],
            };
        });

        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/setworkingcapitalparameters", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify({
                    parameters: parametersArray,
                }),
            })
                .then((response) => {
                    if (response.status === 200) {
                        toggleRefreshWorkingCapitalData();
                        toggleWorkingCapitalDrawer();
                    }
                })
                .catch((error) => {
                    alert("An error occured. Please try again later.");
                    setSubmitting(false);
                    console.log(error);
                });
        });
    };

    function getRowClassName(params) {
        if (params.row.header) {
            return theme.palette.mode === "dark"
                ? "title-row-dark"
                : "title-row";
        }
    }
    // const [ratioData, setRatioData] = useState(initialRows);
    const [ratioData, setRatioData] = useState([]);
    const [emptyParams, setEmptyParams] = useState(true);
    const [params, setParams] = useState({});
    const [loading, setLoading] = useState(true);
    const [insufficientData, setInsufficientData] = useState(false);

    useEffect(() => {
        let paramsExist = false;

        setInsufficientData(false);

        auth.currentUser.getIdToken().then((token) => {
            fetch(urls.importAPI + "/getworkingcapitalparameters", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else if (response.status === 404) {
                        setEmptyParams(true);
                        setLoading(false);
                        throw new Error("No parameters found");
                    }
                })
                .then((data) => {
                    // setLoading(false);
                    if (data?.parameters && data.parameters.length !== 0) {
                        if (data.parameters !== params) {
                            setParams(data.parameters);
                            // toggleRefreshWorkingCapitalData();
                        }
                        setEmptyParams(false);
                        paramsExist = true;
                    } else {
                        setEmptyParams(true);
                    }
                })
                .then(() => {
                    console.log("paramsExist", paramsExist);
                    // This is the function that will update the values of the working capital
                    if (paramsExist)
                        auth.currentUser.getIdToken().then((token) => {
                            fetch(
                                urls.reportAPI +
                                    "/getCompanyWorkingCapitalAggregations",
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: "Bearer " + token,
                                    },
                                }
                            )
                                .then((response) => {
                                    if (response.status === 200) {
                                        return response.json();
                                    } else if (response.status === 404) {
                                        setInsufficientData(true);
                                        setLoading(false);
                                        return response.json();
                                    }
                                })
                                .then((data) => {
                                    setLoading(false);

                                    // console.log(Object.keys(data.params).length);
                                    console.log("data", data);
                                    if (
                                        !data?.params ||
                                        Object.keys(data.params).length === 0
                                    ) {
                                        setEmptyParams(true);
                                    } else {
                                        setEmptyParams(false);
                                        setParams(data.params);
                                        data = Object.keys(titleDict).map(
                                            (key, index) => {
                                                if (
                                                    [
                                                        "Required Working Capital",
                                                        "Total",
                                                    ].includes(key)
                                                ) {
                                                    return objectToTitleWithContent(
                                                        data[titleDict[key]],
                                                        index,
                                                        key
                                                    );
                                                } else {
                                                    return objectToRow(
                                                        data[titleDict[key]],
                                                        index,
                                                        key
                                                    );
                                                }
                                            }
                                        );
                                        setRatioData(data);
                                    }
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        });
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }, [refreshWorkingCapitalData]);

    const CustomNoRowsOverlay = () => {
        return emptyParams ? (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    position: "absolute",
                    zIndex: 10,
                }}>
                <Typography sx={{ mb: "16px", maxWidth: "500px" }}>
                    Before you get a working capital report, you have to define
                    a parameter list
                </Typography>
                <IconButton
                    onClick={toggleWorkingCapitalDrawer}
                    aria-label="delete"
                    sx={{
                        backgroundColor: colors.buttonBackground[100],
                        color: colors.buttonTextColor[100],
                        zIndex: 1,
                        border: "1px solid",
                        borderColor: colors.buttonColor[100],
                    }}>
                    <ArrowForwardIcon />
                </IconButton>
            </Box>
        ) : insufficientData ? (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    position: "absolute",
                    zIndex: 10,
                }}>
                <Typography sx={{ mb: "16px", maxWidth: "500px" }}>
                    Before generating the Working Capital Report, you need to
                    provide Revenue and Costs Company Data
                </Typography>
            </Box>
        ) : (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "transparent",
                    position: "absolute",
                    zIndex: 10,
                }}>
                <Typography sx={{ mb: "16px", maxWidth: "500px" }}>
                    No rows
                </Typography>
                <IconButton
                    onClick={toggleWorkingCapitalDrawer}
                    aria-label="delete"
                    sx={{
                        backgroundColor: colors.buttonBackground[100],
                        color: colors.buttonTextColor[100],
                        zIndex: 1,
                        border: "1px solid",
                        borderColor: colors.buttonColor[100],
                    }}>
                    <ArrowForwardIcon />
                </IconButton>
            </Box>
        );
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box height="500px" sx={tableStyles}>
                <Typography
                    variant="h4"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}>
                    Working Capital Details
                </Typography>
                <DataGrid
                    loading={loading}
                    rows={!emptyParams || !insufficientData ? ratioData : []}
                    density="compact"
                    columns={columns}
                    experimentalFeatures={{ columnGrouping: true }}
                    columnGroupingModel={ratiosGroupingModel}
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        LoadingOverlay: LoadingSkeleton,
                        // noRowsOverlay: () => <div>No Rows hello</div>,
                        Toolbar: () => (
                            <ToolbarReport2
                                editDrawer={toggleWorkingCapitalDrawer}
                            />
                        ), //

                        ToolbarReport2,
                    }}
                    getRowClassName={getRowClassName}
                />
            </Box>
            <WorkingCapitalDrawer
                params={params}
                workingCapitalDrawerOpen={workingCapitalDrawerOpen}
                toggleWorkingCapitalDrawer={toggleWorkingCapitalDrawer}
                saveNewWCParameters={saveNewWCParameters}
            />
        </Box>
    );
};

export default WorkingCapital;
