import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Tab,
  IconButton,
} from "@mui/material";
import Header from "../../components/Header";
import CustomToolbar from "../../components/CustomToolbar";
import { tokens } from "../../theme";
import { DataGrid } from "@mui/x-data-grid";
import LoadingSkeleton from "../../components/LoadingSkeleton";
import { tableStyling, tabStyling } from "../../globalStyling/tablesStyling";
import PieChart from "../../components/PieChart";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import WorkingCapital from "../workingcapital";
import {
  fundingStructureColumns,
  loanDetailsColumns,
} from "../../data/tableColumns";
import urls from "../../data/cloudFunctionUrls";
import auth from "../../firebase/firebase";

import LoanDetailsDrawer from "./LoanDetailsDrawer";
import FundingDetailsDrawer from "./FundingDetailsDrawer";

import companyFoundingYearGetter from "../../data/companyFoundingYear";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const companyFoundingYear = companyFoundingYearGetter();

const Funding = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const tableStyles = tableStyling(colors);
  const [loanDetailsDrawerOpen, setLoanDetailsDrawerOpen] = useState(false);
  const tabStyles = tabStyling(colors);
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const CustomNoRowsOverlay = (props) => {
    const { message, functionDrawer } = props;
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          backgroundColor: "transparent",
          position: "absolute",
          zIndex: 10,
        }}
      >
        <Typography sx={{ mb: "16px", maxWidth: "500px", fontSize: "12px" }}>
          {message}
        </Typography>
        <IconButton
          onClick={functionDrawer}
          aria-label='delete'
          sx={{
            backgroundColor: colors.buttonBackground[100],
            color: colors.buttonTextColor[100],
            zIndex: 1,
            border: "1px solid",
            borderColor: colors.buttonColor[100],
          }}
        >
          <ArrowForwardIcon />
        </IconButton>
      </Box>
    );
  };

  const toggleLoanDetailsDrawer = (e) => {
    setLoanDetailsDrawerOpen(!loanDetailsDrawerOpen);
  };

  // Remote initial capital data ---------------------
  const [initialCapital, setInitialCapital] = useState([]);

  const [refreshInitialCapitalData, setRefreshInitialCapitalData] =
    useState(false);

  const [fetchingInitialCapitalData, setFetchingInitialCapitalData] =
    useState(true);

  const [initialCapitalPercentages, setInitialCapitalPercentages] = useState(
    []
  );

  const toggleRefreshInitialCapitalData = () => {
    setRefreshInitialCapitalData(!refreshInitialCapitalData);
  };

  useEffect(() => {
    auth.currentUser.getIdToken().then((token) => {
      fetch(urls.importAPI + "/getinitialcapital", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
        })
        .then((data) => {
          setFetchingInitialCapitalData(false);
          const percentages = data.initialFundPercentages;
          data = data.initial_funds.map((item, index) => {
            item.percentage = percentages[item.type];

            return {
              id: index,
              dbid: item.dbid,
              category: item.type,
              amount: item.amount,
              participation: parseFloat(item.percentage * 100).toFixed() + "%",
              refresh: refreshInitialCapitalData,
              toggleRefresh: toggleRefreshInitialCapitalData,
            };
          });
          setInitialCapital(data);
          let capitalPercentages = [];
          data.forEach((item) => {
            item.percentage = percentages[item.category];
            if (item.amount > 0) {
              capitalPercentages.push({
                id: item.category,
                label: item.category,
                value: parseFloat(item.percentage * 100).toFixed(),
              });
            }
          });
          console.log("final data", capitalPercentages);
          setInitialCapitalPercentages(capitalPercentages);
        })
        .catch((err) => {
          console.log(err);
          setFetchingInitialCapitalData(false);
        });
    });
  }, [refreshInitialCapitalData]);

  // ---------------------------------

  // Remote loan details data ---------------------
  const [loanDetails, setLoanDetails] = useState([]);
  const [refreshLoanDetailsData, setRefreshLoanDetailsData] = useState(false);
  const [fetchingLoanDetailsData, setFetchingLoanDetailsData] = useState(true);

  const toggleRefreshLoanDetailsData = () => {
    setRefreshLoanDetailsData(!refreshLoanDetailsData);
  };

  useEffect(() => {
    auth.currentUser.getIdToken().then((token) => {
      fetch(urls.importAPI + "/getloans", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
        })
        .then((data) => {
          data = data.loans;
          setFetchingLoanDetailsData(false);
          data = data.map((item, index) => {
            item.date = new Date(
              companyFoundingYear + item.loan_year,
              item.loan_month - 1,
              item.loan_day
            );

            item.date = item.date
              .toLocaleDateString("en-GB", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
              })
              .split("/")
              // .reverse()
              .join("/");
            return {
              id: index,
              dbid: item.dbid,
              description: item.description,
              amount: item.amount,
              interest:
                parseFloat(item.annual_interest_rate * 100).toFixed(2) + "%",
              years: item.repayment_years,
              date: item.date,
              paymentsnumber: item.annual_payment_count,

              toggleRefresh: () => {
                toggleRefreshLoanDetailsData();
                toggleRefreshInitialCapitalData();
              },
            };
          });
          setLoanDetails(data);
        })
        .catch((err) => {
          console.log(err);
          setFetchingLoanDetailsData(false);
        });
    });
  }, [refreshLoanDetailsData]);

  // ---------------------------------
  const saveNewFundingDetails = (values, setSubmitting) => {
    auth.currentUser.getIdToken().then((token) => {
      fetch(urls.importAPI + "/addinitialcapital", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          initial_fund: {
            type: values.category,
            amount: values.investmentvalue,
          },
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            toggleRefreshInitialCapitalData();
            toggleFundingStructureDrawer();
          } else if (res.status === 400) {
            setSubmitting(false);
            alert("You have already added a record of this category.");
          }
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
        });
    });
  };

  // ---------------------------------

  const saveNewLoanDetails = (values, setSubmitting) => {
    const loan_date = values.date;
    const loan_date_split = loan_date.split("-");
    values.loan_year = parseInt(loan_date_split[0] - companyFoundingYear);
    values.loan_month = parseInt(loan_date_split[1]);
    values.loan_day = parseInt(loan_date_split[2]);

    auth.currentUser.getIdToken().then((token) => {
      fetch(urls.importAPI + "/addloan", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          loan: {
            description: values.description,
            amount: values.loanamount,
            annual_interest_rate: values.annualinterest / 100,
            repayment_years: values.paymentyears,
            annual_payment_count: values.paymentsinyear,
            loan_year: values.loan_year,
            loan_month: values.loan_month,
            loan_day: values.loan_day,
          },
        }),
      })
        .then((res) => {
          if (res.status === 200) {
            toggleRefreshLoanDetailsData();
            toggleRefreshInitialCapitalData();
            toggleLoanDetailsDrawer();
          } else if (res.status === 400) {
            setSubmitting(false);
            alert("You have already added a record of this lender.");
          }
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
        });
    });
  };

  const [fundingStructureDrawerOpen, setFundingStructureDrawerOpen] =
    useState(false);
  const toggleFundingStructureDrawer = (e) => {
    setFundingStructureDrawerOpen(!fundingStructureDrawerOpen);
  };
  //   Styling for the tabs
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box m='20px 20px 50px 20px'>
      <Header title='Funding' subtitle="Your company's funding" />
      <TabContext value={value}>
        <Box sx={tabStyles}>
          <TabList
            variant='scrollable'
            onChange={handleChange}
            aria-label='lab API tabs example'
            // textColor='secondary'
            // indicatorColor='secondary'
          >
            <Tab label='Financial Capital' value='1' />
            <Tab label='Working Capital' value='2' />
          </TabList>
        </Box>
        <TabPanel value='1'>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "54px",
              mb: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "24px",
                flexWrap: "wrap",

                // flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Box
                flex={"1 0 430px;"}
                mb={4}
                height='350px'
                position='relative'
              >
                {" "}
                <Typography
                  variant='h4'
                  color={colors.grey[100]}
                  fontWeight='bold'
                  sx={{
                    m: "0 0 16px 0",
                    fontFamily: "Inter",
                  }}
                >
                  Initial Capital Breakdown
                </Typography>
                <Box
                  sx={{
                    height: "100%",
                    borderRadius: "4px",
                    backgroundColor: "transparent",
                    position: "absolute",
                    border: "1px solid ",
                    width: "100%",
                    display: "flex",
                    borderColor: colors.sideBorder[100],
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      height: width < 1034 ? "90%" : "100%",
                      width: "100%",
                    }}
                  >
                    <PieChart data={initialCapitalPercentages} />
                  </Box>
                </Box>
              </Box>

              <Box height='350px' flex={"1 0 460px;"} sx={tableStyles}>
                <Typography
                  variant='h4'
                  color={colors.grey[100]}
                  fontWeight='bold'
                  sx={{
                    m: "0 0 16px 0",
                    fontFamily: "Inter",
                  }}
                >
                  Initial Capital Details
                </Typography>
                <DataGrid
                  density='compact'
                  loading={fetchingInitialCapitalData}
                  rows={initialCapital}
                  components={{
                    LoadingOverlay: LoadingSkeleton,
                    Toolbar: () => (
                      <CustomToolbar
                        onClickAdd={toggleFundingStructureDrawer}
                      />
                    ),
                    CustomToolbar,
                  }}
                  columns={fundingStructureColumns}
                />
              </Box>
            </Box>

            <Box m='0 0 0 0' height='400px' width='100%' sx={tableStyles}>
              <Typography
                variant='h4'
                color={colors.grey[100]}
                fontWeight='bold'
                sx={{ m: "0 0 16px 0", fontFamily: "Inter" }}
              >
                Loan Details
              </Typography>
              <DataGrid
                density='compact'
                loading={fetchingLoanDetailsData}
                rows={loanDetails}
                sortModel={[
                  {
                    field: "date",
                    sort: "asc",
                  },
                ]}
                components={{
                  NoRowsOverlay: (renderProps) => (
                    <CustomNoRowsOverlay
                      functionDrawer={toggleLoanDetailsDrawer}
                      message='If you have any loans, you can add them here'
                      {...renderProps}
                    />
                  ),
                  LoadingOverlay: LoadingSkeleton,
                  Toolbar: () => (
                    <CustomToolbar onClickAdd={toggleLoanDetailsDrawer} />
                  ),

                  CustomToolbar,
                }}
                // rowsPerPageOptionsSkeleton
                columns={loanDetailsColumns}
              />
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value='2'>
          {" "}
          <WorkingCapital></WorkingCapital>
        </TabPanel>
      </TabContext>
      <LoanDetailsDrawer
        loanDetailsDrawerOpen={loanDetailsDrawerOpen}
        toggleLoanDetailsDrawer={toggleLoanDetailsDrawer}
        saveNewLoanDetails={saveNewLoanDetails}
      />
      <FundingDetailsDrawer
        fundingStructureDrawerOpen={fundingStructureDrawerOpen}
        toggleFundingStructureDrawer={toggleFundingStructureDrawer}
        saveNewFundingDetails={saveNewFundingDetails}
      />
    </Box>
  );
};
export default Funding;
