import { useState } from "react";
import * as React from "react";
import { ReactComponent as Decoration1 } from "../../assets/decoration1.svg";
import AddIcon from "@mui/icons-material/Add";

import {
  Box,
  Button,
  Drawer,
  IconButton,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Formik } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// form validation
import { initialOtherRevValues, initialOtherRevSchema } from "./formValues"; // import the objects from the new file
import companyFoundingYearGetter from "../../data/companyFoundingYear"; // import the object from the new file

import { tokens } from "../../theme";
// Mock data for the tables

const companyFoundingYear = companyFoundingYearGetter();

const OtherRevDrawer = ({
  otherDrawerOpen,
  toggleOtherDrawer,
  saveOtherRevenues,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:1000px)");
  return (
    <Drawer
      variant='temporary'
      anchor={"right"}
      open={otherDrawerOpen}
      onClose={() => toggleOtherDrawer()}
      PaperProps={{
        sx: {
          width: {
            sm: 320,
            md: 320,
            lg: 360,
            xl: 500,
          },
          borderRadius: "8px",
          border: "1.5px solid",
          borderColor: colors.sideBorder[100],
          backgroundColor: colors.backgroundColor[100],
          backgroundImage: "none",
          height: "98vh",
          margin: "8px 8px 8px 8px ",
        },
      }}
    >
      {/* {list(anchor)} */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid",
          borderBottomColor: colors.sideBorder[100],
          alignItems: "center",
          height: "100px",
          padding: "16px 16px 8px 16px",
        }}
      >
        {/* New Add Title */}
        <Box>
          <Typography
            variant='h2'
            color={colors.grey[100]}
            fontWeight='bold'
            sx={{
              m: "0 0 0px ",
              fontFamily: "Inter",
              fontSize: "20px",
            }}
          >
            <Decoration1></Decoration1>
            <Box sx={{ display: "inline", ml: "12px" }}>New Revenue Source</Box>
          </Typography>
          <Typography ml='30px' variant='h5' color={colors.grey[400]}>
            Revenue
          </Typography>
        </Box>
        <Box sx={{ height: "58px" }}>
          <IconButton onClick={() => toggleOtherDrawer()}>
            <HighlightOffIcon />
          </IconButton>
        </Box>
      </Box>
      <Formik
        onSubmit={(values, { setSubmitting }) =>
          saveOtherRevenues(values, setSubmitting)
        }
        initialValues={initialOtherRevValues}
        validationSchema={initialOtherRevSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form style={{ height: "90vh" }} onSubmit={handleSubmit}>
            <Box
              height='80%'
              display='flex'
              flexDirection='column'
              columnGap='20px'
              rowGap='20px'
              overflow='auto'
              padding='24px 16px'
              sx={{
                "& 	.MuiFilledInput-root:after": {
                  borderBottomColor: "#2595FF",
                },
                "& 	.Mui-focused": {
                  borderBottomColor: "#2595FF",
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <TextField
                fullWidth
                variant='filled'
                type='text'
                label='Description'
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name='description'
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />

              <TextField
                fullWidth
                id='revenue0'
                variant='filled'
                type='text'
                label={"Revenue for " + `${companyFoundingYear}`}
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.revenue0}
                name='revenue0'
                error={!!touched.revenue0 && !!errors.revenue0}
                helperText={touched.revenue0 && errors.revenue0}
              />
              <TextField
                id='revenue1'
                fullWidth
                variant='filled'
                type='text'
                label={"Revenue for " + `${companyFoundingYear + 1}`}
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.revenue1}
                name='revenue1'
                error={!!touched.revenue1 && !!errors.revenue1}
                helperText={touched.revenue1 && errors.revenue1}
              />
              <TextField
                id='revenue2'
                fullWidth
                variant='filled'
                type='text'
                label={"Revenue for " + `${companyFoundingYear + 2}`}
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.revenue2}
                name='revenue2'
                error={!!touched.revenue2 && !!errors.revenue2}
                helperText={touched.revenue2 && errors.revenue2}
              />
              <TextField
                id='revenue3'
                fullWidth
                variant='filled'
                type='text'
                label={"Revenue for" + `${companyFoundingYear + 3}`}
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.revenue3}
                name='revenue3'
                error={!!touched.revenue3 && !!errors.revenue3}
                helperText={touched.revenue3 && errors.revenue3}
              />
              <TextField
                id='revenue4'
                fullWidth
                variant='filled'
                type='text'
                label={"Revenue for " + `${companyFoundingYear + 4}`}
                size='small'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.revenue4}
                name='revenue4'
                error={!!touched.revenue4 && !!errors.revenue4}
                helperText={touched.revenue4 && errors.revenue4}
              />
            </Box>
            <Box
              sx={{
                borderTop: "1px solid",
                borderTopColor: colors.sideBorder[100],
                pt: "18px",
                pr: "16px",
              }}
              display='flex'
              gap='8px'
              disabled={isSubmitting}
              justifyContent='end'
              mt={isNonMobile ? "50px" : "15px"}
            >
              <Button
                onClick={() => toggleOtherDrawer()}
                variant='outlined'
                size={isNonMobile ? "large" : "medium"}
                pr='80px'
                disabled={isSubmitting}
                sx={{
                  color: colors.grey[100],
                  fontWeight: "medium",
                  borderColor: colors.sideBorder[100],
                }}
              >
                Cancel
              </Button>
              <Button
                startIcon={isSubmitting ? null : <AddIcon />}
                size={isNonMobile ? "large" : "medium"}
                type='submit'
                variant='contained'
                color='buttonColor'
                disabled={isSubmitting}
                sx={{
                  backgroundColor: "#2595FF",
                  color: "#FFFFFF",
                }}
              >
                {isSubmitting ? (
                  <CircularProgress
                    size={24}
                    style={{ color: colors.grey[100] }}
                  />
                ) : (
                  "New Entry"
                )}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Drawer>
  );
};

export default OtherRevDrawer;
